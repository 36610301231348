import React from 'react';
import PropTypes from 'prop-types';

import {Columns as EncodingJobProfileTableColumns} from '@modules/encodingJobProfile/components/Table/Columns';
import {RowRenderer as EncodingJobProfileTableRowRenderer} from '@modules/encodingJobProfile/components/Table/RowRenderer';
import Table from '@appComponents/Table';

const EncodingJobProfileTable = (props) => {
    const {data, loading} = props;

    return (
        <div className='encoding_job_profiles__table'>
            <Table
                columns={EncodingJobProfileTableColumns}
                data={data}
                loading={loading}
                name='encodingJobProfilesList'
                noDataText='No encoding job profiles found'
                rowRenderer={EncodingJobProfileTableRowRenderer}
            />
        </div>
    );
};

EncodingJobProfileTable.defaultProps = {
    data: [],
};

EncodingJobProfileTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
};

export default EncodingJobProfileTable;
