import PropTypes from 'prop-types';
import React, {useState, useMemo} from 'react';
import {Field} from 'redux-form';
import moment from 'moment';
import {debounce as _debounce} from 'lodash';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import useQuery from '@utils/hooks/useQuery';
import {mapPropertyLicencesForDropdown} from '@utils/propertyLicence';
import {convertToInt} from '@utils/helpers';
import {GetDataForSearchingFiltersInLcppClientNotificationsLog} from '@graphql/clientProductLcpp/query';
import {GetTournamentCategoriesFilteredBySports} from '@graphql/tournamentCategory/query';
import {GetTournamentsFilteredByTournamentCategories} from '@graphql/tournament/query';

import {prepareSportsDropdownData, showErrorNotificationMessage} from '../utils/lcppClientNotificationLog';

export const LcppClientNotificationLogFilters = ({
    loading,
    form,
    restoreFilterValues,
    restoreFilterDateValues,
    filters,
}) => {
    const [hasSelectedSport, setHasSelectedSport] = useState(false),
        [hasSelectedTournamentCategory, setHasSelectedTournamentCategory] = useState(false),
        [tournamentCategoriesLoading, setTournamentCategoriesLoading] = useState(false),
        [tournamentsLoading, setTournamentsLoading] = useState(false),
        [sportsIds, setSportsIds] = useState([]),
        [tournamentCategoryIds, setTournamentCategoryIds] = useState([]),
        [skipTournamentCategoriesFetch, setSkipTournamentCategoriesFetch] = useState(true),
        [skipTournamentsFetch, setSkipTournamentsFetch] = useState(true),
        [refetchTournamentCategories, setRefetchTournamentCategories] = useState(null),
        [refetchTournaments, setRefetchTournaments] = useState(null),
        TIME_FORMAT = 'YYYY-MM-DD 00:00';

    const {
        data: {
            propertyLicencesForDropdown,
            propertyLicences,
            sports,
        } = {},
        error: errorFetchingDataForSearchingFilters,
        loading: isDataForSearchingFiltersLoading = true,
    } = useQuery({
        query: GetDataForSearchingFiltersInLcppClientNotificationsLog,
    });

    const sportsWithOurMediaRights = prepareSportsDropdownData(
        isDataForSearchingFiltersLoading,
        propertyLicences,
        sports
    );

    /* get sports start */
    const {
        data: {tournamentCategories} = {},
        error: errorFetchingTournamentCategoriesData,
        loading: isTournamentCategoriesDropdownDataLoading = true,
    } = useQuery({
        query: GetTournamentCategoriesFilteredBySports,
        options: {
            variables: {
                sports: sportsIds,
            },
            skip: skipTournamentCategoriesFetch,
            refetch: refetchTournamentCategories,
        },
    });

    const getTournamentCategories = _debounce(() => {
        setSkipTournamentCategoriesFetch(false);
        setRefetchTournamentCategories(new Date());
        setTournamentCategoriesLoading(false);
    }, 1000);

    const onSportChange = (event, value) => {
        if (0 < value.length) {
            setHasSelectedSport(true);
            setTournamentCategoriesLoading(true);
            setSportsIds(value.map(sport => convertToInt(sport)));
            getTournamentCategories();
        } else {
            form.changeValue('tournamentCategory', null);
            setHasSelectedSport(false);
        }
    };
    /* get sports end */

    /* get tournament start */
    const getTournaments = _debounce(() => {
        setSkipTournamentsFetch(false);
        setRefetchTournaments(new Date());
        setTournamentsLoading(false);
    }, 1000);

    const onTournamentCategoryChange = (event, value) => {
        if (0 < value.length) {
            setHasSelectedTournamentCategory(true);
            setTournamentsLoading(true);
            setTournamentCategoryIds(value.map(tournamentCategory => convertToInt(tournamentCategory)));
            getTournaments();
        } else {
            form.changeValue('tournament', null);
            setHasSelectedTournamentCategory(false);
        }
    };

    const {
        data: {tournaments} = {},
        error: errorFetchingTournamentsData,
        loading: isTournamentsDropdownDataLoading = true,
    } = useQuery({
        query: GetTournamentsFilteredByTournamentCategories,
        options: {
            variables: {
                tournamentCategories: tournamentCategoryIds,
            },
            skip: skipTournamentsFetch,
            refetch: refetchTournaments,
        },
    });
    /* get tournament end */

    const propertyLicencesOptions = !isDataForSearchingFiltersLoading
            ? mapPropertyLicencesForDropdown(propertyLicencesForDropdown)
            : [],
        errorOccurredWhileFetchingData = errorFetchingDataForSearchingFilters
        || errorFetchingTournamentCategoriesData
        || errorFetchingTournamentsData;

    useMemo(()=> {
        errorOccurredWhileFetchingData && showErrorNotificationMessage();
    }, [errorOccurredWhileFetchingData]);

    return (
        <div className='content__filters'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={loading}
                setValue={restoreFilterValues(filters.LcppClientNotificationLog, 'search') || ''}
            />
            <Field
                className='--minimalWidth --datetimeWithoutSeconds'
                component={Form.ReduxInput}
                configuration={{
                    calendarClassName: 'filterDatepicker',
                    dateFormat: 'YYYY-MM-DD HH:mm',
                    shouldCloseOnSelect: false,
                    showTimeSelect: true,
                    timeFormat: 'HH:mm',
                    timeIntervals: 5,
                }}
                defaultValue={moment().format(TIME_FORMAT)}
                disabled={loading}
                name='from'
                placeholder='From...'
                setValue={restoreFilterDateValues(filters.LcppClientNotificationLog, 'from', moment().format(TIME_FORMAT))}
                type='date'
            />
            <Field
                className='--minimalWidth --datetimeWithoutSeconds'
                component={Form.ReduxInput}
                configuration={{
                    calendarClassName: 'filterDatepicker',
                    dateFormat: 'YYYY-MM-DD HH:mm',
                    shouldCloseOnSelect: false,
                    showTimeSelect: true,
                    timeFormat: 'HH:mm',
                    timeIntervals: 5,
                }}
                defaultValue={moment().add(1, 'd').format(TIME_FORMAT)}
                disabled={loading}
                name='to'
                placeholder='To...'
                setValue={restoreFilterDateValues(filters.LcppClientNotificationLog, 'to', moment().add(1, 'd').format(TIME_FORMAT))}
                type='date'
            />
            <Field
                className='long'
                component={Form.ReduxSelect}
                disabled={isDataForSearchingFiltersLoading}
                loading={isDataForSearchingFiltersLoading}
                multiple
                name='propertyLicence'
                options={propertyLicencesOptions}
                placeholder='Property licence'
                search
                setValue={restoreFilterValues(filters.LcppClientNotificationLog, 'propertyLicence')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={isDataForSearchingFiltersLoading}
                loading={isDataForSearchingFiltersLoading}
                multiple
                name='sport'
                placeholder='Sport'
                onChangeValue={onSportChange}
                options={sportsWithOurMediaRights}
                search
                setValue={restoreFilterValues(filters.LcppClientNotificationLog, 'sport')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={tournamentCategoriesLoading}
                hidden={!hasSelectedSport}
                loading={isTournamentCategoriesDropdownDataLoading || tournamentCategoriesLoading}
                multiple
                name='tournamentCategory'
                placeholder='Category'
                onChangeValue={onTournamentCategoryChange}
                options={tournamentCategories}
                search
                setValue={restoreFilterValues(filters.LcppClientNotificationLog, 'tournamentCategory')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={tournamentCategoriesLoading || tournamentsLoading || loading}
                hidden={!hasSelectedTournamentCategory}
                loading={isTournamentsDropdownDataLoading || tournamentsLoading}
                multiple
                name='tournament'
                placeholder='Tournament'
                options={tournaments}
                search
                setValue={restoreFilterValues(filters.LcppClientNotificationLog, 'tournament')}
            />
        </div>
    );
};

LcppClientNotificationLogFilters.propTypes = {
    filters: PropTypes.shape({
        LcppClientNotificationLog: PropTypes.object.isRequired,
    }),
    form: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
    restoreFilterDateValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(LcppClientNotificationLogFilters, {name: 'LcppClientNotificationLog'});
