import {GetSports} from '@graphql/clipConfiguration/query';
import {runQuery} from '@modules/clipConfiguration/utils/forms/common/formHelpers';

export const setOptionsToSportsOnEditForm = (runApolloRequest, setField, setState, state, id, products) => {
    if (!state.SportsDataLoading) {
        setState(() => ({SportsDataLoading: true}));

        runQuery(runApolloRequest, GetSports).then((response) => {

            const formId = id,
                selectedProductId = products,
                options = response.data.sports,
                allSports = state.allSports;
            let sportsNotAssociatedToAnyClips;

            if (0 !== options.length && formId) {
                let idsOfSportsAssociatedToClips = allSports.filter(clipConfig => clipConfig.id !== formId && clipConfig.product.id === selectedProductId)
                    .flatMap(clipConfigWithoutCurrentFormId => clipConfigWithoutCurrentFormId.sports.map(sport =>sport.id));
                sportsNotAssociatedToAnyClips  = options.filter(obj => !idsOfSportsAssociatedToClips.includes(obj.id));
            }

            setField('sports', {
                options: sportsNotAssociatedToAnyClips,
                disabled: 0 === sportsNotAssociatedToAnyClips.length,
            });
        });
    }
};
