export const propertyLicencesForDropdown = `
  propertyLicences: propertyLicencesForDropdown {
    id
    name
    licensor{
      id
      name
    }
  }
`;

export const getDataForMultipleEventCreation = `
query propertyLicencesForMultipleEventCreation(
    $matches: [ID]!
) {
    propertyLicencesForMultipleMatchesDropdown(matches: $matches) {
        id
        name
        licensor {
            id
            name
        }
    }
    eventStatuses {
        id,
        name,
        key : id,
        value : id,
        text : name
    }
    clipProviders {
        id,
        name,
        key : id,
        value : id,
        text : name
    }
    matches(filters: {id: $matches}) {
        start_datetime
        id
        stadium {
          id
          name
        }
        home_competitor {
          id
          name
        }
        away_competitor {
          id
          name
        }
        tv_channels {
          id
          name
        }
        tournament {
          id
          name
          tournament_category {
            id
            name
            sport {
              id
              name
            }
          }
        }
    }
}`;
