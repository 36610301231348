import React from 'react';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {Button} from 'semantic-ui-react';
import {
    get as _get,
    isEmpty as _isEmpty,
} from 'lodash';
import {withRouter} from 'react-router';

import Form from '@appComponents/ReduxFormControls';
import {getQueryStringFromObject, urlSearchStringToObject} from '@utils/helpers';
import {isValidDate} from '@modules/events/utils/constraintsTables';
import {showErrorModal} from '@utils/modal';

import {setInitialFilter, setFilter, resetFilter} from '../../../actions/index';

export function restoreFilterValues(filtersSetKey, filterKey, defaultValue = null) {
    return ( _get(filtersSetKey, 'params') && filterKey in _get(filtersSetKey, 'params', {}))
        ? filtersSetKey.params[filterKey]
        : defaultValue;
}

export function getSelectedValues(selectedValues, values) {
    const newSelectedTournamentCategories = [];

    selectedValues.forEach((selectedValue) => {
        values.forEach((item) => {
            if (selectedValue === item.value) {
                newSelectedTournamentCategories.push(item.value);
            }
        });
    });

    return newSelectedTournamentCategories;
}

/**
 * @ignore
 */
export function FiltersWrapper(Component, params) {
    /**
     * @ignore
     */
    const mapStateToProps = (state, ownParams) => {
        const key = `filters${params.name}${ownParams.additionalFilterId || ''}`;

        return {
            additionalFilterId: ownParams.additionalFilterId || null,
            filters: state.app.filters,
            fields: (state.form[key] && state.form[key].registeredFields)
                ? state.form[key].registeredFields : {}
        }
    };

    /**
     * @ignore
     */
    const mapDispatchToProps = (dispatch) => {

        return {
            setInitialFilter: (data) => {
                dispatch(setInitialFilter(data))
            },
            setFilter: (data) => {
                dispatch(setFilter(data))
            },
            resetFilter: (name) => {
                dispatch(resetFilter(name))
            }
        }
    };

    class FiltersForm extends React.Component {
        static propTypes = {
            additionalFilterId: PropTypes.string,
            change: PropTypes.func,
            data: PropTypes.object,
            handleSubmit: PropTypes.func.isRequired,
            history: PropTypes.object,
            loading: PropTypes.bool,
            location: PropTypes.object,
            reset: PropTypes.func.isRequired,
            resetFilter: PropTypes.func.isRequired,
            setFilter: PropTypes.func.isRequired,
            setInitialFilter: PropTypes.func.isRequired,
            filters: PropTypes.object,
            fields: PropTypes.object,
            excludedParams: PropTypes.array,
            url: PropTypes.string,
            match: PropTypes.object,
        };

        static defaultProps = {
            additionalFilterId: null,
            loading: false,
        };

        constructor(props) {
            super(props);

            this.queryParams = null;

            if (!props.url || (props.url && props.match.path === props.url)) {
                this.queryParams = urlSearchStringToObject(props.location.search);
            }

            this.props.setInitialFilter({
                name: params.name,
                data: {}
            });
        }

        restoreFilterValues = (filtersSetKey, filterKey, defaultValue = null) => {
            if (!_isEmpty(this.queryParams)) {
                return _get(this.queryParams,`[${filterKey}]`, defaultValue)
            }

            return ( _get(filtersSetKey, 'params') && filterKey in _get(filtersSetKey, 'params', {}))
                ? filtersSetKey.params[filterKey]
                : defaultValue;
        };

        restoreFilterDateValues = (filtersSetKey, filterKey, defaultValue = null) => {
            return this.restoreFilterValuesWithValidation(
                filtersSetKey,
                filterKey,
                isValidDate,
                defaultValue,
                'Date provided in filters is wrong. Please correct your selection.'
            );
        };

        restoreFilterValuesWithValidation = (filtersSetKey, filterKey, validationCallback, defaultValue = null, errorMessage = '') => {
            const fieldsValidation = this.state?.fieldsValidation || {};

            if (!_isEmpty(this.queryParams)) {
                const filterFieldValue = this.queryParams[filterKey];
                const isValid = validationCallback(filterFieldValue);
                const currentValidationStatus = fieldsValidation[filterKey];

                if (currentValidationStatus !== isValid) {
                    this.setState({
                        fieldsValidation: {
                            ...fieldsValidation,
                            [filterKey]: isValid,
                        },
                    });

                    if (!isValid) {
                        showErrorModal({
                            header: 'Error with filters',
                            text: errorMessage,
                        });
                    }
                }

                if (!isValid) {
                    return defaultValue;
                }

                return _get(this.queryParams, `[${filterKey}]`, defaultValue);
            }

            return filtersSetKey?.params?.[filterKey] ?? defaultValue;
        };

        /**
         * @ignore
         */
        submitFilters = (data) => {
            /**
             * To be sure, that someone click on submit button
             * There is search with only one field (eg. Competitors)
             * When we don't send any letter there, we don't send any parameters
             * We need add extra lastClickSubmit parameter globally!
             */
            data.lastClickSubmit = Date.now();

            if (params.onSubmit) {
                data = params.onSubmit(data);
            }

            const queryString = getQueryStringFromObject(
                {
                    ...data,
                    ...urlSearchStringToObject(
                        window.location.search,
                        ['pageLimit', 'order', 'orderBy']
                    )
                },
                (params.excluded || [])
            );

            this.props.history.push({
                search: `?${queryString}`
            });

            this.props.setFilter({
                name: `${params.name}${this.props.additionalFilterId || ''}`,
                data: data,
                forceRefresh: true
            });
        };

        resetFilters = () => {
            if(_get(params, 'excluded', []).length) {
                const name = `${params.name}${this.props.additionalFilterId || ''}`;
                params.excluded.forEach(param => {
                    Object.assign(params.initialValues, {[param]: this.props.filters[name].params[param]});
                });
            }

            this.props.reset();
        };

        render() {
            const {additionalFilterId, loading, filters} = this.props,
                removeResetButton = _get(params, 'removeResetButton', false);

            let filtersHiddenClassName = "";

            const name = `${params.name}${additionalFilterId || ''}`;

            if (filters && filters[name] && filters[name].areFiltersVisible === false) {
                filtersHiddenClassName = "--is-hidden"
            }

            return (
                <Form.Create
                    onSubmit={this.props.handleSubmit(this.submitFilters)}
                    className={`filters__container ${filtersHiddenClassName}`}
                >
                    <Component
                        restoreFilterValues={this.restoreFilterValues}
                        restoreFilterDateValues={this.restoreFilterDateValues}
                        data={this.props.data}
                        loading={this.props.loading}
                        filters={this.props.filters}
                        form={{
                            changeValue: this.props.change
                        }}
                        additionalFilterId={this.props.additionalFilterId}
                    />

                    <Button.Group className="filter__buttons" as="div">
                        {!removeResetButton && <Button
                            name="ResetButton"
                            type="reset"
                            content='Reset'
                            className="button__reset"
                            disabled={loading}
                            onClick={this.resetFilters}
                        />}
                        <Button
                            name="SubmitButton"
                            type="submit"
                            disabled={loading}
                            className="small"
                            primary
                            icon="search"
                        />
                    </Button.Group>
                </Form.Create>
            )
        }
    }

    return withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
        form: `filters${params.name}`,
        initialValues: params.initialValues,
        validate: params.validate
    })(FiltersForm)))
}
