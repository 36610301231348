import {isEmpty as _isEmpty} from 'lodash';

import {convertToInt} from '@utils/helpers';

export const mediaConnectTableColumns = {
    'id': {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {
        label: 'Name',
        sorting: (row) => row.name.toLowerCase(),
    },
    'encoding_datacenter': {
        label: 'Encoding datacenter',
        sorting: (row) => row.encoding_datacenter.name.toLowerCase(),
    },
    'mediaconnect_origin_feeds': {
        label: 'Feeds',
        sorting: (row) => _isEmpty(row.mediaconnect_origin_feeds) ? '' : row.mediaconnect_origin_feeds[0].url,
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
};
