import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import PropTypes from 'prop-types';

import {graphql} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getSearchFilters} from '@utils/filters';
import {convertToInt} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import navigation from '@constants/navigation';
import {GetSdiOriginEntryPointsForTable} from '@graphql/sdiOriginEntryPoint/query';
import {GetEncodingDatacenters} from '@graphql/encodingDatacenter/query';

import SdiOriginEntryPointsTable from '../components/SdiOriginEntryPointsTable';
import SdiOriginEntryPointsIndexButtons from '../components/SdiOriginEntryPointsIndexButtons';
import SdiOriginEntryPointsFilters from '../components/SdiOriginEntryPointsFilters';
import {exportExcel} from '../utils/export/sdiOriginEntryPointsIndexExcel';

export const DEFAULT_FILTERS_VALUES = {
    search: '',
    encodingDatacenters: [],
};

const SdiOriginEntryPointIndex = ({
    DataSdiOriginEntryPoint = {
        sdiOriginEntryPoints: [],
        loading: false,
    },
    DataFilters = {
        encodingDatacenters: DEFAULT_FILTERS_VALUES.encodingDatacenters,
        loading: false,
    },
    Menu,
}) => {
    React.useEffect(() => {
        Menu.storeMenu(navigation.infrastructure.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                buttons={SdiOriginEntryPointsIndexButtons}
                exportExcelParams={exportExcel(DataSdiOriginEntryPoint.sdiOriginEntryPoints)}
                filters={SdiOriginEntryPointsFilters}
                filtersButtonName='sdiOriginEntryPoints'
                filtersProps={{data: DataFilters}}
                loading={DataSdiOriginEntryPoint.loading}
                messagesBoxNames='sdiOriginEntryPoint'
                pageTitle='SDI origin entry points'
            />
            <SdiOriginEntryPointsTable
                loading={DataSdiOriginEntryPoint.loading}
                sdiOriginEntryPoints={DataSdiOriginEntryPoint.sdiOriginEntryPoints}
            />
        </div>
    );
};

SdiOriginEntryPointIndex.propTypes = {
    DataSdiOriginEntryPoint: PropTypes.shape({
        sdiOriginEntryPoints: PropTypes.array,
        loading: PropTypes.bool,
    }),
    DataFilters: PropTypes.shape({
        encodingDatacenters: PropTypes.array,
        loading: PropTypes.bool,
    }),
    Menu: PropTypes.object,
};

const SdiOriginEntryPointWithQuery = compose(
    graphql(GetSdiOriginEntryPointsForTable, {
        options: ({
            filters: {
                search = DEFAULT_FILTERS_VALUES.search,
                encodingDatacenters = DEFAULT_FILTERS_VALUES.encodingDatacenters,
            }
        }) => ({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
            variables: {
                search: search,
                encoding_datacenter: encodingDatacenters.map(encodingDatacenter => convertToInt(encodingDatacenter)),
            },
        }),
        name: 'DataSdiOriginEntryPoint',
    }),
    graphql(GetEncodingDatacenters, {
        options: () => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'cache-and-network',
            };
        },
        name: 'DataFilters',
    })
)(FilterQueryWrapper(SdiOriginEntryPointIndex, {
    queryForRefresh: 'DataSdiOriginEntryPoint',
    filterUrls: ['sdiOriginEntryPoints.index'],
}));

const mapStateToProps = (state) => ({
    filters: getSearchFilters(state, 'sdiOriginEntryPoints', DEFAULT_FILTERS_VALUES),
});

export default connect(mapStateToProps, mapModulesToProps(['Menu']))(
    FilterUrlParamsWrapper(SdiOriginEntryPointWithQuery, DEFAULT_FILTERS_VALUES)
);
