import React from 'react';

import Link from '@appComponents/Link';

const renderSingleSubdivisionTableRow = (column, row, params) => {
    switch (column) {
        case 'id':
            return row.id;
        case 'name':
            return row.name;
        case 'iso_code':
            return row.iso_code;
        case 'postal_codes':
            return (row.num_postal_codes)
                ? <Link name='countries.postalCodes'
                    params={{id: params.countryId}}
                    query={{country_subdivision: JSON.stringify([row.id])}}>{row.num_postal_codes}</Link>
                : 0;
        default:
            return '';
    }
};

export default renderSingleSubdivisionTableRow;
