import {isEmpty as _empty} from 'lodash';
import React from 'react';

import {renderModalError} from '@utils/forms';
import {getLink} from '@appComponents/Link';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';

export const CENTERED_HEADLINE_AND_BODY = {
    header: {
        align: 'center',
    },
    content: {
        align: 'center',
    },
};

export const renderCountryModalError = (message) => (
    renderModalError(
        'Country',
        getLink('countries.index'),
        message
    )
);

export const formatBlackoutZonesAsString = (blackoutZoneData) => {
    if (!_empty(blackoutZoneData)) {
        return blackoutZoneData.map( element => element.name).join(', ');
    }

    return '';
};

export const renderLink = (link, id, content, authorizationObject) => (
    <LinkWithAuthorization
        authorization={authorizationObject}
        link={{
            name: link,
            params: {id: id},
        }}
    >
        {content}
    </LinkWithAuthorization>
);
