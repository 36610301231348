import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import {sortByTextValue} from '@utils/sorters';

const UdpOriginEntryPointFilters = ({
    data: {
        contentCategories = [],
        encodingDatacenters = [],
        tournaments = [],
    },
    filters,
    loading,
    restoreFilterValues,
}) => {
    return (
        <div className='udpOriginEntryPoint__filters filter__controls__container'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                setValue={restoreFilterValues(filters.udpOriginEntryPoints, 'search') ?? ''}
                disabled={loading}
            />
            <Field
                component={Form.ReduxSelect}
                name='encodingDatacenter'
                options={sortByTextValue(encodingDatacenters)}
                disabled={loading}
                setValue={restoreFilterValues(filters.udpOriginEntryPoints, 'encodingDatacenter') ?? ''}
                placeholder='Encoding datacenter'
                loading={loading}
                search
                multiple
                selection

            />
            <Field
                component={Form.ReduxSelect}
                name='tournaments'
                options={tournaments}
                disabled={loading}
                setValue={restoreFilterValues(filters.udpOriginEntryPoints, 'tournaments') ?? ''}
                placeholder='Tournament'
                loading={loading}
                className='long'
                search
                multiple
                selection
            />
            <Field
                component={Form.ReduxSelect}
                name='contentCategories'
                options={contentCategories}
                disabled={loading}
                placeholder='Content category'
                loading={loading}
                className='long'
                setValue={restoreFilterValues(filters.udpOriginEntryPoints, 'contentCategories') ?? ''}
                search
                multiple
                selection
            />
        </div>
    );
};

UdpOriginEntryPointFilters.propTypes = {
    data: PropTypes.shape({
        tournaments: PropTypes.array,
        contentCategories: PropTypes.array,
        encodingDatacenters: PropTypes.array,
    }),
    filters: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(UdpOriginEntryPointFilters, {name: 'udpOriginEntryPoints'});
