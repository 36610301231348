import {GetSportsExcludingClipConfigSports} from '@graphql/clipConfiguration/query';
import {convertToInt} from '@utils/helpers';
import {
    runQuery,
} from '@modules/clipConfiguration/utils/forms/common/formHelpers';

export const setSportsForClipConfigurationSportLevelForm = (product, runApolloRequest, setField, setState, state, includeSelectedSports = []) => {

    if (product && !state.SportsDataLoading) {
        setState(() => ({SportsDataLoading: true}));

        runQuery(runApolloRequest, GetSportsExcludingClipConfigSports, {
            productId: product,
            includeSports: includeSelectedSports.map((sport) => (
                convertToInt(sport)
            )),
        }).then((response) => {
            const options = response.data.sportsWithoutClipConfigSports;
            setField('sports', {
                options: options,
                disabled: 0 === options.length,
            });
            setState(() => ({
                SportsData: options,
                SportsDataLoading: false,
            }));
        });
    }
};
