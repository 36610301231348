import React from 'react';
import {isEmpty as _isEmpty} from 'lodash';

import LinkWithAuthorization from '../../app/components/LinkWithAuthorization';
import {rtmpAuthorizationObject} from './RtmpOriginEntryPointTable';
import {Authorization} from '../../app/components/Authorization';
import {IconCheckmark, IconEdit} from '../../app/components/IconCollection';
import * as RESOURCES from '../../app/constants/resources';


const ROUTE_EDIT = (id) => `/rtmp-origin-entry-points/${id}`;

export const rtmpOriginEntryPointRowRenderer = (column, row) => {
    switch (column) {
        case 'id':
            return (
                <LinkWithAuthorization
                    authorization={rtmpAuthorizationObject}
                    link={{
                        name: 'rtmpOriginEntryPoint.edit',
                        params: {id: row.id},
                    }}
                >
                    {row.id}
                </LinkWithAuthorization>
            );
        case 'name':
            return renderLinkToEditAndDescription(row);
        case 'rtmp_origin_entry_point_type':
        case 'origin_hosting_provider':
            return renderNameOfFirstLevelDependency(row, column);
        case 'host':
            return renderHostAndPublicHost(row);
        case 'is_active':
            return renderCheckbox(row, column);
        case 'actions':
            return (
                <Authorization authorization={rtmpAuthorizationObject}>
                    <div className="actions-list-icon">
                        <IconEdit size="large" link={ROUTE_EDIT(row.id)}
                            resource={RESOURCES.RTMP_ORIGIN_ENTRY_POINT}/>
                    </div>
                </Authorization>
            );
        default:
            return null;
    }
};

const renderNameOfFirstLevelDependency = (row, column) => {
    return (
        <div>
            {(null === row[column] || row[column] === undefined)
                ? ''
                : row[column].name.capitalizeFirstLetter()
            }
        </div>
    );
};

const renderLinkToEditAndDescription = (row) => {
    return (
        <div>
            <LinkWithAuthorization
                authorization={rtmpAuthorizationObject}
                link={{
                    name: 'rtmpOriginEntryPoint.edit',
                    params: {id: row.id},
                }}
            >
                {row.name}
            </LinkWithAuthorization>
            { !_isEmpty(row.description) ?
                <p className='muted'>{row.description}</p> : null
            }
        </div>
    );
};

const renderHostAndPublicHost = (row) => {
    return (
        <div>
            {row.host}
            { !_isEmpty(row.public_host) ?
                <p>{ `Public: ${ row.public_host }` }</p> : null
            }
        </div>
    );
};

const renderCheckbox = (row, column) => {
    return (
        row[column] ? <IconCheckmark/> : ''
    );
};
