import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';
import {Columns as UdpOriginEntryPointTableColumns} from '@modules/udpOriginEntryPoint/components/Table/Columns';
import {RowRenderer as UdpOriginEntryPointTableRowRenderer} from '@modules/udpOriginEntryPoint/components/Table/RowRenderer';

const UdpOriginEntryPointTable = (props) => {
    const {udpOriginEntryPoints, loading} = props;

    return (
        <div className='udpOriginEntryPoints__table'>
            <Table
                name='udpOriginEntryPointsList'
                columns={UdpOriginEntryPointTableColumns}
                data={udpOriginEntryPoints}
                loading={loading}
                rowRenderer={UdpOriginEntryPointTableRowRenderer}
                noDataText='No UDP origin entry points found.'
            />
        </div>
    );
};

UdpOriginEntryPointTable.defaultProps = {
    udpOriginEntryPoints: [],
};

UdpOriginEntryPointTable.propTypes = {
    udpOriginEntryPoints: PropTypes.array,
    loading: PropTypes.bool,
};

export default UdpOriginEntryPointTable;
