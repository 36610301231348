export const changeLogRevisionFilterQuery = `
query ChangelogRevisionFilterQuery (
  $from: DatetimeTzInputType
  $to: DatetimeTzInputType
  $elementId: Int
  $searchByElementName: String
  $changelogActionType: [ID]
  $securedResource: [ID]
  $user: [ID]
  $apiUser: [ID]
)
{
  changelogRevisions (filters: {
    from: $from
    to: $to
    search: $searchByElementName
    elementId: $elementId
    changelogActionType: $changelogActionType
    securedResource: $securedResource
    user: $user
    apiUser: $apiUser
  }) {
    id
    secured_resource {
      id
      name
      path
    }
    changelog_action_type {
      id
      name
    }
    element_id
    element_name
    datetime
    user {
      id
      name
    }
    api_user {
      id
      name
    }
    ip
  }
}`;

export const changeLogFilterQuery = `
query changeLogFilters{
  securedResources {
    id
    value : id,
    text : name
  }
  changelogActionTypes {
    id
    value : id,
    text : name
  }
  changelogUsers {
    id
    value: id
    text: name
  }
  apiUsers {
    id
    value: id
    text: name
  }
}`;
