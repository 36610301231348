import {convertToInt} from "@utils/helpers";
import {CENTERED_HEADLINE_AND_BODY, formatBlackoutZonesAsString} from "@modules/country/utils/utils";

export const countryPostalCodeColumns = {
    id: {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    subdivision: {
        label: 'Subdivision',
        sorting: (row) => (row.subdivision_name
            ? row.subdivision_name.toLowerCase()
            : ''),
    },
    postal_code: {
        label: 'Postal code',
        sorting: (row) => (row.code ? row.code.toLowerCase() : ''),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    blackout_zones: {
        label: 'Blackout Zones',
        sorting: (row) => formatBlackoutZonesAsString(row.blackout_zones),
    },
};
