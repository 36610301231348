import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {EVENT} from '@constants/resources';
import {getDataForMultiplePresetApply} from '@graphql/events/query';
import {ApplyPresetToEvents} from '@graphql/events/mutation';

import * as formUtils from '../utils/formHelper';
import {validateForEventMultiplePresetApply} from './validator';

export default function EventMultiplePresetApplyModel() {
    return {
        title: null,
        formName: 'EventMultipleCreation',
        entityDataMapKey: 'EventMultipleCreation',
        validate: validateForEventMultiplePresetApply,
        resources: EVENT,
        dataMap: {
            EventMultipleCreation: {
                property_licence: null,
                event_preset: null,
                event_status: null,
                description: null,
                products: [],
                notes: null,
            },
        },
        fields: {
            property_licence: {
                optionsKey: 'propertyLicence',
                props: {
                    component: formUtils.renderPropertyLicenceField,
                    label: 'Property licence',
                    name: 'property_licence',
                    disabled: true,
                    type:'select',
                    required: true,
                },
            },
            event_preset: {
                props: {
                    component: formUtils.renderEventPresetDropdown,
                    label: 'Apply preset',
                    required: true,
                    name: 'event_preset',
                    placeholder: 'Select preset',
                    type: 'select',
                },
            },
            as_additional_contents: {
                dataMapKey: 'as_additional_contents',
                props: {
                    component: Form.SemanticInput,
                    type: 'toggle',
                    name: 'as_additional_contents',
                    label: 'Add contents as additional contents',
                },
            },
            multiple: [
                {
                    description: {
                        dataMapKey: 'event.description',
                        props: {
                            component: Form.SemanticInput,
                            label: 'Description',
                            name: 'description',
                            type: 'text',
                        },
                    },
                },
                {
                    color: {
                        dataMapKey: 'event.color',
                        props: {
                            component: Form.SemanticInput,
                            name: 'color',
                            type: 'color',
                        },
                        defaultValue: '3a87ad',
                    },
                },
            ],
            event_status: {
                optionsKey: 'eventStatuses',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Status',
                    name: 'event_status',
                    allowClear: true,
                    placeholder: 'Keep existing status',
                },
            },
            coverage: {
                optionsKey: 'coverage',
                props: {
                    className: 'inline',
                    component: Form.SemanticCheckboxList,
                    label: 'Coverage',
                    name: 'coverage',
                    inline: true,
                },
            },
            clip_provider: {
                optionsKey: 'clipProviders',
                props: {
                    hidden: true,
                    required: true,
                    component: Form.SemanticSelect,
                    className: '--small-width',
                    name: 'clip_provider',
                    label: 'Clip provider',
                },
            },
            notes: {
                component: Form.FormRowNotes,
                props: {
                    name: 'notes',
                },
            },
        },
        messages: {
            boxName: {
                delete: 'eventsIndex',
                success: 'eventsIndex',
                error: 'formInnerErrorMessage',
            },
            text: {
                ...MESSAGES,
                SAVED: () => 'The preset has been applied to the events successfully.',
                FORM_ERROR: () => 'The preset could not be applied to the events. Please correct the highlighted fields below.',
                NOT_SAVED: () => '',
            },
        },
        query: {
            getOptions: getDataForMultiplePresetApply,
        },
        mutation: {
            updateEntity: ApplyPresetToEvents,
        },
    };
}
