import {
    uniqWith as _uniqWith,
    isEqual as _isEqual,
} from 'lodash';

export const getSelectedTournamentCategories = (setField, setState, state, selectedTournamentCategories) => {
    if (0 < selectedTournamentCategories.length && !state.selectedTournamentCategoriesData) {
        const tournamentCategories = selectedTournamentCategories.map((tournamentCategorie) => (
            tournamentCategorie.id.toString()
        ));
        setField('categories', {
            defaultValue: _uniqWith(tournamentCategories, _isEqual),
        });
        setState(() => ({
            selectedTournamentCategoriesData: selectedTournamentCategories,
        }));
    }
};
