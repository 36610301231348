import {camelCase as _camelCase} from 'lodash';

export const changelogElementExcel = (dataExport, changelogElementData) => ({
    dataExport: dataExport,
    filename: `changelog_${_camelCase(changelogElementData.secured_resource.name)}_${changelogElementData.element_id}`,
    parseData: changelogChange => [
        changelogChange.datetime,
        changelogChange.property_name,
        changelogChange.original_value,
        changelogChange.new_value,
        changelogChange.user?.name ?? changelogChange.api_user?.name,
        changelogChange.ip,
    ],
    path: 'changelog.index.path',
    titles: [
        'Date/time',
        'Property',
        'Original value',
        'New value',
        'User / API user',
        'IP',
    ],
});
