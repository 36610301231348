import {convertToInt} from '@utils/helpers';
import {CENTERED_HEADLINE_AND_BODY} from '@modules/country/utils/utils';

export const countryTableColumns = {
    id: {
        label: 'ID',
        sorting: (row) => convertToInt(row.id),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    name: {
        label: 'Name',
        sorting: (row) => row.name.toLocaleLowerCase(),
    },
    full_name: {
        label: 'Full Name',
        sorting: (row) => row.full_name.toLocaleLowerCase(),
    },
    iso_alpha2_code: {
        label: 'ISO alpha-2 code',
        sorting: (row) => row.iso_alpha2_code.toUpperCase(),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    iso_alpha3_code: {
        label: 'ISO alpha-3 code',
        sorting: (row) => row.iso_alpha3_code.toUpperCase(),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    iso_numeric_code: {
        label: 'ISO numeric code',
        sorting: (row) => convertToInt(row.iso_numeric_code),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    continent_id: {
        label: 'Continent',
        sorting: (row) => (row.continent && row.continent.name) ? row.continent.name.toLowerCase() : '',
    },
    handle_geo_restrictions_on_subdivision_level: {
        label: 'Subdivision support',
        sorting: (row) => Number(row.handle_geo_restrictions_on_subdivision_level),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    country_subdivisions: {
        label: 'Subdivisions',
        sorting: (row) => row.country_subdivisions.length,
        ...CENTERED_HEADLINE_AND_BODY,
    },
};
