import {
    isArray as _isArray,
} from 'lodash';

import {
    GetTournamentCategoriesFilteredBySport,
} from '@graphql/clipConfiguration/query';
import {convertToInt} from '@utils/helpers';
import {
    runQuery,
} from '@modules/clipConfiguration/utils/forms/common/formHelpers';

export const setTournamentCategories = (runApolloRequest, setField, setState, state, sport) => {
    if (sport && !state.TournamentCategoriesDataLoading) {
        setState(() => ({TournamentCategoriesDataLoading: true}));

        runQuery(runApolloRequest,
            GetTournamentCategoriesFilteredBySport,
            {
                sport: _isArray(sport) ? sport.map((tournament) => (
                    convertToInt(tournament.id)
                )) : [convertToInt(sport)],
            }).then((response) => {
            setField('categories', {
                options: response.data.tournamentCategories,
                disabled: 0 === response.data.tournamentCategories.length,
            });
            setState(() => ({
                TournamentCategoriesData: response.data.tournamentCategories,
                TournamentCategoriesDataLoading: false,
            }));
        });
    }
};
