export const checkIfSelectedTournamentAreAvailable = (options, selectedTournaments) => {
    let selectedAvailableTournaments = [];

    selectedTournaments.forEach((value) => {
        if (options.some(v => (v.id === value))) {
            selectedAvailableTournaments.push(value);
        }
    });

    return selectedAvailableTournaments;
};
