import React from 'react';
import { Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { get as _get , isEmpty as _isEmpty} from 'lodash';

import { getCompetitorsNameFromEvent } from '@utils/competitorName';
import {ShowWarnings} from '@utils/ShowWarnings';

import {
    checkIfIsTvStreaming,
    getCompetitorWarnings,
    getTvStreamingEncoderId ,
    isAwayCompetitorBlackListed,
    isHomeCompetitorBlacklisted,
} from '../../utils/eventSchedule';

export const CompetitorsBlacklistedStatus = ({hc_blacklisted = false, ac_blacklisted = false}) => (
    <>
        {hc_blacklisted && <Label color={'red'} size={'mini'}>Blacklisted home competitor</Label>}
        {ac_blacklisted && <Label color={'red'} size={'mini'}>Blacklisted away competitor</Label>}
    </>
);

CompetitorsBlacklistedStatus.propTypes = {
    hc_blacklisted: PropTypes.bool,
    ac_blacklisted: PropTypes.bool,
};

export const CompetitorNameAndLabels = ({event = {}}) => {
    const isHcBlacklisted = isHomeCompetitorBlacklisted(event),
        isAcBlacklisted = isAwayCompetitorBlackListed(event),
        competitorWarning = getCompetitorWarnings(event),
        isTvStreaming = checkIfIsTvStreaming(event),
        tvStreamingEncoderId = getTvStreamingEncoderId(event),
        tvStreamingInfoExist = isTvStreaming && null !== tvStreamingEncoderId,
        competitorsWarningsExist = 0 !== competitorWarning.length,
        marginClass = (event.description && (competitorsWarningsExist || tvStreamingInfoExist))
            ? 'marginBetweendDescriptionAndWarnings'
            : '';

    const miniLabel = (!_isEmpty(event.description)
        ? <Label style={{backgroundColor: `#${event.color}`}} size='mini'>{event.description}</Label>
        : ''
    );

    return (
        <>
            <div>{getCompetitorsNameFromEvent(event)}</div>
            <div>{miniLabel}</div>
            <div className={marginClass}>
                {tvStreamingInfoExist && <Label color='grey' size='mini'>TV Streaming</Label>}
                <ShowWarnings warnings={competitorWarning} />
                <CompetitorsBlacklistedStatus hc_blacklisted={isHcBlacklisted} ac_blacklisted={isAcBlacklisted} />
            </div>
        </>
    );
};

CompetitorNameAndLabels.propTypes = {
    event: PropTypes.object.isRequired,
};

export const MatchId = ({event = {}}) => {
    const matchId = _get(event, 'sr_event.match.id', false);

    return (
        <div>
            {(matchId) || '-'}
        </div>
    );
};

MatchId.propTypes = {
    event: PropTypes.object.isRequired,
};
