import React from 'react';
import {isEmpty as _isEmpty} from 'lodash';

import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {IconEdit} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import * as RESOURCES from '@constants/resources';
import {Authorization} from '@appComponents/Authorization';
import {
    authorizationObject,
} from '@modules/sdiOriginEntryPoint/components/SdiOriginEntryPointsTable/authorizationObject';

const sdiOriginEntryPointsTableRowRenderer = (column, row) => {
    switch (column) {
        case 'id':
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{
                        name: 'sdiOriginEntryPoints.edit',
                        params: {id: row.id},
                    }}
                >
                    {row.id}
                </LinkWithAuthorization>
            );
        case 'name':
            return (
                <div>
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: 'sdiOriginEntryPoints.edit',
                            params: {id: row.id},
                        }}
                    >
                        {row.name}
                    </LinkWithAuthorization>
                    {!_isEmpty(row.description) ?
                        <p className='muted'>{row.description}</p> : null
                    }
                </div>
            );
        case 'encoding_datacenter' :
            return (
                <div>
                    {(null === row[column] || row[column] === undefined)
                        ? ''
                        : row[column].name
                    }
                </div>
            );
        case 'actions' :
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit size='large' link={getLink('sdiOriginEntryPoints.edit', {id: row.id})}
                            resource={RESOURCES.SDI_ORIGIN_ENTRY_POINTS}
                        />
                    </div>
                </Authorization>
            );
        default:
            return null;
    }
};

export default sdiOriginEntryPointsTableRowRenderer;
