import React from 'react';
import {useQuery} from '@apollo/client';
import {isEmpty as _isEmpty} from 'lodash';
import {useParams} from 'react-router';
import {Segment} from 'semantic-ui-react';

import {getCountrySubdivisionsWithPostalCodeCount} from '@graphql/countrySubdivision/query';
import {showModal} from '@utils/modal';
import {renderCountryModalError} from '@modules/country/utils/utils';
import Table from '@appComponents/Table';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {exportExcel} from '@modules/country/utils/export/countrySubdivisionsExcel';
import {convertToInt} from '@utils/helpers';
import {countrySubdivisionColumns} from '@modules/country/components/SubdivisionsTable/subdivisionsTableColumns';
import renderSingleSubdivisionTableRow from '@modules/country/components/SubdivisionsTable/subdivisionsTableRenderer';

const CountrySubdivisions = () => {
    const matchParams = useParams(),
        countryId = convertToInt(matchParams.id),
        queryParams = {
            filters: {
                country: countryId,
            },
            groups: ['num_postal_codes'],
        };

    const {
        data: {
            countrySubdivisions,
        } = {},
        error: countryDataFetchingError,
        loading: isCountryDataLoading = true,
    } = useQuery(getCountrySubdivisionsWithPostalCodeCount,
        {
            variables: {
                ...queryParams,
            },
            fetchPolicy: 'no-cache',
        }
    );

    if (!_isEmpty(countryDataFetchingError)) {
        showModal({
            isVisible: true,
            content: renderCountryModalError(countryDataFetchingError.message),
        });
    }

    if (isCountryDataLoading) {
        return (
            <Segment basic loading className='--full-height' />
        );
    }

    return (
        <div>
            <HeaderRenderer
                exportExcelParams={exportExcel(countrySubdivisions, countryId)}
                messagesBoxNames='Country Subdivisions'
            />
            <Table
                name='Countries'
                columns={countrySubdivisionColumns}
                data={countrySubdivisions}
                loading={isCountryDataLoading}
                noDataText='No subdivisions found'
                rowRenderer={renderSingleSubdivisionTableRow}
                rowRendererProps={{ countryId: countryId }}
                pagination={false}
            />
        </div>
    );
};

export default CountrySubdivisions;
