import PropTypes from 'prop-types';
import React from 'react';

import Table from '@modules/app/components/Table';
import {columnObjectAlignLeft} from '@utils/tableHelpers';

export const columns = {
    'datetime': {
        label: 'Date/time',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
            singleLine: true,
        },
    },
    'property': {
        label: 'Property',
        sorting: (row) => row.property_name,
        ...columnObjectAlignLeft,
    },
    'original_value': {
        label: 'Original value',
        sorting: (row) => row.original_value,
        ...columnObjectAlignLeft,
    },
    'new_value': {
        label: 'New value',
        sorting: (row) => row.new_value,
        ...columnObjectAlignLeft,
    },
    'user': {
        label: 'User / API user',
        sorting: (row) => row.user?.name ?? row.api_user?.name,
        ...columnObjectAlignLeft,
    },
    'ip': {
        label: 'IP',
        sorting: (row) => row.ip,
        ...columnObjectAlignLeft,
    },
};

export const rowRenderer = (column, row) => {
    switch (column) {
        case 'datetime':
            return row.datetime;
        case 'property':
            return row.property_name;
        case 'original_value':
            return row.original_value;
        case 'new_value':
            return row.new_value;
        case 'user':
            return row.user?.name ?? row.api_user?.name ?? '';
        case 'ip':
            return row.ip;
        default:
            return null;
    }
};

const ChangeLogElementTable = ({data}) => {
    return (
        <Table
            name='changelogElementTable'
            columns={columns}
            data={data}
            limit={50}
            rowRenderer={rowRenderer}
            noDataText='No modifications found.'
            defaultSort='datetime'
            defaultSortDirection='DESC'
        />
    );
};

ChangeLogElementTable.propTypes = {
    data: PropTypes.array,
};

export default ChangeLogElementTable;
