import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {compose} from 'redux';

import {graphql} from 'react-apollo';
import {getLink} from '@appComponents/Link';
import {convertToInt} from '@utils/helpers';
import {createForm} from '@utils/forms';
import {routes} from '@constants/routes';

import ApplicationsIndexButtons from '../components/ApplicationsIndexButtons';
import ApplicationsTable from '../components/ApplicationsTable';
import HeaderRenderer from '../../app/components/HeaderRenderer';
import ApplicationModel from '../forms/ApplicationModel';
import ApplicationModelEdit from '../forms/ApplicationModelEdit';
import ApplicationForm from '../forms/ApplicationForm';
import mapModulesToProps from '../../../utils/mapModulesToProps';
import {exportExcel} from '../utils/export/applicationsIndexExcel';
import {GetRtmpOriginEntryPointApplicationsForTable} from '../../../graphql/rtmpOriginEntryPointApplication/query.graphql';

const ApplicationIndex = ({
    DataApplications = {
        rtmpOriginEntryPointApplications: [],
        loading: false,
    },
    history: {
        push,
    },
    isVisible,
    match: {
        params: {
            id,
            applicationId = null,
        },
        path,
    },
    Modal,
    rtmpOriginEntryPointHost = '',
}) => {
    const [modalVisibility, setModalVisibility] = useState(isVisible);

    useEffect(() => {
        if (path !== routes.rtmpOriginEntryPoint.applications.index.path) {
            const formData = path === routes.rtmpOriginEntryPoint.applications.edit.path
                ? {id, applicationId}
                : null;

            loadModal(path, formData);
        }
    }, [path]);

    useEffect(() => {
        if (isVisible !== modalVisibility && !isVisible) {
            push(getLink('rtmpOriginEntryPoint.applications.index', {id: id}));
        }
    }, [isVisible]);

    const loadModal = (activePath, params = {}) => {
        if (routes.rtmpOriginEntryPoint.applications.add.path === activePath) {
            return loadModalForm(createForm(
                ApplicationModel,
                ApplicationForm,
                {rtmpOriginEntryPointId: id}
            ), params);
        } else if (routes.rtmpOriginEntryPoint.applications.edit.path === activePath) {
            return loadModalForm(createForm(
                ApplicationModelEdit,
                ApplicationForm,
                {id: applicationId, rtmpOriginEntryPointId: id}
            ), params);
        }
    };

    const loadModalForm = (Form, data) => {
        Modal.setModal({
            isVisible: true,
            header: null,
            content: <Form formData={data}/>,
        });

        setModalVisibility(true);
    };

    return (
        <div>
            <HeaderRenderer
                buttons={ApplicationsIndexButtons}
                buttonsProps={{id}}
                exportExcelParams={exportExcel(
                    DataApplications.rtmpOriginEntryPointApplications,
                    id,
                    rtmpOriginEntryPointHost
                )}
                loading={DataApplications.loading}
            />
            <ApplicationsTable
                applications={DataApplications.rtmpOriginEntryPointApplications}
                loading={DataApplications.loading}
            />
        </div>
    );
};

ApplicationIndex.propTypes = {
    DataApplications: PropTypes.shape({
        rtmpOriginEntryPointApplications: PropTypes.array,
        loading: PropTypes.bool,
    }),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    isVisible: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            applicationId: PropTypes.string,
        }),
        path: PropTypes.string.isRequired,
    }),
    Modal: PropTypes.shape({
        setModal: PropTypes.func.isRequired,
    }),
    rtmpOriginEntryPointHost: PropTypes.string.isRequired,
};

const ApplicationsWithQuery = compose(
    graphql(GetRtmpOriginEntryPointApplicationsForTable, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    rtmpOriginEntryPoint: convertToInt(props.match.params.id),
                },
            };
        },
        name: 'DataApplications',
    })
)(ApplicationIndex);

const mapStateToProps = (state) => ({
    isVisible: state.modal.isVisible,
});

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Modal']))(ApplicationsWithQuery));
