import {
    GetSports,
} from '@graphql/clipConfiguration/query';
import {
    runQuery,
} from '@modules/clipConfiguration/utils/forms/common/formHelpers';

export const setSports = (runApolloRequest, setField, setState, state, isEditForm = false) => {
    if (!state.SportsDataLoading) {
        setState(() => ({SportsDataLoading: true}));

        runQuery(runApolloRequest, GetSports).then((response) => {
            const options = response.data.sports;

            setField('sports', {
                options: options,
                disabled: 0 === options.length || isEditForm,
            });
            setState(() => ({
                SportsData: options,
                SportsDataLoading: false,
            }));
        });
    }
};
