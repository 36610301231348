import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Popup, Label} from 'semantic-ui-react';

import {COMPETITOR_NOT_DEFINED, COMPETITOR_NOT_DEFINED_BOTH} from '@constants/variables';
import {getCompetitorsObjectFromEvent} from '@utils/competitorObject';
import {addHasMatchIdToStructure} from '@utils/eventObject';

import {
    getCompetitorCountryFlagsFromEvent,
} from '../../../../../utils/EventsTabsLayout/Details/EventDetails/fields/column3/competitors';

const PopupCompetitor = <Popup
    trigger={<Icon color='red' name='warning sign'/>}
    content='This competitor is blacklisted.'
    hoverable
/>;

const CompetitorsName = ({competitors}) => {
    const homeCompetitor = competitors.home.name,
        awayCompetitor = competitors.away.name;

    if (!homeCompetitor && !awayCompetitor) {
        return COMPETITOR_NOT_DEFINED_BOTH;
    }

    return (
        <>
            <Competitor competitor={competitors.home} type='home'/>
            &nbsp;:&nbsp;
            <Competitor competitor={competitors.away} type='away'/>
        </>
    );
};

const Competitor = ({competitor, type}) => {
    const competitorName = competitor.name || COMPETITOR_NOT_DEFINED;

    if (competitor.is_blacklisted && 'home' === type) {
        return (<span className='--warning'>
            {PopupCompetitor}
            {competitorName}
        </span>);

    } else if (competitor.is_blacklisted && 'away' === type) {
        return (<span className='--warning awayCompetitor'>
            {competitorName}
            {PopupCompetitor}
        </span>);
    }

    return (<span>{competitorName}</span>);
};

export const Competitors = ({event}) => {
    const competitorCountryFlagsFromEvent = getCompetitorCountryFlagsFromEvent(event),
        competitorsObject = getCompetitorsObjectFromEvent(addHasMatchIdToStructure(event));
    const underageCompetitors = event.sr_event?.match?.has_underage_competitors
            && <Label color={'red'} size={'mini'} style={{marginLeft: '5px'}}>{'Underage competitors'}</Label>;

    if (competitorCountryFlagsFromEvent.home || competitorCountryFlagsFromEvent.away) {
        return (
            <span key={'competitorsName'}>
                {competitorCountryFlagsFromEvent.home}
                <CompetitorsName competitors={competitorsObject}/>
                {competitorCountryFlagsFromEvent.away}
                {underageCompetitors}
            </span>
        );
    }

    return (
        <>
            <CompetitorsName competitors={competitorsObject}/>
            {underageCompetitors}
        </>
    );
};

Competitor.propTypes = {
    competitor: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
};

CompetitorsName.propTypes = {
    competitors: PropTypes.object.isRequired,
};

Competitors.propTypes = {
    event: PropTypes.object.isRequired,
};
