import {
    debounce as _debounce,
    isEmpty as _isEmpty,
    get as _get,
    sortBy as _sortBy,
} from 'lodash';
import {withRouter} from 'react-router';

/* eslint import/no-unresolved: 0 */
import {gql, withApollo} from 'react-apollo';
import {getLink} from '@appComponents/Link';
import {refetchQueryByName} from '@utils/apollo';
import {EVENT_NON_SPORTRADAR_TYPE, EVENT_TYPE_NON_SPORTRADAR} from '@constants/variables';
import {avCompetitorsList as graphQLavCompetitorsList} from '@graphql/competitors';
import {convertToInt} from '@utils/helpers';

import RightScope from './logic/RightScope';
import EventForm from './EventForm';
import {prefillCoverage, updateClipProviderFieldBasedOnPreset} from '../utils/formHelper';

export class NonSportradarEventForm extends EventForm {
    setRightScopes = (value) => {
        return super.setRightScopes(value, 'content_category');
    };

    getCompetitors = (event, data) => {
        if (3 > data.searchQuery.length) {
            return;
        }

        this.setField(data.name, {
            loading: true,
            disabled: true,
        });

        this.runApolloRequest('query', {
            fetchPolicy: 'network-and-cache',
            query: gql(graphQLavCompetitorsList),
            variables: {
                search: data.searchQuery || '',
            },
        }).then((response) => {
            const competitorsOptions = response.data.avCompetitors.map(competitor => {

                return Object.assign({}, competitor, {text: `${competitor.text} (${competitor.id})`});
            });

            this.setField(data.name, {
                options: competitorsOptions,
                loading: false,
                disabled: false,
            });
        }).catch(() => {
            this.setFormMessage("Can't get competitors.");
        });
    };

    filterStartDatetime = (data) => (
        RightScope.isValidDate(data, this.state.rightsScopes, this.state.selectedContentCategoryId)
    );

    componentDidUpdate(prevProps) {
        super.receivedPropsValidator(prevProps);
        const {event_preset, content_category} = this.props.formValues;
        const isCoverageFieldHidden = _get(this.props, 'Model.fields.coverage.props.hidden', false);

        this.setCoverageField(
            prevProps, null, content_category, true, false
        );
        this.toggleClipProviderDropdown(prevProps);

        if (prevProps.formValues.event_preset !== event_preset) {
            prefillCoverage(
                isCoverageFieldHidden,
                this.state.selectedPresetData?.event?.products,
                this.props.Model,
                this.setField,
                this.state,
                (newState) => this.setState(newState)
            );

            this.setState({presetApplied: !!event_preset});
        }

        if (event_preset && !isCoverageFieldHidden) {
            updateClipProviderFieldBasedOnPreset(this.props, prevProps.formValues, this.setField, this.state, this.state.presetsListObject);
        }

        if (!this.state.dataReceived && this.state.defaultForm_data && this.state.defaultForm_data.event ) {
            const entity = _get(this.state, 'defaultForm_data.event', {}),
                contentCategory = _get(entity, 'av_event.content_category', {});

            this.setState(() => ({
                selectedContentCategoryId: parseInt(contentCategory.id, 10),
            }));

            this.setCompetitorField('home_competitor', EVENT_TYPE_NON_SPORTRADAR);
            this.setCompetitorField('away_competitor', EVENT_TYPE_NON_SPORTRADAR);

            this.setField('content_category', {
                disabled: true,
                options: [{
                    id: parseInt(contentCategory.id, 10),
                    text: `${contentCategory.parent.parent.name} >
                        ${contentCategory.parent.name} >
                        ${contentCategory.name}`,
                    value: contentCategory.id,
                }],
            });
            this.getPresetsForPropertyLicence(entity.property_licence.id,
                _get(this.state, 'defaultForm_data.event.id', null));
            this.getRightsScopes(entity.property_licence.id).then(() => {
                this.setField(['start_datetime', 'end_datetime'], {disabled: false});
            });

            this.setState(() => ({
                dataReceived: true,
            }));
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink('events.configuration.index', {id: response.data.createAvEvent.event.id}));
        });

        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink('events.index'));
        });

        this.setCreateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false});
        });

        this.setUpdateErrorCallback(() => {
            this.props.Modal.setModal({isVisible: false});
        });

        this.setUpdateSuccessCallback(() => {
            this.backToEventPage();
            refetchQueryByName('EventQuery');
            refetchQueryByName('GetEventContents');
            refetchQueryByName('GetEventPresetsByEvent');
            refetchQueryByName('encodingTargetsQuery');
        });

        this.setField('start_datetime', {
            configuration: {
                ...this.props.Model.fields.start_datetime.props.configuration,
                filterDate: this.filterStartDatetime,
            },
        });

        this.setOnChangeCallback({
            property_licence: (data) => {
                this.setContentCategoryOptions(data.value);
                this.getPresetsForPropertyLicence(data.value, _get(this.state, 'defaultForm_data.event.id', null));
            },
            content_category: (data) => {
                this.setState(() => ({
                    selectedContentCategoryId: data.value,
                }), () => {
                    this.resetCoverageField();
                });

                if (data.value) {
                    this.setField(['start_datetime', 'end_datetime'], {disabled: false, selected: null});
                } else {
                    this.setField(['start_datetime', 'end_datetime'], {disabled: true, selected: null});
                }
            },
        });

        this.setField(['home_competitor', 'away_competitor'], {
            onSearchChangeSelect: _debounce(this.getCompetitors, 1000),
        });

        this.setOptionParsers({
            property_licence: (data) => {
                let newData = [],
                    contentCategoriesOptions = {};

                for (let i = 0; i < data.length; i++) {
                    let propertyLicence = data[i],
                        contents = [];

                    for (let key = 0; key < propertyLicence.contents.length; key++) {
                        let contentCategory = propertyLicence.contents[key];

                        contents.push({
                            id: contentCategory.content_category.id,
                            value: contentCategory.content_category.id,
                            text: propertyLicence.content_category.name + ' > ' +
                            contentCategory.content_category.parent.name + ' > ' +
                            contentCategory.content_category.name,
                        });
                    }

                    contents.sort((a, b) => {
                        const textA = a.text.toLowerCase();
                        const textB = b.text.toLowerCase();

                        return (textA > textB) - (textA < textB);
                    });

                    contentCategoriesOptions[propertyLicence.id] = contents;

                    newData.push({
                        key: propertyLicence.id,
                        value: propertyLicence.id,
                        text: propertyLicence.name + ' - ' + propertyLicence.licensor.name,
                    });
                }

                this.setState(() => ({
                    contentCategoriesOptions: contentCategoriesOptions,
                }));

                return _sortBy(newData, (item) => item.text.toLocaleLowerCase());
            },
            venue: (data) => {
                let newData = [];

                for (let i = 0; i < data.length; i++) {
                    let venue = data[i],
                        venueName = _get(venue, 'city.name', null),
                        city = venueName ? ` - ${venueName}` : '';

                    newData.push({
                        key: venue.id,
                        text: venue.name + city + ` (${venue.id})`,
                        value: venue.id,
                    });
                }

                return newData;
            },
        });
    }

    prepareDataForSubmit = (data) => {
        let event = Object.assign({}, this.props.Model.dataMap[this.props.Model.entityDataMapKey], data),
            contentCategoryId = parseInt(event.content_category, 10),
            venue = parseInt(event.venue, 10),
            id = event.id;

        delete event.id;
        delete event.venue;
        delete event.content_category;
        delete event.preset_id;
        delete event.preset_name;
        delete event.preset_overwrite;
        delete event.preset_save;
        delete event.as_additional_contents;
        delete event.coverage;
        delete event.event_status;

        let savedPresetId = _get(this.state, 'defaultForm_data.event.event_preset.id', null);

        event.color = event.color.substring(1);
        event.property_licence = parseInt(event.property_licence, 10);
        event.event_type = EVENT_NON_SPORTRADAR_TYPE;
        event.notes = data.notes || null;
        event.description = data.description || null;
        event.products = data.coverage || null;
        event.clip_provider = !_isEmpty(event.products) ? convertToInt(event.clip_provider) : null;

        if (data.event_preset) {
            event.event_preset = !data.event_preset
                ? parseInt(savedPresetId, 10) : parseInt(data.event_preset, 10);
        }

        let dataToSave =
            {
                event: event,
                content_category: contentCategoryId,
                home_competitor: event.home_competitor ? parseInt(event.home_competitor, 10) : null,
                away_competitor: event.away_competitor ? parseInt(event.away_competitor, 10) : null,
                venue: isNaN(venue) ? null : venue,
            };

        delete dataToSave.event.home_competitor;
        delete dataToSave.event.away_competitor;

        if (id) {
            dataToSave.id = parseInt(id, 10);
        } else {
            dataToSave.event.event_status = parseInt(data.event_status, 10);
        }

        if (undefined !== data.as_additional_contents) {
            dataToSave.as_additional_contents = !!data.as_additional_contents;
        }

        return this.addPresetDataForSubmit(dataToSave, data);
    };

}

export default withRouter(withApollo(NonSportradarEventForm));
