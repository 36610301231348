import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    get as _get,
    isEmpty as _isEmpty,
    sortBy as _sortBy,
    isNil,
} from 'lodash';
import moment from 'moment';
import {Dimmer, Divider, Icon, Image, Label, Loader, Popup} from 'semantic-ui-react';

import {
    EVENT_TYPE_SPORTRADAR,
    ORIGIN_STREAM_TYPE_HTTP,
    ORIGIN_STREAM_TYPE_RTMP,
    ORIGIN_STREAM_TYPE_UDP,
    ORIGIN_STREAM_TYPE_SDI,
    ORIGIN_STREAM_TYPE_MEDIACONNECT,
    SECURITY_PRIVILEGES_READ,
    SECURITY_PRIVILEGES_UPDATE,
    TV_STREAMING_PRODUCTION_COMPANY,
    ORIGIN_STREAM_TYPE_SRT, ENCODING_STATUS_ENDED,
    ENCODING_REDUNDANCY_TYPE_MULTI_AZ_ENCODING,
} from '@constants/variables';
import {Authorization} from '@appComponents/Authorization';
import {LIVE_CONTROL, EVENT_CONTENT} from '@constants/resources';
import {refetchQueryByName} from '@utils/apollo';
import { IconEdit, IconCodeBranch } from '@appComponents/IconCollection';
import {CopyToClipboardRenderer} from '@appComponents/CopyToClipboardRenderer';
import Table from '@appComponents/Table';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import LabelColored from '@appComponents/LabelColored';
import PopupTable from '@appComponents/PopupTable';
import * as RESOURCES from '@constants/resources';
import {AvatarTournamentCategory} from '@modules/events/views/common/AvatarTournamentCategory';
import {CompetitorsName} from '@modules/events/views/common/CompetitorsName';
import {EventContentType} from '@modules/events/views/common/EventContentType';
import {CompetitorsAndTypeWrapper} from '@utils/CompetitorsAndTypeWrapper';
import mapModulesToProps from '@utils/mapModulesToProps';
import {renderEventStatusName} from '@utils/status';
import {replaceMatchIdPlaceholderInsideString} from '@utils/helpers';
import {ShowWarnings} from '@utils/ShowWarnings';

import {
    shouldRowBePurple,
    shouldRowBeRed,
    shouldRowBeYellow,
    shouldRowBeGreen,
    shouldRowBeOrange,
    isWarningVisible,
} from '../utils/helpers';
import {getWarnings} from '../utils/getWarnings';
import { SrtFailoverOriginEntryPointNotDefined } from '../views/LiveControlTable/SrtFailoverOriginEntryPointNotDefined';
import { UdpFailoverEntryPointPopup } from '../views/LiveControlTable/UdpFailoverEntryPointPopup';
import { UdpOriginEntryPointFailoverWarning } from '../views/LiveControlTable/UdpOriginEntryPointFailoverWarning';
import LiveControlEncodingTimePopup from './LiveControlEncodingTimePopup';
import ModifyEncodingStartDatetimeForm from '../forms/ModifyEncodingStartDatetimeForm';
import ModifyEncodingEndDatetimeForm from '../forms/ModifyEncodingEndDatetimeForm';
import LiveControlActions from './LiveControlActions';

export const authorizationLiveControlUpdateObject = {
    [LIVE_CONTROL]: SECURITY_PRIVILEGES_UPDATE,
    [EVENT_CONTENT]: SECURITY_PRIVILEGES_UPDATE,
};

const columns = {
    'event_content_start_datetime': {
        label: 'Time',
        sorting: true,
        className: '--noWrap-time',
    },
    'content': {label: 'Content', sorting: false},
    'entryPoint': {
        label: 'Entry point',
        sorting: row => row.entryPointSortValue,
    },
    'datacenter': {
        label: 'Datacenter',
        sorting: row => _get(row, 'encoding_datacenter_name', null),
    },
    'distributions': {label: 'Distributions', sorting: false},
    'cdn': {label: 'CDN', sorting: false},
    'bookings': {
        label: 'Bookings',
        sorting: false,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'encodingStartTime': {
        label: 'Enc. start time',
        sorting: false,
        content: {singleLine: true},
    },
    'encodingEndTime': {
        label: 'Enc. end time',
        sorting: false,
        content: {singleLine: true},
    },
    'encodingStatus': {
        label: 'Enc. status',
        sorting: (row) => row.encoding_status,
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

/**
 *
 * @param row
 * @returns {XML}
 */
const renderTime = (row) => {
    let renderTimeComponents = [],
        startDatetimeFormat = 'HH:mm',
        endDatetimeFormat = 'HH:mm',
        hasEventMatch = (row.match_id);

    if (!moment(row.event_content_start_datetime).isSame(new Date(), 'day')) {
        startDatetimeFormat = 'YYYY-MM-DD HH:mm';
    }

    renderTimeComponents.push(
        <span key={'ecsd_st' + row.id.toString()}>
            {moment(row.event_content_start_datetime).format(startDatetimeFormat)}
        </span>
    );

    if(!_isEmpty(row.event_content_end_datetime))
    {
        if (!moment(row.event_content_end_datetime).isSame(new Date(), 'day')) {
            endDatetimeFormat = 'YYYY-MM-DD HH:mm';
        }

        renderTimeComponents.push(<span  key={'ecsd_sep' + row.id.toString()}><br />-<br /></span>);
        renderTimeComponents.push(
            <span key={'ecsd_et' + row.id.toString()}>
                {moment(row.event_content_end_datetime).format(endDatetimeFormat)}
            </span>
        );
    }

    if (row.event_content_is_247_stream) {
        renderTimeComponents.push(
            <div key={`ec247_${row.id}`}>
                <Label color='grey' size='mini'>24/7</Label>
            </div>
        );
    }

    if (hasEventMatch) {
        return renderTimePopup(row, renderTimeComponents);
    } else {
        return (
            <React.Fragment key={`rtc_${row.id}`}>
                {renderTimeComponents}
                {renderEventStatusName(row.event_status_id, row.event_status, row.match_status_name, false)}
            </React.Fragment>
        );
    }
};

const renderTimePopup = (row, renderTimeComponents) => {
    const format = 'YYYY-MM-DD HH:mm:ss';

    let rows = [
        {
            header: 'Match status',
            body: row.match_status_name || '-',
        },
        {
            header: 'Original match start time',
            body: moment(row.match_start_datetime).format(format),
        },
        {
            header: 'About to start at',
            body: row.match_about_to_start_datetime ? moment(row.match_about_to_start_datetime).format(format) : '-',
        },
        {
            header: 'Started',
            body: getMatchStartedOrEnded(row.match_has_started),
        },
        {
            header: 'Started at',
            body: row.match_started_datetime ? moment(row.match_started_datetime).format(format) : '-',
            hidden: !row.match_has_started,
        },
        {
            header: 'Ended',
            body: getMatchStartedOrEnded(row.match_has_ended),
        },
        {
            header: 'Ended at',
            body: row.match_ended_datetime ? moment(row.match_ended_datetime).format(format) : '-',
            hidden: !row.match_has_ended,
        },
    ];

    return (
        <Popup
            className='--noPadding'
            flowing
            key={`popup_lct_${row.id}`}
            hoverable
            trigger={
                <div key={`popup_trigger_${row.id}`}>
                    {renderTimeComponents}
                    <div>
                        <ShowWarnings warnings={getWarnings(row)} />
                    </div>
                </div>
            }
        >
            <PopupTable rows={rows}/>
        </Popup>
    );
};

const getMatchStartedOrEnded = (value) => ((null === value) ? '-' : (value ? 'Yes' : 'No'));

/**
 *
 * @param row
 * @returns {string}
 */
const renderDistributions = (row) => {
    const distributions = row.distributions_by_product;

    return distributions ? distributions
        .map((distribution) => distribution.product_short_name + ' ('
            + (_sortBy(distribution.distribution_types.map((type) => (
                {
                    name: type.name,
                    distribution_type_id: type.distribution_type_id,
                }
            )), 'distribution_type_id').map(el => el.name)).join(', ') + ')')
        .map((distRow) => (<span key={distRow} style={{whiteSpace: 'nowrap'}}>{distRow}<br/></span>)) : '';
};

/**
 *
 * @param row
 */
const renderCdns = (row) => {
    return row.distributions_by_product.map((dist) => {
        if (!isNil(dist.cdns)) {
            return dist.cdns.map((cdn) => {
                if (!isNil(cdn.logo_filename)) {
                    return <Popup
                        key={`tooltip-cdn-${cdn.id}-${dist.product_short_name}`}
                        trigger={<Image key={'cdn-tt' + cdn.id } className='cdnLogo' size='tiny' src={'/images/' + cdn.logo_filename}/>}
                        flowing
                        hoverable
                    >
                        {cdn.name}
                    </Popup>;
                } else {
                    return <span key={`cdn-${cdn.id}-${dist.product_short_name}`}>{cdn.name}</span>;
                }
            });
        }
    }).map((element) => {
        if (!_isEmpty(element)) {
            return <div key={`cdns-${element[0].key}`} className='cdnsRow'>{element}<Divider hidden={true}/></div>;
        } else {
            return <div key={`cdns-rd-${row.id}`} className='cdnsRow'/>;
        }
    });
};

/**
 *
 * @param row
 * @returns {string}
 */
const renderBookings = (row) => {
    const distributions = row.distributions_by_product;

    return distributions ? distributions
        .map((distribution) => {
            return (
                <div key={row.event_content_id + '-distr-' + distribution.product_short_name}>
                    <LinkWithAuthorization
                        link={{
                            name: 'events.bookings.index',
                            params: {id: row.event_id},
                        }}
                        newWindow
                        authorization={{
                            [RESOURCES.BOOKING]: SECURITY_PRIVILEGES_READ,
                        }}
                    >
                        {distribution.bookings_count}
                    </LinkWithAuthorization>
                    <Divider hidden={true} />
                </div>
            );
        }) : '0';
};

export const EncodingModifyButton = (props) => {
    const onClick = () => {
        props.onClick(props.dataId);
    };

    return (
        <Authorization authorization={authorizationLiveControlUpdateObject}>
            <span className={'encodingModifyButton'} onClick={onClick}><IconEdit/></span>
        </Authorization>
    );
};
EncodingModifyButton.propTypes = {
    dataId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

/**
 *
 * @param row
 * @param showModal
 * @param timezone
 *
 * @returns {XML}
 */
const renderEncodingStartTime = (row, showModal, timezone) => {
    let encodingStartDatetime = 'Manual';
    let displayEditButton = false;

    if (false === isNil(row.encoding_start_datetime)) {
        const encStartTime = moment.tz(row.encoding_start_datetime, timezone);
        encodingStartDatetime = encStartTime.format('HH:mm');

        if (false === isNil(row.encoding_start_margin)) {
            encodingStartDatetime += ' (-'+ row.encoding_start_margin.toString() + ' min)';
        }

        if (moment.tz(timezone).isBefore(encStartTime)) {
            displayEditButton = true;
        }

    } else if(false === isNil(row.event_content_start_datetime)) {
        const encStartTime = moment.tz(row.event_content_start_datetime, timezone);

        if (false === isNil(row.encoding_start_margin)) {
            encodingStartDatetime = encStartTime.subtract(row.encoding_start_margin, 'minutes').format('HH:mm');
            encodingStartDatetime += ' (-' + row.encoding_start_margin.toString() + ' min)';
        }

        displayEditButton = true;
    } else {
        displayEditButton = true;
    }

    const showStartEncodingModal = data => showModal(
        <ModifyEncodingStartDatetimeForm
            formData={row}
            id={data}
            timezone={timezone}
            successCallback={() => refetchQueryByName('LiveControl')}
        />
    );

    const content = (
        <div>
            <span>{encodingStartDatetime}</span>
            {displayEditButton
                ? <EncodingModifyButton
                    dataId={row.id}
                    onClick={showStartEncodingModal}
                />
                : ''
            }
        </div>
    );

    return (row.encoding_first_start_datetime || row.encoding_start_datetime || row.encoding_start_margin)
        ? (
            <LiveControlEncodingTimePopup
                content={content}
                row={row}
                type='start'
            />
        )
        : content;
};

/**
 *
 * @param row
 * @param showModal
 * @param timezone
 *
 * @returns {XML}
 */
const renderEncodingEndTime = (row, showModal, timezone) => {
    let encodingEndDatetime = 'Manual';
    let displayEditButton = false;

    if (false === isNil(row.encoding_end_datetime)) {
        const encEndTime = moment.tz(row.encoding_end_datetime, timezone);
        encodingEndDatetime = encEndTime.format('HH:mm');

        if (false === isNil(row.encoding_end_margin)) {
            encodingEndDatetime += ' (+' + row.encoding_end_margin.toString() + ' min)';
        }

        if (moment.tz(timezone).isBefore(encEndTime)) {
            displayEditButton = true;
        }

    } else if (false === isNil(row.event_content_end_datetime)) {
        const encEndTime = moment.tz(row.event_content_end_datetime, timezone);
        displayEditButton = true;

        if (false === isNil(row.encoding_end_margin)) {
            encodingEndDatetime = encEndTime.add(row.encoding_end_margin, 'minutes').format('HH:mm');
            encodingEndDatetime += ' (+' + row.encoding_end_margin.toString() + ' min)';
        }
    } else if (false === isNil(row.encoding_end_margin)) {
        displayEditButton = true;
        encodingEndDatetime = ' +' + row.encoding_end_margin + ' min';
    } else {
        displayEditButton = true;
    }

    const showStopEncodingModal = data => showModal(
        <ModifyEncodingEndDatetimeForm
            formData={row}
            id={data}
            timezone={timezone}
            successCallback={() => refetchQueryByName('LiveControl')}
        />
    );

    const content = (
        <div>
            <span>{encodingEndDatetime}</span>
            {displayEditButton
                ? <EncodingModifyButton
                    dataId={row.id}
                    onClick={showStopEncodingModal}
                />
                : ''
            }
        </div>
    );

    return (row.encoding_end_datetime || row.encoding_end_margin)
        ? (
            <LiveControlEncodingTimePopup
                content={content}
                row={row}
                type='end'
            />
        )
        : content;
};

/**
 *
 * @param row
 * @returns {XML}
 */
const renderEncodingStatus = (row) => {
    let encodingStatus = 'Not started',
        drmWarningIcon = null,
        warningIcon = null,
        informationTooltip = null;

    const classNameWarningIcon = '--height-100-percent --marginLeft-0-5-em',
        hasEncodingTargets = row.distributions_by_product.find((element) => {
            return 0 < parseInt(element.encoding_targets_count, 10);
        }) !== undefined,
        encodingConfigurationChanged = [row]
            .filter(content => moment(content.encoding_configuration_update_datetime)
                .isAfter(content.encoding_start_datetime))
            .map(content => content.id);

    if (false === hasEncodingTargets) {
        warningIcon = <Popup
            key={`first-line-${row.id}`}
            trigger={<Icon color='red' name='warning sign' className={classNameWarningIcon} />}
            flowing
            content='No encoding targets'
            hoverable
        />;
    }

    if (!_isEmpty(encodingConfigurationChanged)) {
        if (ENCODING_STATUS_ENDED === row.encoding_status_id) {
            warningIcon = <Popup
                key={`first-line-${row.id}`}
                trigger={<Icon color='orange' name='warning sign' className={classNameWarningIcon} />}
                flowing
                content='The encoding configuration of this content has been modified since its last encoding was started.
                 You can reset and restart the encoding to encode with the new configuration.'
                hoverable
            />;
        }

        if (ENCODING_STATUS_ENDED !== row.encoding_status_id) {
            warningIcon = <Popup
                key={`first-line-${row.id}`}
                trigger={<Icon color='red' name='warning sign' className={classNameWarningIcon} />}
                flowing
                content='The encoding configuration of this content has been modified since its last encoding was started.
                 To encode with the new configuration, you need to stop the encoding, reset it and start it again.'
                hoverable
            />;
        }
    }

    row.distributions_by_product.some((productDistribution) => {
        if (true === productDistribution.has_wrong_drm_configuration) {
            drmWarningIcon = (<Popup
                key={`drm-wrong-configuration-${row.id}`}
                trigger={<Icon color='red' name='warning sign' className={classNameWarningIcon}/>}
                flowing
                content='At least one encoding target has a wrong DRM configuration.'
                hoverable
            />);

            return true;
        }

        return false;
    });

    if (null !== row.encoding_message) {
        const informationIcon = <Icon circular size='tiny' className='--marginLeft-0-5-em' inverted color='blue' name='info' />;
        informationTooltip = (
            <Popup
                key={`first-line-popup-${row.id}`}
                trigger={informationIcon}
                flowing
                content={row.encoding_message}
                hoverable
            />
        );
    }

    if (null !== row.encoding_status) {
        encodingStatus = row.encoding_status;
    }

    let statusElement = <span>{encodingStatus}</span>;

    if (null !== row.encoder) {
        statusElement = <Popup trigger={statusElement} content={`Encoder: ${row.encoder}`}/>;
    }

    return (
        <div className='encodingStatusRow'>
            {drmWarningIcon}
            {statusElement}
            {warningIcon}
            {informationTooltip}
        </div>
    );
};

const getEntryPointNameAndWarningText = (row) => {
    let warningText = '';
    let name = '';

    switch (row.origin_stream_type_id) {
        case ORIGIN_STREAM_TYPE_RTMP: {
            name = `${row.origin_stream_type_name} - ${row.entry_point_application_name}`;
            warningText = 'RTMP origin stream name not defined!';
            break;
        }
        case ORIGIN_STREAM_TYPE_UDP: {
            name = `${row.origin_stream_type_name} - ${row.udp_entry_point_name}`;
            warningText = 'UDP origin entry point not defined!';
            break;
        }
        case ORIGIN_STREAM_TYPE_SDI: {
            name= `${row.origin_stream_type_name} - ${row.sdi_entry_point_name}`;
            warningText = 'SDI origin entry point not defined!';
            break;
        }
        case ORIGIN_STREAM_TYPE_HTTP: {
            name = row.origin_stream_type_name;
            warningText = 'HTTP origin entry point URL not defined!';
            break;
        }
        case ORIGIN_STREAM_TYPE_MEDIACONNECT: {
            name= `${row.origin_stream_type_name} - ${row.mediaconnect_entry_point_name}`;
            warningText = 'MediaConnect origin entry point not defined!';
            break;
        }
        case ORIGIN_STREAM_TYPE_SRT: {
            name = `${row.origin_stream_type_name}`;
            warningText = 'SRT origin entry point URL not defined!';
        }
    }

    return {
        warningText,
        name,
    };
};

/**
 *
 * @param props
 * @returns {XML}
 * @constructor
 */
const LiveControlTable = (props) => {
    const { handleUdpOriginEntryPointFailover, loading, timezone } = props;

    const showModal = (content) => {
        props.Modal.setModal({
            isVisible: true,
            hideButtons: false,
            content: content,
        });
    };

    const getWarningsForEntryPoint = (row) => {
        let errors = [];

        if (
            row.event_content_is_main_content
            && row.property_licence_tech_setup_is_tv_streaming
            && row.match_tv_streaming_encoder_id
        ) {
            const url = `rtmp://${row.rtmp_origin_entry_point_host}${row.rtmp_origin_entry_point_application_type_path}`,
                encoderUrl = row.match_tv_streaming_encoder_stream_url,
                encoder = encoderUrl.substr(encoderUrl.lastIndexOf('/')+1);

            if(!row.match_tv_streaming_encoder_stream_url.includes(url)) {
                errors.push(
                    <div className='--warning'>
                        <Icon name='warning sign' color='red'/>
                        RTMP origin entry point application does not match the TV Streaming encoder.
                    </div>
                );
            }

            if (
                TV_STREAMING_PRODUCTION_COMPANY !== row.production_company_id
                || encoder !== row.rtmp_origin_stream_name
            ) {
                errors.push(
                    <div className='--warning'>
                        <Icon name='warning sign' color='red'/>
                        RTMP origin stream name does not match the TV Streaming encoder.
                    </div>
                );
            }
        }

        return errors;
    };

    const renderEntryPointName = (row) => {
        let entryPointName = (!isWarningVisible(row) || ORIGIN_STREAM_TYPE_RTMP === row.origin_stream_type_id)
            && getEntryPointNameAndWarningText(row).name;

        const rows = [
            {
                header: 'Contribution type',
                body: row.contribution_type_name,
            },
            {
                header: 'Production company',
                body: (
                    <LinkWithAuthorization
                        link={{
                            name: 'productionCompanies.edit',
                            params: {id: row.production_company_id},
                        }}
                        newWindow={true}
                        authorization={{
                            [RESOURCES.PRODUCTION_COMPANY]: SECURITY_PRIVILEGES_UPDATE,
                        }}
                    >
                        {row.production_company_name}
                    </LinkWithAuthorization>
                ),
            },
            {
                header: 'Contact person',
                body: (
                    <LinkWithAuthorization
                        link={{
                            name: 'productionCompanies.contactPersons.edit',
                            params: {
                                id: row.production_company_id,
                                contactPersonId: row.contact_person_id,
                            },
                        }}
                        newWindow={true}
                        authorization={{
                            [RESOURCES.CONTACT_PERSON]: SECURITY_PRIVILEGES_UPDATE,
                        }}
                    >
                        {row.contact_person_name}
                    </LinkWithAuthorization>
                ),
            },
            {
                header: 'Origin stream name',
                body: row.rtmp_origin_stream_name ?
                    replaceMatchIdPlaceholderInsideString(row.rtmp_origin_stream_name, row.match_id) : '-',
                hidden: ORIGIN_STREAM_TYPE_RTMP !== row.origin_stream_type_id,
            },
            {
                header: 'Failover origin entry point',
                body: row.failover_udp_entry_point_name || '-',
                hidden: ORIGIN_STREAM_TYPE_UDP !== row.origin_stream_type_id,
            },
            {
                header: 'HTTP origin entry point URL',
                body: row.http_origin_entry_point_url || '-',
                hidden: ORIGIN_STREAM_TYPE_HTTP !== row.origin_stream_type_id,
            },
            {
                header: 'SRT origin entry point URL',
                body: row.srt_origin_entry_point_url || '-',
                hidden: ORIGIN_STREAM_TYPE_SRT !== row.origin_stream_type_id,
            },
            {
                header: 'Failover SRT origin entry point URL',
                body: row.failover_srt_origin_entry_point_url || '-',
                hidden: ORIGIN_STREAM_TYPE_SRT !== row.origin_stream_type_id,
            },
        ];

        return (
            <>
                <Popup
                    className='--noPadding'
                    flowing
                    hoverable
                    trigger={
                        <span>
                            <span>{entryPointName}</span>
                            {getWarningsForEntryPoint(row)}
                            {
                                isWarningVisible(row)
                                && (
                                    <div className='--warning'>
                                        <Icon name='warning sign' color='red'/>
                                        {getEntryPointNameAndWarningText(row).warningText}
                                    </div>
                                )
                            }
                        </span>
                    }
                >
                    <PopupTable rows={rows}/>
                </Popup>
                <UdpOriginEntryPointFailoverWarning row={row} />
                <UdpFailoverEntryPointPopup row={row} onClickUdpOriginEntryPointFailover={onClickUdpOriginEntryPointFailover} />
                <SrtFailoverOriginEntryPointNotDefined row={row} />
            </>
        );
    };

    const onClickUdpOriginEntryPointFailover = (row) => {
        handleUdpOriginEntryPointFailover(row);
    };

    /**
     *
     * @param row
     * @returns {[null,null,null]}
     */
    const renderContent = (row) => ([
        renderContentFirstLine(row),
        renderContentSecondLine(row),
        renderContentThirdLine(row),
    ]);

    /**
     *
     * @param row
     * @returns {XML}
     */
    const renderContentFirstLine = (row) => {
        /**
         * <div> tag is necessary because of param in Semantic UI <Popup> component (trigger property)
         */
        const contentToRender = (
            <div>
                <CompetitorsAndTypeWrapper>
                    <CompetitorsName event={row} />
                    <EventContentType event={row} />
                </CompetitorsAndTypeWrapper>
            </div>
        );

        const renderIdWithCopyIcon = (id) => (
            <CopyToClipboardRenderer text={id.toString()} value={id.toString()} />
        );

        let rows = [
            {
                header: 'Property licence',
                body: (
                    <LinkWithAuthorization
                        link={{
                            name: 'propertyLicences.edit',
                            params: {id: row.property_licence_id},
                        }}
                        newWindow={true}
                        authorization={{
                            [RESOURCES.PROPERTY_LICENCE]: SECURITY_PRIVILEGES_UPDATE,
                        }}
                    >
                        {`${row.property_licence_name} - ${row.licensor_name}`}
                    </LinkWithAuthorization>
                ),
            },
            {
                header: 'Notes',
                body: (
                    row.event_notes
                        ? row.event_notes.split('\n').map((line, index) => (<div key={index}>{line}</div>))
                        : null
                ),
                hidden: !row.event_notes,
            },
            {
                header: 'Main event content',
                body: row.event_content_is_main_content ? 'Yes' : 'No',
            },
            {
                header: 'Event content ID',
                body: renderIdWithCopyIcon(row.event_content_id),
            },
            {
                header: 'Event ID',
                body: renderIdWithCopyIcon(row.event_id),
            },
            {
                header: 'Match ID',
                body: row.match_id ? renderIdWithCopyIcon(row.match_id) : '-',
                hidden: EVENT_TYPE_SPORTRADAR !== row.event_type_id,
            },
        ];

        return (
            <Popup
                className='--noPadding'
                key={`first-line-${row.id}`}
                trigger={contentToRender}
                flowing
                hoverable
            >
                <PopupTable rows={rows}/>
            </Popup>
        );
    };

    /**
     *
     * @param row
     * @returns {XML}
     */
    const renderContentSecondLine = (liveControlTableRow) => (
        <AvatarTournamentCategory
            event={liveControlTableRow}
            key={`avatarTournamentCategory_${liveControlTableRow.id}`}
        />
    );

    /**
     *
     * @param row
     * @returns {XML}
     */
    const renderContentThirdLine = (row) => {
        if (null !== row.event_description) {
            return (
                <LabelColored
                    color={null === row.event_color ? '' : row.event_color}
                    content={row.event_description}
                    key={'label-colored' + row.event_content_id}
                />
            );
        }

        return null;
    };

    /**
     *
     * @param column
     * @param row
     *
     * @returns {*}
     */
    const rowRenderer = (column, row) => {
        switch (column) {
            case 'event_content_start_datetime':
                return renderTime(row);
            case 'content':
                return renderContent(row);
            case 'entryPoint':
                return renderEntryPointName(row);
            case 'datacenter':
                return <span>{row.encoding_datacenter_name}
                    {
                        (row.encoding_redundancy_type === ENCODING_REDUNDANCY_TYPE_MULTI_AZ_ENCODING
                            && <Popup
                                trigger={<IconCodeBranch className='--rotated-90deg --marginLeft-0-5-em' />}
                                content={'Multi AZ encoding'}/>)
                    }
                </span>;
            case 'distributions':
                return renderDistributions(row);
            case 'cdn':
                return <div className='--grid'>{renderCdns(row)}</div>;
            case 'bookings':
                return renderBookings(row);
            case 'encodingStartTime':
                return null !== row.event_content_input_id ? renderEncodingStartTime(row, showModal, timezone) : '';
            case 'encodingEndTime':
                return null !== row.event_content_input_id ? renderEncodingEndTime(row, showModal, timezone) : '';
            case 'encodingStatus':
                return null !== row.event_content_input_id ? renderEncodingStatus(row) : '';
            case 'actions' :
                return <LiveControlActions row={row}/>;
            default:
                return null;
        }
    };

    /**
     *
     * @param rowData
     * @returns {string}
     */
    const getRowClassname = (rowData) => {
        let classModifier = '';

        if (rowData.distributions_by_product) {
            if (shouldRowBeRed(rowData)) {
                classModifier = '--red';
            } else if (shouldRowBeOrange(rowData, props.timezone)) {
                classModifier = '--orange';
            } else if (shouldRowBeYellow(rowData)) {
                classModifier = '--yellow';
            } else if (shouldRowBeGreen(rowData)) {
                classModifier = '--green';
            } else if (shouldRowBePurple(rowData)) {
                classModifier = '--purple';
            }
        }

        return classModifier;
    };

    const liveControlList = props.liveControlList.map(el => ({
        ...el,
        entryPointSortValue: (!isWarningVisible(el) || el.origin_stream_type_id === ORIGIN_STREAM_TYPE_RTMP)
            ? getEntryPointNameAndWarningText(el).name
            : null,
    }));

    return (
        <div className='liveControl__table'>
            <Dimmer inverted active={loading}>
                <Loader/>
            </Dimmer>
            {(0 < liveControlList.length || !loading)  && <Table
                name='liveControlList'
                columns={columns}
                data={liveControlList}
                noDataText='No content found'
                rowRenderer={rowRenderer}
                rowClassname={getRowClassname}
            />
            }
        </div>
    );
};

LiveControlTable.defaultProps = {
    liveControlList: [],
};

LiveControlTable.propTypes = {
    handleUdpOriginEntryPointFailover: PropTypes.func.isRequired,
    liveControlList: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    Modal: PropTypes.object,
    timezone: PropTypes.string,
};

export default connect(state => ({timezone: state.timezone.timezone}), mapModulesToProps(['Modal']))(LiveControlTable);
export const testMethods = {
    columns,
    renderTime,
};
