import {withRouter} from 'react-router';
import {
    get as _get,
    isEmpty as _isEmpty,
} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {convertToInt} from '@utils/helpers';
import {
    formatToSaveClipsOptions,
    setClipsTree,
    createLinkToIndexPage,
} from '@modules/clipConfiguration/utils/forms/common/formHelpers';
import {refetchQueryByName} from '@utils/apollo';
import {setSports} from '@modules/clipConfiguration/utils/forms/tournamentLevelForm/setSports';
import {
    setTournamentCategories,
} from '@modules/clipConfiguration/utils/forms/tournamentLevelForm/setTournamentCategories';
import {
    getSelectedTournamentCategories,
} from '@modules/clipConfiguration/utils/forms/tournamentLevelForm/getSelectedTournamentCategories';
import {setTournaments} from '@modules/clipConfiguration/utils/forms/tournamentLevelForm/setTournaments';

export class ClipConfigurationTournamentLevelForm extends DefaultForm {

    componentDidMount() {
        const link = createLinkToIndexPage();

        this.setField('sports', {
            label: 'Sport',
        });

        this.setOnChangeCallback({
            products: (event) => {
                setClipsTree(this, event.value, []);
            },
            sports: (event) => {
                setTournamentCategories(this.runApolloRequest, this.setField, this.setState, this.state, event.value);
            },
            categories: (event) => {
                const clipConfigSelectedTournaments =
                    _get(this.props, 'GraphQLEntityData.clipConfig.tournaments', []);

                setTournaments(
                    this.runApolloRequest, this.setField, this.setState, this.state,
                    this.props.formValues.tournaments,
                    event.value.map((value) => (
                        parseInt(value)
                    )),
                    this.props.formValues.products,
                    [...this.props.formValues.tournaments, ...clipConfigSelectedTournaments]
                );
            },
        });

        this.setCreateSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });
        this.setDeleteSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });
        this.setUpdateSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });

        this.setState(() => ({
            defaultForm_formTitle: 'Add new clip configuration on tournament level',
        }));

        if (!this.props.formParams.id) {
            setSports(this.runApolloRequest, this.setField, this.setState, this.state);
        }
    }

    componentDidUpdate(prevProps) {
        const clipConfig = _get(this.props, 'GraphQLEntityData.clipConfig', null);
        const selectedTournamentCategoriesAndSports = _get(this.props, 'GraphQLEntityData.selectedTournamentCategoriesAndSports', null);

        if (clipConfig && !this.state.dataLoaded) {
            const selectedSports = selectedTournamentCategoriesAndSports.sports.map((sport) => (
                sport.id
            ));
            setSports(this.runApolloRequest, this.setField, this.setState, this.state, true);
            setClipsTree(this, clipConfig.product.id, clipConfig.event_content_type_configs);
            this.setField('sports', {
                defaultValue: selectedSports.toString(),
            });

            this.setField('products', {
                defaultValue: clipConfig.product.id,
                disabled: true,
            });

            this.setField('tournaments', {
                defaultValue: clipConfig.tournaments.map((tournament) => (
                    tournament.id
                )),
            });

            this.setState(() => ({dataLoaded: true}));
        }

        if (selectedTournamentCategoriesAndSports && _isEmpty(this.state.selectedTournamentCategoriesData)) {
            getSelectedTournamentCategories(this.setField, this.setState, this.state, selectedTournamentCategoriesAndSports.tournamentCategories);
        }

        if (clipConfig && _isEmpty(this.state.TournamentCategoriesData)) {
            setTournamentCategories(this.runApolloRequest, this.setField, this.setState, this.state, selectedTournamentCategoriesAndSports.sports);
        }

        if (clipConfig && !_isEmpty(this.state.selectedTournamentCategoriesData) && !this.state.TournamentData && !this.state.TournamentDataLoading) {
            setTournaments(
                this.runApolloRequest, this.setField, this.setState, this.state,
                clipConfig.tournaments.map((tournament) => (
                    tournament.id
                )),
                this.state.selectedTournamentCategoriesData.map((tournamentCategorie) => (
                    convertToInt(tournamentCategorie.id)
                )),
                clipConfig.product.id,
                clipConfig.tournaments
            );
        }

        if (this.props.formParams.id && this.props.formValues.name
            && prevProps.formValues.name === this.props.formValues.name) {
            this.props.Model.title = `Clip configuration "${this.props.formValues.name}"`;
        }
    }

    prepareDataForSubmit = (data) => {
        return (undefined === data)
            ? null
            : Object.assign(
                {},
                this.props.Model.fields[this.props.Model.entityDataMapKey],
                {
                    id: this.props.formParams.id,
                    name: data.name,
                    product: convertToInt(data.products),
                    sports: null,
                    tournaments: data.tournaments,
                    clipConfigEventContentType: formatToSaveClipsOptions(data.eventContentTypes),
                }
            );
    };

    renderErrors() {
        super.renderErrors(
            'The requested clip configuration could not be found.',
            this.props.Model.entityLabel,
            createLinkToIndexPage()
        );
    }
    onCancel = () => {
        this.props.history.push(createLinkToIndexPage());
    };

    renderSaveButton = () => super.renderSaveButton({content: 'Save'});

    renderCancelButton = (props) => super.renderCancelButton({...props, type: 'button', onClick: this.onCancel});
}

export default withRouter(ClipConfigurationTournamentLevelForm);
