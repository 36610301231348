import React, {useEffect, useState} from 'react';
import {forEach as _forEach, get as _get, map as _map, flatMap as _flatMap} from 'lodash';
import {Link} from 'react-router-dom';

import {convertToInt} from '@utils/helpers';
import {
    CHANGELOG_ACTION_TYPE_CREATION,
    CHANGELOG_ACTION_TYPE_DELETION,
    CHANGELOG_ACTION_TYPE_MODIFICATION,
} from '@constants/variables';

export const renderLinkToElementPage = (row, isLink = false) => {
    const path = _get(row, 'secured_resource.path');

    if (!path) {
        return row.element_name;
    }

    const elementPath = path.replace('{id}', row.element_id);

    return (
        <Link
            to={elementPath}
            target='_blank'
            rel='noopener noreferrer'
        >
            {path && isLink ? elementPath : row.element_name}
        </Link>
    );
};

export const renderLinkToChangelogPage = (row) => {
    const changelogPagePath = `/changelog/resources/${row.secured_resource.id}/elements/${row.element_id}`;

    return (
        <Link
            to={changelogPagePath}
            target='_blank'
            rel='noopener noreferrer'
        >
            {row.element_id}
        </Link>
    );
};

export const useUrlIds = (url) => {
    const [ids, setIds] = useState([]);

    useEffect(() => {
        const regex = /(\d+)/g;
        const matches = url.match(regex);

        setIds(matches);
    }, [url]);

    return ids;
};

export const formatChangelogElementRevisionsData = (changelogRevisions) => {
    let changelogRevisionsData;
    let creationDateTime = '';
    let deletionDateTime = '';
    let creationUser = {};
    let creationApiUser = {};
    let deletionUser = {};
    let deletionApiUser = {};
    let path = '';

    _forEach(changelogRevisions, (revision) => {
        if (revision.changelog_action_type) {
            if (CHANGELOG_ACTION_TYPE_CREATION === convertToInt(revision.changelog_action_type.id)) {
                creationDateTime = revision.datetime;
                path = revision.secured_resource.path;
                creationUser = {
                    id: revision.user?.id,
                    name: revision.user?.name,
                };
                creationApiUser = {
                    id: revision.api_user?.id,
                    name: revision.api_user?.name,
                };
            }

            if (CHANGELOG_ACTION_TYPE_MODIFICATION === convertToInt(revision.changelog_action_type.id)) {
                path = revision.secured_resource.path;
            }

            if (CHANGELOG_ACTION_TYPE_DELETION === convertToInt(revision.changelog_action_type.id)) {
                deletionDateTime = revision.datetime;
                path = '';
                deletionUser = {
                    id: revision.user?.id,
                    name: revision.user?.name,
                };
                deletionApiUser = {
                    id: revision.api_user?.id,
                    name: revision.api_user?.name,
                };
            }
        }

        changelogRevisionsData = {
            secured_resource: revision.secured_resource,
            element_id: revision.element_id,
            element_name: revision.element_name,
            creationUser: creationUser,
            creationApiUser: creationApiUser,
            deletionUser: deletionUser,
            deletionApiUser: deletionApiUser,
            creationDateTime: creationDateTime,
            deletionDateTime: deletionDateTime,
            path: deletionDateTime ? '' : path,
        };
    });

    return changelogRevisionsData;
};

export const  extractChangelogChanges = (data) => {
    return _flatMap(data, element =>
        _map(element.changelog_changes, change => ({
            ...change,
            api_user: element.api_user,
            datetime: element.datetime,
            user: element.user,
            ip: element.ip,
        }))
    );
};

export const isChangelogElementPath = (path) => {
    const regex = /^\/changelog\/resources\/\d+\/elements\/\d+$/;

    return regex.test(path);
};
