import React from 'react';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

import {getBookingFiltersIsCancelledStatus} from '@utils/booking';
import {withClientBookingsFilters} from '@modules/booking/HOC/withClientBookingsFilters';
import {BookingsFiltersFields} from '@modules/booking/components/BookingsFiltersFields';
import Form from '@appComponents/ReduxFormControls';

const ClientEventBookingsFiltersComponent = (filtersProps) => {
    const {
        clientFilters,
        loading,
        restoreFilterValues,
        data: {
            products,
            eventContentTypes,
            matchEventTypes,
            deviceCategories,
            countries,
            clientPackages,
            bookingTypes,
        },
    } = filtersProps;

    return (
        <div className='filter__controls__container'>
            <BookingsFiltersFields {...filtersProps} />
            <Field
                className={'--customWidth --products'}
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='products'
                placeholder='Product'
                options={products}
                search
                setValue={restoreFilterValues(clientFilters, 'products')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='eventContentTypes'
                placeholder='Event content type'
                options={eventContentTypes}
                search
                setValue={restoreFilterValues(clientFilters, 'eventContentTypes')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='matchEventTypes'
                placeholder='Match event type'
                options={matchEventTypes}
                search
                setValue={restoreFilterValues(clientFilters, 'matchEventTypes')}
            />
            <Field
                className='--customWidth --deviceCategories'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='deviceCategories'
                placeholder='Device category'
                options={deviceCategories}
                search
                setValue={restoreFilterValues(clientFilters, 'deviceCategories')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='countries'
                placeholder='Country'
                options={countries}
                search
                setValue={restoreFilterValues(clientFilters, 'countries')}
            />
            <Field
                className='medium'
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='clientPackages'
                placeholder='Package'
                options={clientPackages}
                search
                setValue={restoreFilterValues(clientFilters, 'clientPackages')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='bookingTypes'
                placeholder='Type'
                options={bookingTypes}
                search
                setValue={restoreFilterValues(clientFilters, 'bookingTypes')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='isCancelled'
                placeholder='Cancellation status'
                options={getBookingFiltersIsCancelledStatus()}
                search
                setValue={restoreFilterValues(clientFilters, 'isCancelled', 0)}
            />
        </div>
    );
};

ClientEventBookingsFiltersComponent.propTypes = {
    filterProps: PropTypes.shape({
        clientFilters: PropTypes.object,
        data: PropTypes.objectOf(PropTypes.array),
        dataConfigProps: PropTypes.object,
        loading: PropTypes.bool,
        restoreFilterValues: PropTypes.func,
        restoreFilterDateValues: PropTypes.func,
    }),
};

export const ClientEventBookingsFilters = withClientBookingsFilters(ClientEventBookingsFiltersComponent, 'ClientEventBookings');

