import {
    BlackoutZonesForCountrySubdivisionFragment,
} from '@modules/country/utils/BlackoutZonesForCountrySubdivisionFragment';

export const renderCountryPostalCodeTableRow = (column, row) => {
    switch (column) {
        case 'id':
            return row.id;
        case 'subdivision':
            return (row.subdivision_name) ? row.subdivision_name : '-';
        case 'postal_code':
            return (row.code) ? row.code : '';
        case 'blackout_zones':
            return BlackoutZonesForCountrySubdivisionFragment(row);
        default:
            return '';
    }
};

export default renderCountryPostalCodeTableRow;
