import {getLink} from '@appComponents/Link';
import {showErrorModal} from '@utils/modal';

export const showPackageNotFoundError = (id) => {
    showErrorModal({
        header: 'Package was not found',
        redirectLink: getLink('clients.packages.index', { id }),
        errorButtonIcon: 'arrow circle left',
        errorButtonText: 'Back to packages list',
    });
};
