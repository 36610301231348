import {isEmpty as _isEmpty} from 'lodash';

export const Columns = {
    'id': {
        label: 'ID',
        sorting: (row) => parseInt(row.id, 10),
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'name': {label: 'Name', sorting: (row) => row.name ? row.name : ''},
    'encoding_datacenter': {
        label: 'Encoding datacenter',
        sorting: (row) => _isEmpty(row.encoding_datacenter) ? '' : row.encoding_datacenter.name,
    },
    'url': {label: 'URL', sorting: (row) => row.url},
    'failover_udp_origin_entry_point': {
        label: 'Failover',
        sorting: (row) => _isEmpty(row.failover_udp_origin_entry_point) ? '' : row.failover_udp_origin_entry_point.name,
    },
    'content': {
        label: 'Content',
        sorting: false,
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};
