const sdiOriginEntryPointsTableColumns = {
    'id': {
        label: 'ID',
        sorting: (row) => parseInt(row.id, 10),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {
        label: 'Name',
        sorting: (row) => row.name.capitalizeFirstLetter(),
    },
    'encoding_datacenter': {
        label: 'Encoding datacenter',
        sorting: (row) => (null === row.country)
            ? ''
            : row.encoding_datacenter.name.capitalizeFirstLetter(),
    },
    'input_id': {
        label: 'Input ID',
        sorting: row => parseInt(row.input_id, 10),
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};

export default sdiOriginEntryPointsTableColumns;
