import {CLIP_CONFIGURATION} from '@constants/resources';
import {
    CreateClipConfiguration,
    UpdateClipConfiguration,
    DeleteClipConfiguration,
} from '@graphql/clipConfiguration/mutation';
import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';

import validate from './SportLevelValidator';

export default function ClipConfigurationModel() {
    return {
        entityDataMapKey: 'clipConfig',
        entityLabel: 'Clip configuration',
        formName: 'Clip configuration',
        resources: CLIP_CONFIGURATION,
        showChangelog: true,
        validate: validate,
        dataMap: {
            clipConfig: {
                id: null,
                name: null,
            },
        },
        messages: {
            boxName: {
                delete: 'clipConfigurationMessage',
                error: 'clipConfigurationMessageError',
                success: 'clipConfigurationMessage',
            },
            text: {
                ...MESSAGES,
                DELETE_HEADER_ARGUMENT_FIRST: () => MESSAGES.DELETE_HEADER('clip configuration'),
                DELETE_CONFIRMATION: (label, name = '') => (
                    `Are you sure you want to delete the clip configuration ${name ? ` "${name}"` : ''}?`
                ),
            },
        },
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'clipConfig.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            name: {
                dataMapKey: 'clipConfig.name',
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    required: true,
                    type: 'input',
                },
            },
            product: {
                optionsKey: 'productsDropdown',
                props: {
                    label: 'Product',
                    required: true,
                    component: Form.SemanticSelect,
                    name: 'products',
                },
            },
            sports: {
                optionsKey: 'sports',
                props: {
                    label: 'Sports',
                    required: true,
                    component: Form.SemanticSelect,
                    name: 'sports',
                    hidden: false,
                    disabled: true,
                },
            },
            categories: {
                optionsKey: 'categories',
                props: {
                    label: 'Categories',
                    component: Form.SemanticSelect,
                    name: 'categories',
                    multiple: true,
                    disabled: true,
                },
            },
            tournaments: {
                optionsKey: 'tournaments',
                props: {
                    label: 'Tournaments',
                    component: Form.SemanticSelect,
                    name: 'tournaments',
                    multiple: true,
                    disabled: true,
                },
            },
            clips: {
                optionsKey: 'eventContentTypes',
                props: {
                    label: 'Clips',
                    component: Form.SemanticCheckboxTree,
                    required: true,
                    name: 'eventContentTypes',
                    hidden: false,
                    allowChildless: true,
                },
            },
        },
        query: {
            getEntity: null,
            getOptions: null,
        },
        mutation: {
            createEntity: CreateClipConfiguration,
            updateEntity: UpdateClipConfiguration,
            deleteEntity: DeleteClipConfiguration,
        },
    };
}
