import {RTS_COVERAGE_CONFIRMED} from '@constants/variables';
import {isRedStatusLabel} from '@utils/status';
import {isValidMatchStatusId} from '@modules/liveControl/utils/helpers';

export const getWarnings = (row) => {
    let warnings = [];

    if (row.property_licence_tech_setup_is_tv_streaming && null !== row.match_tv_streaming_encoder_id) {
        warnings.push('TV Streaming');
    }

    if (RTS_COVERAGE_CONFIRMED === row.match_rts_coverage_id) {
        warnings.push('RTS');
    }

    if (!row.match_has_live_scout) {
        warnings.push('No scout');
    }

    if (row.home_team_is_blacklisted) {
        warnings.push('Blacklisted home competitor');
    }

    if (row.away_team_is_blacklisted) {
        warnings.push('Blacklisted away competitor');
    }

    if (!row.match_has_live_odds) {
        warnings.push('No LO');
    }

    if (row.match_has_underage_competitors) {
        warnings.push('Underage competitors');
    }

    if (row.match_is_cancelled) {
        warnings.push('Cancelled');
    }

    if (row.match_is_postponed) {
        warnings.push('Postponed');
    }

    if (row.match_is_walkover) {
        warnings.push('W.O.');
    }

    if (isValidMatchStatusId(row.match_status_id)) {
        warnings.push(row.match_status_name);
    }

    if(isRedStatusLabel(row.event_status_id)) {
        warnings.push(row.event_status);
    }

    return warnings;
};
