import {get as _get} from 'lodash';

import {refetchQueryByName} from '@utils/apollo';
import {
    prefillCoverage,
    transformProductsForCoverageDropdown,
    updateClipProviderFieldBasedOnPreset,
} from '@modules/events/utils/formHelper';

import EventForm from './EventForm';
import {showErrorNoAvailablePresets} from '../utils/multipleEventActions';

export class EventsMultiplePresetApplyForm extends EventForm {
    prepareDataForSubmit(data) {
        return {
            id: 'UPDATE', //Dummy variable for update
            events: this.props.formParams.optionsVariables.eventsIds,
            event_preset: data.event_preset,
            description: data.description || null,
            color: data.color.substring(1),
            event_status: data.event_status || null,
            clip_provider: 0 < data.coverage.length ? data.clip_provider : null,
            products: data.coverage,
            notes: data.notes,
            as_additional_contents: !!data.as_additional_contents,
        };
    }

    componentDidMount() {
        super.componentDidMount();

        this.setUpdateSuccessCallback(() => {
            refetchQueryByName(this.props.formParams.queryForRefetch);
        });

        const products = _get(this.props, 'formParams.optionsVariables.products', []);

        this.setField('coverage', {
            options: transformProductsForCoverageDropdown(products),
            hidden: !products.length,
        });
    }

    componentDidUpdate(prevProps) {

        prefillCoverage(
            this.props.Model.fields.coverage.props.hidden,
            this.state.selectedPresetData?.event?.products,
            this.props.Model,
            this.setField,
            this.state,
            (newState) => this.setState(newState)
        );

        updateClipProviderFieldBasedOnPreset(this.props, prevProps.formValues, this.setField, this.state);
    }

    renderSaveButton(props = {}) {
        return super.renderSaveButton({...props, ...{content: 'Apply'}});
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const {
            GraphQLOptionsData: {
                loading: optionsLoading,
                eventPresets,
                propertyLicence,
            },
        } = nextProps;

        if (!optionsLoading && !this.state.dataReceived) {
            if (0 === eventPresets.length) {
                showErrorNoAvailablePresets();

                return;
            }

            this.setField('property_licence', {
                options: [{
                    id: propertyLicence.id,
                    text: `${propertyLicence.name} - ${propertyLicence.licensor.name}`,
                    key: propertyLicence.id,
                    value: propertyLicence.id,
                }],
            });

            const optionsForDropdown = [],
                presetsListObject = {};

            for (const preset of eventPresets){
                presetsListObject[preset.id] = preset;

                optionsForDropdown.push({
                    key: preset.id,
                    value: preset.id,
                    text: preset.name,
                });
            }

            this.setField('event_preset', {
                options: optionsForDropdown,
            });

            this.setState(() => ({
                dataReceived: true,
                presetsListObject,
            }));
        }
    }
}

export default EventsMultiplePresetApplyForm;
