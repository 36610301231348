import PropTypes from 'prop-types';
import React from 'react';

import {rmtpOriginEntryPointColumns} from '@modules/rtmpOriginEntryPoint/components/RmtpOriginEntryPointColumns';

import Table from '../../app/components/Table';
import * as CONST from '../../app/constants/variables';
import * as RESOURCES from '../../app/constants/resources';
import {rtmpOriginEntryPointRowRenderer} from './rtmpOriginEntryPointRowRenderer';

export const rtmpAuthorizationObject = {
    [RESOURCES.RTMP_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ,
};

const RtmpOriginEntryPointTable = (props) => {
    const { rtmpOriginEntryPoints, loading } = props;

    return (
        <div className='RtmpOriginEntryPoint__table'>
            <Table
                name='RtmpOriginEntryPointList'
                columns={rmtpOriginEntryPointColumns}
                data={rtmpOriginEntryPoints}
                loading={loading}
                rowRenderer={rtmpOriginEntryPointRowRenderer}
                pagination={false}
                noDataText='No RTMP origin entry points found.'
            />
        </div>
    );
};

RtmpOriginEntryPointTable.defaultProps = {
    rtmpOriginEntryPoint: [],
};

RtmpOriginEntryPointTable.propTypes = {
    rtmpOriginEntryPoints: PropTypes.array,
    rtmpOriginEntryPoint: PropTypes.array.isRequired,
    onLicensorDelete: PropTypes.func,
    loading: PropTypes.bool,
};

export default RtmpOriginEntryPointTable;
