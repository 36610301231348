import {
    GetTournamentsExcludingClipConfigTournamentsFilteredByTournamentCategories,
} from '@graphql/clipConfiguration/query';
import {convertToInt} from '@utils/helpers';
import {
    runQuery,
} from '@modules/clipConfiguration/utils/forms/common/formHelpers';
import {
    checkIfSelectedTournamentAreAvailable,
} from '@modules/clipConfiguration/utils/forms/tournamentLevelForm/checkIfSelectedTournamentsAreAvalable';

export const setTournaments = (
    runApolloRequest,
    setField,
    setState,
    state,
    formValuesTournaments,
    tournamentCategories,
    product,
    includeSelectedTournaments) => {
    if (0 < tournamentCategories.length && !state.TournamentDataLoading) {
        setState(() => ({TournamentDataLoading: true}));
        runQuery(runApolloRequest,
            GetTournamentsExcludingClipConfigTournamentsFilteredByTournamentCategories,
            {
                productId: parseInt(product),
                includeTournaments: includeSelectedTournaments.map((tournament) => (
                    convertToInt(tournament.id)
                )),
                tournamentsCategories: tournamentCategories,
            }
        ).then((response) => {
            setField('tournaments', {
                options: response.data.tournamentWithoutClipConfigSports,
                disabled: 0 === response.data.tournamentWithoutClipConfigSports.length,
                defaultValue: checkIfSelectedTournamentAreAvailable(
                    response.data.tournamentWithoutClipConfigSports,
                    formValuesTournaments
                ),
            });
            setState(() => ({
                TournamentData: response.data.tournamentWithoutClipConfigSports,
                TournamentDataLoading: false,
            }));
        });
    } else {
        setField('tournaments', {
            disabled: true,
            defaultValue: [],
        });
    }
};
