import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    isEmpty as _isEmpty,
} from 'lodash';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import navigation from '@constants/navigation';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import {prepareTournamentsForDropdown} from '@utils/tournament';
import useQuery from '@utils/hooks/useQuery';
import {
    GetUdpOriginEntryPointForList,
    GetFiltersForUdpOriginEntryPointsList,
} from '@graphql/udpOriginEntryPoints/query';
import {prepareContentCategoriesForDropdown} from '@utils/contentCategories';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';

import {exportExcel} from '../utils/export/udpOriginEntryPointIndexExcel';
import {udpOriginEntryPointsModalError} from '../utils/indexHelpers';
import UdpOriginEntryPointIndexButtons from '../components/UdpOriginEntryPointIndexButtons';
import UdpOriginEntryPointFilters from '../components/UdpOriginEntryPointFilters';
import UdpOriginEntryPointTable from '../components/UdpOriginEntryPointTable';

export const DEFAULT_FILTERS_VALUES = {
    contentCategories: [],
    encodingDatacenter: [],
    search: null,
    tournaments: [],
};

export function UdpOriginEntryPointIndex({
    areFiltersVisible = true,
    filters = {},
    Menu,
    Modal,
}) {
    const queryVariables = {
        search: filters.search || DEFAULT_FILTERS_VALUES.search,
        encodingDatacenter: filters.encodingDatacenter || DEFAULT_FILTERS_VALUES.encodingDatacenter,
        contentCategories: filters.contentCategories || DEFAULT_FILTERS_VALUES.contentCategories,
        tournaments: filters.tournaments || DEFAULT_FILTERS_VALUES.tournaments,
    };
    const {
        loading: areDataLoading = true,
        error: dataError = {},
        data = {},
    } = useQuery({
        query: GetUdpOriginEntryPointForList,
        options: {
            variables: queryVariables,
            refetch: filters.lastClickSubmit,
        },
    });

    const {
        loading: areFiltersLoading = true,
        data: dataForFilters = {},
    } = useQuery({
        query: GetFiltersForUdpOriginEntryPointsList,
    });

    if (!_isEmpty(dataError)) {
        Modal.setModal({
            isVisible: true,
            content: udpOriginEntryPointsModalError,
        });
    }

    useEffect(() => {
        Menu.storeMenu(navigation.infrastructure.key);
    }, []);

    return (
        <div>
            <HeaderRenderer
                buttons={UdpOriginEntryPointIndexButtons}
                exportExcelParams={exportExcel(data.udpOriginEntryPoints)}
                filters={UdpOriginEntryPointFilters}
                filtersButtonName="udpOriginEntryPoints"
                filtersProps={{
                    loading: areFiltersLoading,
                    data: {
                        encodingDatacenters: dataForFilters.encodingDatacenters || [],
                        contentCategories: (
                            dataForFilters.contentCategories
                                && sortDropdownOptionsAlphanumerically(
                                    prepareContentCategoriesForDropdown(dataForFilters.contentCategories)
                                )
                        )
                            || [],
                        tournaments: (
                            dataForFilters.tournaments
                                && sortDropdownOptionsAlphanumerically(
                                    prepareTournamentsForDropdown(dataForFilters.tournaments)
                                )
                        )
                            || [],
                        isVisible: areFiltersVisible,
                    },
                }}
                loading={areDataLoading}
                messagesBoxNames='udpOriginEntryPointMessage'
                pageTitle='UDP origin entry points'
            />

            <UdpOriginEntryPointTable
                udpOriginEntryPoints={data.udpOriginEntryPoints}
                loading={areDataLoading}
            />
        </div>
    );
}

UdpOriginEntryPointIndex.propTypes = {
    areFiltersVisible: PropTypes.bool,
    filters: PropTypes.shape({
        search: PropTypes.string,
        encodingDatacenter: PropTypes.array,
        contentCategories: PropTypes.array,
        tournaments: PropTypes.array,
    }),
    Menu: PropTypes.object.isRequired,
    Modal: PropTypes.object.isRequired,
};

const UdpOriginEntryPointIndexWrapped = FilterQueryWrapper(UdpOriginEntryPointIndex, {
    queryForRefresh: 'GetUdpOriginEntryPointForList',
    filterUrls: ['udpOriginEntryPoint.index'],
});

const mapDispatchToProps = mapModulesToProps(['Menu', 'Modal']);

const mapStateToProps = (state) => {
    return {
        filters: getSearchFilters(state, 'udpOriginEntryPoints', {
            ...DEFAULT_FILTERS_VALUES,
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(UdpOriginEntryPointIndexWrapped, {
        search: null,
        encodingDatacenter: [],
        contentCategories: [],
        tournaments: [],
    })
);
