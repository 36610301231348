import {Label} from 'semantic-ui-react';
import {isEmpty as _isEmpty} from 'lodash';
import React from 'react';

export const ShowWarnings = ({ warnings }) => {
    return (_isEmpty(warnings))
        ? ''
        : warnings.map((warning) => (
            <Label key={warning} color='red' size='mini'>{warning}</Label>
        ));
};
