import React from 'react';

import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import {IconEdit} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import * as RESOURCES from '@constants/resources';
import {Authorization} from '@appComponents/Authorization';
import {hasClients, namesOfClients, renderCheckbox} from '@utils/tableHelpers';

import { authorizationObject } from './authorizationObject';

export const RowRenderer = (column, row) => {
    switch (column) {
        case 'id':
        case 'name':
            return (
                <LinkWithAuthorization
                    authorization={authorizationObject}
                    link={{name: 'encodingJobProfile.edit', params: {id: row.id}}}
                >
                    {row[column]}
                </LinkWithAuthorization>
            );

        case 'product' :
            return (
                <div>
                    {(! row.product) ? '' : row.product.short_name}
                </div>
            );

        case 'distribution_type':
            return (
                <div>
                    {(! row.distribution_type) ? '' : row.distribution_type.name}
                </div>
            );
        case 'has_drm_support':
        case 'has_multi_audio_support':
        case 'is_default':
            return renderCheckbox(row[column]);

        case 'clients':
            return (
                <div>
                    {(false === hasClients(row)) ? 'All' : namesOfClients(row.clients)}
                </div>
            );

        case 'actions' :
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit size='large' link={getLink('encodingJobProfile.edit', {id: row.id})}
                            resource={RESOURCES.ENCODING_JOB_PROFILE}/>
                    </div>
                </Authorization>
            );

        default:
            return null;
    }
};

export default RowRenderer;
