import React from 'react';
import {Label, Popup} from 'semantic-ui-react';
import {has, join, map, sortBy} from 'lodash/fp';
import {isArray, toUpper} from 'lodash';

import {IconCheckmark} from '@appComponents/IconCollection';

export const columnObjectAlignCenter = {
    header: {
        align: 'center',
    },
    content: {
        align: 'center',
    },
};

export const columnObjectAlignLeft = {
    header: {
        align: 'left',
    },
    content: {
        align: 'left',
    },
};

export const renderNameOfRowColumn = (row, column) => (
    (!row || undefined === row[column] || null === row[column])
        ? ''
        : row[column].name || ''
);

export const renderProductColumnForComputerVision = (productName, isComputerVision) => (
    <>
        {productName}
        {isComputerVision
            ? (
                <Popup
                    content='Computer Vision'
                    position='top center'
                    trigger={<Label size='mini' className='td__label'>CV</Label>}
                />
            )
            : null
        }
    </>
);

export const getDisabledClassNameForCell = (tableRowData) => {
    return tableRowData.disabled || tableRowData.is_disabled ? '--is-disabled' : null;
};

export const renderCheckbox = (value) => {
    return value ? <IconCheckmark/> : '';
};

export const hasClients = (row) => {
    if (true === has('clients', row)) {
        return true === isArray(row.clients) && 0 < row.clients.length;
    }

    return false;
};

export const namesOfClients = (clients, prop = 'name', separator = ', ') => {
    return join(separator, map(prop, sortBy(client => toUpper(client.name), clients)));
};
