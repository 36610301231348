import React from 'react';

import {Authorization} from '@appComponents/Authorization';
import {IconEdit} from '@appComponents/IconCollection';
import {getLink} from '@appComponents/Link';
import {MEDIA_CONNECT_ORIGIN_ENTRY_POINT} from '@constants/resources';
import {
    authorizationObject,
    renderMediaConnectFeedsColumn,
    renderMediaConnectIdColumn,
    renderMediaConnectNameColumn,
} from '@modules/mediaConnect/components/MediaConnectTable/mediaConnectTableHelpers';

export const mediaConnectTableRenderer = (column, row) => {
    switch (column) {
        case 'id':
            return renderMediaConnectIdColumn(column, row);
        case 'name':
            return renderMediaConnectNameColumn(column, row);
        case 'encoding_datacenter':
            return row.encoding_datacenter.name;
        case 'mediaconnect_origin_feeds':
            return renderMediaConnectFeedsColumn(row);
        case 'actions': {
            return (
                <Authorization authorization={authorizationObject}>
                    <div className='actions-list-icon'>
                        <IconEdit
                            size='large'
                            link={getLink('mediaConnect.edit', {
                                id: row.id,
                            })}
                            resource={MEDIA_CONNECT_ORIGIN_ENTRY_POINT}
                        />
                    </div>
                </Authorization>
            );
        }
        default:
            return null;
    }
};
