import React from 'react';
import {get as _get} from 'lodash';

import {renderLink} from '@modules/country/utils/utils';
import {IconCheckmark} from '@appComponents/IconCollection';
import {authorizationObject} from '@modules/country/components/CountryTable/authorizationObject';

const countryRowRenderer = (column, row) => {
    switch (column) {
        case 'id':
            return renderLink('countries.generalInformation', row.id, row.id, authorizationObject);
        case 'name':
            return renderLink('countries.generalInformation', row.id, row.name, authorizationObject);
        case 'full_name':
            return row.full_name;
        case 'iso_alpha2_code':
            return row.iso_alpha2_code;
        case 'iso_alpha3_code':
            return row.iso_alpha3_code;
        case 'iso_numeric_code':
            return row.iso_numeric_code;
        case 'continent_id':
            return _get(row, 'continent.name', '');
        case 'handle_geo_restrictions_on_subdivision_level':
            return row.handle_geo_restrictions_on_subdivision_level ? <IconCheckmark/> : '';
        case 'country_subdivisions':
            return row.country_subdivisions.length
                ? renderLink('countries.subdivisions', row.id, row.country_subdivisions.length, authorizationObject)
                : '';
        default:
            return '';
    }
};

export default countryRowRenderer;
