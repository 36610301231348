export const rmtpOriginEntryPointColumns = {
    'id': {
        label: 'ID',
        sorting: (row) => parseInt(row.id, 10),
        header: {
            align: 'center',
            isCollapsed: true,
        },
        content: {
            align: 'center',
        },
    },
    'name': {
        label: 'Name',
        sorting: true,
    },
    'host' : {
        label: 'Host',
        sorting: true,
    },
    'location' : {
        label: 'Location',
        sorting: true,
    },
    'rtmp_origin_entry_point_type' : {
        label: 'Type',
        sorting: (row) => row.rtmp_origin_entry_point_type.name,
    },
    'origin_hosting_provider' : {
        label: 'Hosting provider',
        sorting: (row) => row.origin_hosting_provider.name,
    },
    'is_active': {
        label: 'Active',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'start_time': {
        label: 'Start time',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'stop_time': {
        label: 'End time',
        sorting: true,
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
    'actions': {
        label: 'Actions',
        header: {
            align: 'center',
        },
        content: {
            align: 'center',
        },
    },
};
