import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {get as _get} from 'lodash';
import moment from 'moment';

import {gql, graphql} from 'react-apollo';
import {routes} from '@constants/routes';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import navigation from '@constants/navigation';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import * as ChangeLogGraphQl from '@graphql/changeLog/index';
import {showModal} from '@utils/modal';

import ChangeLogTable from '../components/ChangeLogTable';
import ChangeLogFilters from '../components/ChangeLogFilters';
import {exportExcel as changelogExportExcel} from '../utils/export/changelogExcel';
import ChangeLogDetails from './ChangeLogDetails';

export class ChangeLogIndex extends Component {
    static defaultProps = {
        filters: {
            from: moment().set({'hour': 0, 'minute': 0, 'second': 0}).format('YYYY-MM-DD HH:mm:ss'),
            to: moment().add(1, 'days').set({
                'hour': 0,
                'minute': 0,
                'second': 0,
            }).format('YYYY-MM-DD HH:mm:ss'),
            elementId: null,
            elementName: null,
            resources: [],
            actions: [],
            users: [],
            apiUsers: [],
        },
    };

    static propTypes = {
        DataChangeLog: PropTypes.object.isRequired,
        DataChangeLogFilter: PropTypes.object.isRequired,
        filters: PropTypes.shape({
            from: PropTypes.string,
            to: PropTypes.string,
            elementId: PropTypes.string,
            securedResources: PropTypes.arrayOf(PropTypes.number),
            actionTypes: PropTypes.arrayOf(PropTypes.number),
        }),
        history: PropTypes.object,
        loadingRefetch: PropTypes.bool.isRequired,
        match: PropTypes.object,
        Menu: PropTypes.object,
        modal: PropTypes.object,
    };

    state = {
        match: this.props.match,
        modal: this.props.modal,
        modalWasClosed: false,
        shouldOpenModal: false,
        changelogRevisions: [],
        DataChangeLog: [],
        DataChangeLogFilter: [],
    };

    constructor(props) {
        super(props);
    }

    openModal(revisionId = null) {
        if (!revisionId) {
            return;
        }

        showModal({
            isVisible: true,
            size: 'small',
            content: <ChangeLogDetails revisionId={revisionId}/>,
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            DataChangeLog: {changelogRevisions},
            DataChangeLogFilter: {securedResources, changelogActionTypes},
        } = nextProps;
        const urlChanged = nextProps.match.path !== prevState.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.changelog.index.path,
            shouldOpenModal = urlChanged && urlIsNotIndex;

        let nextState = {
            modal: nextProps.modal,
            match: nextProps.match,
            shouldOpenModal: shouldOpenModal,
        };

        const
            modalChanged = nextProps.modal.isVisible !== prevState.modal.isVisible,
            modalIsNotVisible = !nextProps.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        modalWasClosed && nextProps.history.push(routes.changelog.index.path);

        if (securedResources && securedResources !== prevState.securedResources) {
            nextState.securedResources = securedResources.map((elementType) =>
                ({key: elementType.value, text: elementType.text, value: parseInt(elementType.value, 10)}));
        }

        if (changelogActionTypes && changelogActionTypes !== prevState.actionTypes) {
            nextState.actionTypes = changelogActionTypes.map((actionType) =>
                ({key: actionType.value, text: actionType.text, value: parseInt(actionType.value, 10)}));
        }

        if (changelogRevisions && changelogRevisions !== prevState.changelogRevisions) {
            nextState.changelogRevisions = changelogRevisions;
        }

        return nextState;
    }

    componentDidMount() {
        this.props.Menu.storeMenu(navigation.reportingCenter.key);

        if (this.props.match.path === routes.changelog.view.path) {
            this.openModal(_get(this.props, 'match.params.id', ''));
        }
    }

    componentDidUpdate() {
        if (this.state.shouldOpenModal) {
            this.openModal(_get(this.props, 'match.params.id', ''));
        }
    }

    render() {
        const loading = (
            this.props.DataChangeLog.loading
            || this.props.DataChangeLogFilter.loading
            || this.props.loadingRefetch
        );

        return (
            <div>
                <HeaderRenderer
                    exportExcelParams={changelogExportExcel(this.state.changelogRevisions)}
                    loading={loading}
                    pageTitle={'Changelog'}
                />
                <ChangeLogFilters
                    data={this.props.DataChangeLogFilter}
                    loading={loading}
                />
                <div className='clearfix'/>
                <ChangeLogTable
                    changelogRevisions={this.state.changelogRevisions}
                    loading={loading}
                />
            </div>
        );
    }
}

export const ChangeLogRevisionFilterQuery = gql(ChangeLogGraphQl.changeLogRevisionFilterQuery);
export const ChangeLogFilterQuery = gql(ChangeLogGraphQl.changeLogFilterQuery);

const ChangeLogWithQuery = compose(
    graphql(ChangeLogRevisionFilterQuery, {
        options: (props) => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
                variables: {
                    from: props.filters.from
                        ? moment(props.filters.from, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                        : ChangeLogIndex.defaultProps.filters.from,
                    to: props.filters.to
                        ? moment(props.filters.to, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                        : ChangeLogIndex.defaultProps.filters.to,
                    elementId: parseInt(props.filters.elementId, 10) || ChangeLogIndex.defaultProps.filters.elementId,
                    searchByElementName: props.filters.elementName || ChangeLogIndex.defaultProps.filters.elementName,
                    securedResource: props.filters.resources || ChangeLogIndex.defaultProps.filters.resources,
                    changelogActionType: props.filters.actions || ChangeLogIndex.defaultProps.filters.actions,
                    user: props.filters.users || ChangeLogIndex.defaultProps.filters.users,
                    apiUser: props.filters.apiUsers || ChangeLogIndex.defaultProps.filters.apiUsers,
                },
            };
        },
        name: 'DataChangeLog',
    }),
    graphql(ChangeLogFilterQuery, {
        options: () => {
            return {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'cache-and-network',
            };
        },
        name: 'DataChangeLogFilter',
    })
)(FilterQueryWrapper(ChangeLogIndex, {queryForRefresh: 'DataChangeLog', filterUrls: ['changelog.index']}));

const mapStateToProps = (state) => {
    return {
        filters: getSearchFilters(state, 'Changelog', ChangeLogIndex.defaultProps.filters),
        modal: state.modal,
    };
};

export default withRouter(connect(mapStateToProps, mapModulesToProps(['Menu', 'Modal']))(
    FilterUrlParamsWrapper(ChangeLogWithQuery, ChangeLogIndex.defaultProps.filters))
);
