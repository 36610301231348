import {withRouter} from 'react-router';
import {get as _get} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {convertToInt} from '@utils/helpers';
import {
    formatToSaveClipsOptions,
    setClipsTree,
    createLinkToIndexPage, clipConfigToSports,
} from '@modules/clipConfiguration/utils/forms/common/formHelpers';
import {refetchQueryByName} from '@utils/apollo';
import {setSportsForClipConfigurationSportLevelForm} from '@modules/clipConfiguration/utils/forms/sportsLevelForm/setSportsForClipConfigurationSportLevelForm';
import {setOptionsToSportsOnEditForm} from '@modules/clipConfiguration/utils/forms/sportsLevelForm/setOptionsToSportsOnEditForm';

export class ClipConfigurationSportLevelForm extends DefaultForm {

    componentDidMount() {
        const link = createLinkToIndexPage();

        if (this.props.formParams.id) {
            clipConfigToSports(this.runApolloRequest, this.setState);
        }

        this.setOnChangeCallback({
            products: (event) => {
                setSportsForClipConfigurationSportLevelForm(event.value, this.runApolloRequest, this.setField, this.setState, this.state);
                setClipsTree(this, event.value, []);
            },
        });

        this.setCreateSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });

        this.setDeleteSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });
        this.setUpdateSuccessCallback(() => {
            refetchQueryByName('GetClipConfigs');
            this.props.history.push(link);
        });

        this.setState(() => ({
            defaultForm_formTitle: 'Add new clip configuration on sport level',
        }));

        if (this.props.formParams.id) {
            this.setField('sports', {
                disabled: false,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const clipConfig = _get(this.props, 'GraphQLEntityData.clipConfig', null);

        if (this.state?.allSports && this.props.formValues.products) {
            setOptionsToSportsOnEditForm(this.runApolloRequest, this.setField, this.setState, this.state, this.props.formParams.id, this.props.formValues.products);
        }

        if (clipConfig && !this.state?.dataLoaded) {
            const selectedSports = clipConfig.sports.map((sport) => (
                sport.id
            ));

            setClipsTree(this, clipConfig.product.id, clipConfig.event_content_type_configs);
            this.setField('sports', {
                defaultValue: selectedSports,
            });

            this.setField('products', {
                defaultValue: clipConfig.product.id,
                disabled: true,
            });

            this.setState(() => ({dataLoaded: true}));
        }

        if (this.props.formParams.id && this.props.formValues.name
            && prevProps.formValues.name === this.props.formValues.name) {
            this.props.Model.title = `Clip configuration "${this.props.formValues.name}"`;
        }
    }

    prepareDataForSubmit = (data) => {
        return (undefined === data)
            ? null
            : Object.assign(
                {},
                this.props.Model.fields[this.props.Model.entityDataMapKey],
                {
                    id: this.props.formParams.id,
                    name: data.name,
                    product: convertToInt(data.products),
                    sports: data.sports,
                    tournaments: null,
                    clipConfigEventContentType: formatToSaveClipsOptions(data.eventContentTypes),
                }
            );
    };

    renderErrors() {
        super.renderErrors(
            'The requested clip configuration could not be found.',
            this.props.Model.entityLabel,
            createLinkToIndexPage()
        );
    }
    onCancel = () => {
        this.props.history.push(createLinkToIndexPage());
    };

    renderSaveButton = () => super.renderSaveButton({content: 'Save'});

    renderCancelButton = (props) => super.renderCancelButton({...props, type: 'button', onClick: this.onCancel});
}

export default withRouter(ClipConfigurationSportLevelForm);
