import {get as _get} from 'lodash';

import {parseToBoolean} from "./helpers";
import moment from "moment/moment";

/**
 * Get filters from redux-store or get from default filters
 * @param state Global redux state
 * @param filterName Filter name in redux store (app.filters.NAME)
 * @param defaultFiltersParams (object with default params)
 * @param overwriteParams Allow to send own object with parameters
 * @returns {*}
 */
export const getSearchFilters = (state, filterName, defaultFiltersParams = {}, overwriteParams = {}) => {
    if (Object.keys(overwriteParams).length) {
        return overwriteParams;
    }

    if (state.app.filters
        && state.app.filters[filterName]
        && state.app.filters[filterName].params
        // There is initial filter param in FiltersWrapper HOC (REDUX: app.filters.NAME.params.timestamp)
        // with default timestamp (will be always at least one param).
        // More than one param means that someone click on search button and send additional params
        // or there are params in redux-store
        // (lastClickSubmit and timestamp redux-store parameters)
        && (1 < Object.keys(state.app.filters[filterName].params).length)
    ) {
        return state.app.filters[filterName].params;
    }

    /**
     * Add timestamp to default filter params
     * to get "timestamp" value in FilterQueryWrapper::componentWillReceiveProps() - nextProps
     *
     * eg.
     * - Competitors Search with only "Search" field. Without value in Search, we pass default params
     * because of condition ((1 < Object.keys(state.app.filters[filterName].params).length),
     * but we need information about timestamp to compare current timestamp with next timestamp in willReceiveProps
     */
    let actualTimestamp = _get(state, `app.filters[${filterName}].params.timestamp`, null);

    /**
     * Default filter params with timestamp property
     */
    if  (null !== actualTimestamp) {
        return Object.assign(defaultFiltersParams, {timestamp: actualTimestamp});
    }

    return defaultFiltersParams;
};

export const convertBooleanValueForFilters = (value, emptyValue = null) => {
    if (null === value || undefined === value || '' === value) {
        return emptyValue;
    }

    return parseToBoolean(value);
};

export const reverseBooleanValueForFilters = (value, emptyValue = null) => {
    if (null === value || undefined === value || '' === value) {
        return emptyValue;
    }

    return !parseToBoolean(value);
};

export const dropdownParser = (valuesForDropdown) => {
    return valuesForDropdown.map((element) => ({
        key: parseInt(element.key, 10),
        text: element.text,
        value: parseInt(element.value, 10),
    }));
};

export const defineNextState = (nextState, thisState, date, name) => {
    nextState.fromMaxTime = null;
    nextState.toMinTime = null;

    if ('from' === name) {
        if(!date._isValid) {
            date = moment().hours(0).minutes(0);
        }

        nextState.toMinDate = date;

        if (date.format('YYYY-MM-DD') === thisState.fromMaxDate.format('YYYY-MM-DD')) {
            nextState.fromMaxTime = thisState.fromMaxDate;
            nextState.toMinTime = date;
        }
    } else if ('to' === name) {
        if(!date._isValid) {
            date = moment().hours(23).minutes(59);
        }

        nextState.fromMaxDate = date;

        if (date.format('YYYY-MM-DD') === thisState.toMinDate.format('YYYY-MM-DD')) {
            nextState.fromMaxTime = date;
            nextState.toMinTime = thisState.toMinDate;
        }
    }
};
