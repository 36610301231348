import React from 'react';
import PropTypes from 'prop-types';
import Table from '@appComponents/Table';
import {mediaConnectTableRenderer} from '@modules/mediaConnect/components/MediaConnectTable/mediaConnectTableRenderer';
import {mediaConnectTableColumns} from '@modules/mediaConnect/components/MediaConnectTable/mediaConnectTableColumns';

const MediaConnectTable = ({mediaconnectOriginEntryPoints, loading}) => {
    return (
        <Table
            name='mediaConnectTable'
            columns={mediaConnectTableColumns}
            data={mediaconnectOriginEntryPoints}
            loading={loading}
            rowRenderer={mediaConnectTableRenderer}
            noDataText='No Media Connect origin entry points found'
        />
    );
};

MediaConnectTable.propTypes = {
    mediaconnectOriginEntryPoints: PropTypes.array,
    loading: PropTypes.bool,
};

export default MediaConnectTable;
