import {CENTERED_HEADLINE_AND_BODY} from '@modules/country/utils/utils';

export const countrySubdivisionColumns = {
    id: {
        label: 'ID',
        sorting: (row) => parseInt(row.id, 10),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    name: {
        label: 'Name',
        sorting: (row) => (row.name ? row.name.toLowerCase() : ''),
    },
    iso_code: {
        label: 'ISO code',
        sorting: (row) => (row.iso_code ? row.iso_code.toUpperCase() : ''),
        ...CENTERED_HEADLINE_AND_BODY,
    },
    postal_codes: {
        label: 'Postal codes',
        sorting: (row) => (row.num_postal_codes ? row.num_postal_codes : 0),
        ...CENTERED_HEADLINE_AND_BODY,
    },
};
