import PropTypes from 'prop-types';
import React from 'react';

import sdiOriginEntryPointsTableColumns
    from '@modules/sdiOriginEntryPoint/components/SdiOriginEntryPointsTable/sdiOriginEntryPointsTableColumns';
import Table from '@modules/app/components/Table';
import sdiOriginEntryPointsTableRowRenderer
    from '@modules/sdiOriginEntryPoint/components/SdiOriginEntryPointsTable/sdiOriginEntryPointsTableRowRenderer';

const SdiOriginEntryPointsTable = ({sdiOriginEntryPoints, loading}) => {
    return (
        <div className='sdiOriginEntryPoints__table'>
            <Table
                name='sdiOriginEntryPointsList'
                columns={sdiOriginEntryPointsTableColumns}
                data={sdiOriginEntryPoints}
                loading={loading}
                rowRenderer={sdiOriginEntryPointsTableRowRenderer}
                pagination={false}
                noDataText={'No SDI origin entry points found'}
            />
        </div>
    );
};

SdiOriginEntryPointsTable.propTypes = {
    sdiOriginEntryPoints: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};

export default SdiOriginEntryPointsTable;
