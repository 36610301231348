import {debounce as _debounce, map as _map} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Field} from 'redux-form';

import {gql, withApollo} from 'react-apollo';
import {showConstraintsModal} from '@modules/events/utils/constraintsTables';
import {eventPresets} from '@graphql/propertyLicences/eventPresets';
import {FiltersWrapper, getSelectedValues} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {
    EVENT_NON_SPORTRADAR_TYPE,
    EVENT_SPORTRADAR_TYPE,
} from '@constants/variables';
import {contentCategoriesFilteredByLevelAndParents} from '@graphql/content';
import {tournamentCategoriesFilteredBySports} from '@graphql/tournamentCategories';
import {GetStadiumsByTournamentsForDropdown} from '@graphql/stadium/query';
import {GetTournamentsByTournamentCategories} from '@graphql/tournament/query';
import {GetSportsForDropdown} from '@graphql/sport/query';
import {GetContentCategoriesForDropdown} from '@graphql/contentCategory/query';
import {GetTvChannelsForDropdown} from '@graphql/tvChannels/query';
import {filteredSelectedTournament, filteredSelectedTournamentCategory} from '@modules/events/utils/eventSchedule';
import {getTodayDate, getTomorrowDate} from '@utils/date';
import {defineNextState} from '@utils/filters';

import {setTvChannelState} from '../utils/eventContentHelper';

export class EventScheduleFiltersComponent extends React.Component {
    static propTypes = {
        client: PropTypes.object.isRequired,
        clipProviders: PropTypes.array,
        contentCategoriesLevel1: PropTypes.array,
        contributionTypes: PropTypes.array,
        data: PropTypes.object.isRequired,
        encodingDatacenters: PropTypes.array,
        eventStatuses: PropTypes.array,
        eventTypes: PropTypes.array,
        eventContentTypes: PropTypes.array,
        eventContentVariants: PropTypes.array,
        filters: PropTypes.object.isRequired,
        form: PropTypes.object.isRequired,
        hasEventReport: PropTypes.array,
        hasMatchId: PropTypes.array,
        loading: PropTypes.bool.isRequired,
        matchProperties: PropTypes.array,
        MessageBox: PropTypes.object,
        products: PropTypes.array,
        productStatuses: PropTypes.array,
        propertyLicences: PropTypes.array,
        scheduledOrNot: PropTypes.array,
        sports: PropTypes.array,
        tvChannels: PropTypes.array,
        restoreFilterValues: PropTypes.func.isRequired,
        restoreFilterDateValues: PropTypes.func.isRequired,
        venues: PropTypes.array,
    };

    static defaultProps = {
        clipProviders: [],
        contentCategoriesLevel1: [],
        contributionTypes: [],
        encodingDatacenters: [],
        eventStatuses: [],
        eventTypes: [],
        hasEventReport: [],
        hasMatchId: [],
        matchProperties: [],
        products: [],
        productStatuses: [],
        propertyLicences: [],
        scheduledOrNot: [],
        sports: [],
        venues: [],
        tvChannels: [],
        eventContentTypes: [],
        eventContentVariants: [],
    };

    static onSubmit = (data) => {
        let from = moment(data.from).format('YYYY-MM-DD HH:mm'),
            to = moment(data.to).format('YYYY-MM-DD HH:mm');

        if (from !== data.from && to !== data.to) {
            data.from = getTodayDate();
            data.to = getTomorrowDate();
        } else if (from !== data.from) {
            data.from = moment(data.to).subtract(1, 'd').format('YYYY-MM-DD HH:mm');
        }  else if (to !== data.to) {
            data.to = moment(data.from).add(1, 'd').format('YYYY-MM-DD HH:mm');
        }

        showConstraintsModal(data);

        return data;
    };

    constructor() {
        super();

        this.state = {
            clipProvider: [],
            contentCategoriesLevel2: [],
            contentCategoriesLevel3: [],
            eventPresets: [],
            fromMaxDate: null,
            fromMaxDateDefault: null,
            fromMaxTime: moment().hours(23).minutes(59),
            hasContentCategoryLevel1: false,
            hasContentCategoryLevel2: false,
            hasSport: false,
            hasTournamentCategory: false,
            hasSelectedContentCategoryLevel1: false,
            hasSelectedContentCategoryLevel2: false,
            hasSelectedCoverage: false,
            hasSelectedPropertyLicence: false,
            hasSelectedSport: false,
            hasSelectedTournament: false,
            hasSelectedTournamentCategory: false,
            selectedContentCategoriesLevel2: [],
            selectedContentCategoriesLevel3: [],
            selectedEventPresets: [],
            selectedStadiums: [],
            selectedTournamentCategories: [],
            selectedTournaments: [],
            stadiums: [],
            toMinDate: null,
            toMinDateDefault: null,
            toMinTime: moment().hours(0).minutes(0),
            tournamentCategories: [],
            tournaments: [],
            tvChannels: [],
            tvChannelsLoading: false,
            sports: [],
            sportsLoading: false,
            contentCategoriesLevel1: [],
            contentCategoriesLevel1Loading: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        let fromMaxDate = this.props.restoreFilterDateValues(nextProps.filters.EventSchedule, 'to', moment().add(1, 'd')),
            toMinDate = this.props.restoreFilterDateValues(nextProps.filters.EventSchedule, 'from', moment()),
            nextState = {};

        fromMaxDate = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
        toMinDate = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);

        if (fromMaxDate && !moment(this.state.fromMaxDateDefault).isSame(fromMaxDate)) {
            nextState.fromMaxDateDefault = fromMaxDate;
            nextState.fromMaxDate = fromMaxDate;
        }

        if (toMinDate && !moment(this.state.toMinDateDefault).isSame(toMinDate)) {
            nextState.toMinDateDefault = toMinDate;
            nextState.toMinDate = toMinDate;
        }

        if (
            nextState.fromMaxDateDefault &&
            nextState.toMinDateDefault &&
            nextState.fromMaxDateDefault.format('YYYY-MM-DD') === nextState.toMinDateDefault.format('YYYY-MM-DD')
        ) {
            nextState.fromMaxTime = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
            nextState.toMinTime = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);
        }

        this.setState(() => (nextState));
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.hasSelectedSport && this.state.selectedTournamentCategories
            && this.state.hasSelectedSport !== prevState.hasSelectedSport) {
            this.setState(() => ({
                selectedTournamentCategories: [],
            }));
        }

        if (!this.state.hasSelectedTournamentCategory && this.state.selectedTournaments
            && this.state.hasSelectedTournamentCategory !== prevState.hasSelectedTournamentCategory) {
            this.setState(() => ({
                selectedTournaments: [],
            }));
        }

        if (!this.state.hasSelectedContentCategoryLevel1 && this.state.selectedContentCategoriesLevel2
            && this.state.hasSelectedContentCategoryLevel1 !== prevState.hasSelectedContentCategoryLevel1) {
            this.setState(() => ({
                selectedContentCategoriesLevel2: [],
            }));
        }

        filteredSelectedTournamentCategory(this.state.selectedSports, prevState, this.setState.bind(this));
        filteredSelectedTournament(
            prevState,
            this.setState.bind(this),
            this.state.tournaments,
            this.state.selectedTournaments
        );
    }

    getEventPresetsFromGraphQL = _debounce((propertyLicenceIds) => {
        this.props.client.query({
            query: gql(eventPresets),
            variables: {propertyLicence: propertyLicenceIds},
        }).then((result) => {
            let eventPresets = result.data.eventPresets.mapDataForDropdownWithIntVal().sort((a, b) => (
                    a.text > b.text ? 1 : -1
                )),
                selectedEventPresets = this.state.selectedEventPresets;

            this.setState(() => ({
                eventPresets: eventPresets,
                eventPresetsLoading: false,
            }));

            this.props.form.changeValue('eventPreset', getSelectedValues(selectedEventPresets, eventPresets));
        }).catch((error) => {
            this.setState(() => ({
                eventPresets: [],
                eventPresetsLoading: false,
            }));

            this.props.MessageBox.addMessage(
                'eventsIndex',
                'Failed to fetch event presets filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getSportsFromGraphQL = _debounce(() => {
        this.props.client.query({
            query: GetSportsForDropdown,
        }).then((result) => {
            this.setState(() => ({
                sportsLoading: false,
                sports: sortDropdownOptionsAlphanumerically(result.data.sports.map((sport) => ({
                    key: sport.value,
                    text: sport.text,
                    value: convertToInt(sport.value),
                }))),
            }));
        }).catch((error) => {
            this.setState(() => ({
                sport: [],
                sportsLoading: false,
            }));

            this.props.MessageBox.addMessage(
                'eventsIndex',
                'Failed to fetch sports filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getContentCategoriesFromGraphQL = _debounce(() => {
        this.props.client.query({
            query: GetContentCategoriesForDropdown,
            variables: {
                level: 1,
            },
        }).then((result) => {
            this.setState(() => ({
                contentCategoriesLevel1Loading: false,
                contentCategoriesLevel1: sortDropdownOptionsAlphanumerically(
                    result.data.contentCategories.map((contentCategory) => ({
                        key: contentCategory.value,
                        text: contentCategory.text,
                        value: convertToInt(contentCategory.value),
                    }))),
            }));
        }).catch((error) => {
            this.setState(() => ({
                contentCategoriesLevel1: [],
                contentCategoriesLevel1Loading: false,
            }));

            this.props.MessageBox.addMessage(
                'eventsIndex',
                'Failed to fetch contentCategoriesLevel1 filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getTvChannelsFromGraphQL = _debounce(() => {
        this.props.client.query({
            query: GetTvChannelsForDropdown,
        }).then((result) => {
            this.setState(() => ({
                tvChannels: result.data.tvChannels.mapDataForDropdownWithIntVal(),
            }));
        }).catch((error) => {
            this.setState(() => ({
                contentCategoriesLevel1: [],
                contentCategoriesLevel1Loading: false,
            }));

            this.props.MessageBox.addMessage(
                'eventsIndex',
                'Failed to fetch tvChannels filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getTournamentCategoriesFromGraphQL = _debounce((sportIds) => {
        this.props.client.query({
            query: gql(tournamentCategoriesFilteredBySports),
            variables: {
                sports: sportIds,
            },
        }).then((result) => {
            const tournamentCategories = sortDropdownOptionsAlphanumerically(
                    result.data.tournamentCategories.mapDataForDropdownWithIntVal()
                ),
                selectedTournamentCategories = this.state.selectedTournamentCategories;

            this.setState(() => ({
                selectedSports: sportIds,
                tournamentCategories: tournamentCategories,
                tournamentCategoriesLoading: false,
            }));

            this.props.form.changeValue('category',
                getSelectedValues(selectedTournamentCategories, tournamentCategories)
            );
        }).catch((error) => {
            this.setState(() => ({
                tournamentCategories: [],
                tournamentCategoriesLoading: false,
            }));

            this.props.MessageBox.addMessage(
                'eventsIndex',
                'Failed to fetch tournament categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getTournamentsFromGraphQL = _debounce((tournamentCategoryIds) => {
        this.props.client.query({
            query: GetTournamentsByTournamentCategories,
            variables: {
                tournamentCategories: tournamentCategoryIds,
            },
        }).then((result) => {
            const tournaments = sortDropdownOptionsAlphanumerically(
                    result.data.tournaments.mapDataForDropdownWithIntVal()
                ),
                selectedTournaments = this.state.selectedTournaments;

            this.setState(() => ({
                tournaments: tournaments,
                tournamentsLoading: false,
            }));

            this.props.form.changeValue('tournament',
                getSelectedValues(selectedTournaments, tournaments)
            );
        }).catch((error) => {
            this.setState(() => ({
                tournaments: [],
                tournamentsLoading: false,
            }));

            this.props.MessageBox.addMessage(
                'eventsIndex',
                'Failed to fetch tournaments filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getStadiumsFromGraphQL = _debounce((tournamentIds) => {
        this.props.client.query({
            query: GetStadiumsByTournamentsForDropdown,
            variables: {tournaments: tournamentIds},
        }).then((result) => {
            let stadiums = result.data.stadiums.mapDataForDropdownWithIntVal(),
                selectedStadiums = this.state.selectedStadiums;

            this.setState(() => ({
                stadiums: stadiums,
                stadiumsLoading: false,
            }));

            this.props.form.changeValue('stadium', getSelectedValues(selectedStadiums, stadiums));
        }).catch((error) => {
            this.setState(() => ({
                stadiums: [],
                stadiumsLoading: false,
            }));

            this.props.MessageBox.addMessage(
                'eventsIndex',
                'Failed to fetch stadiums filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getContentCategoriesLevel2FromGraphQL = _debounce((contentCategoryLevel1Ids) => {
        this.props.client.query({
            query: gql(contentCategoriesFilteredByLevelAndParents),
            variables: {
                level: 2,
                parents: contentCategoryLevel1Ids,
            },
        }).then((result) => {
            const contentCategoriesLevel2 = sortDropdownOptionsAlphanumerically(
                    result.data.contentCategories.mapDataForDropdownWithIntVal()
                ),
                selectedContentCategoriesLevel2 = this.state.selectedContentCategoriesLevel2;

            this.setState(() => ({
                contentCategoriesLevel2: contentCategoriesLevel2,
                contentCategoriesLevel2Loading: false,
            }));

            this.props.form.changeValue('contentCategoryLevel2',
                getSelectedValues(selectedContentCategoriesLevel2, contentCategoriesLevel2)
            );
        }).catch((error) => {
            this.setState(() => ({
                contentCategoriesLevel2: [],
                contentCategoriesLevel2Loading: false,
            }));

            this.props.MessageBox.addMessage(
                'eventsIndex',
                'Failed to fetch 2nd level content categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getContentCategoriesLevel3FromGraphQL = _debounce((contentCategoryLevel2Ids) => {
        this.props.client.query({
            query: gql(contentCategoriesFilteredByLevelAndParents),
            variables: {
                level: 3,
                parents: contentCategoryLevel2Ids,
            },
        }).then((result) => {
            const contentCategoriesLevel3 = sortDropdownOptionsAlphanumerically(
                    result.data.contentCategories.mapDataForDropdownWithIntVal()
                ),
                selectedContentCategoriesLevel3 = this.state.selectedContentCategoriesLevel3;

            this.setState(() => ({
                contentCategoriesLevel3: contentCategoriesLevel3,
                contentCategoriesLevel3Loading: false,
            }));

            this.props.form.changeValue('contentCategoryLevel3',
                getSelectedValues(selectedContentCategoriesLevel3, contentCategoriesLevel3)
            );
        }).catch((error) => {
            this.setState(() => ({
                contentCategoriesLevel3: [],
                contentCategoriesLevel3Loading: false,
            }));

            this.props.MessageBox.addMessage(
                'eventsIndex',
                'Failed to fetch 3rd level content categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    onPropertyLicenceChange = (event, value) => {
        let state = {
            hasSelectedPropertyLicence: false,
            eventPresets: [],
            eventPresetsLoading: false,
        };

        if (0 < value.length) {
            state = {
                hasSelectedPropertyLicence: true,
                eventPresetsLoading: true,
            };

            let propertyLicenceIds = value.map(propertyLicence => convertToInt(propertyLicence));

            this.getEventPresetsFromGraphQL(propertyLicenceIds);
        } else {
            this.props.form.changeValue('eventPreset', null);
        }

        this.setState(() => (state));
    };

    onEventPresetChange = (event, value) => {
        this.setState(() => ({selectedEventPresets: value}));
    };

    onEventTypeChange = (event, value) => {
        const type = parseInt(value, 10);
        let hasSport = (type === EVENT_SPORTRADAR_TYPE),
            hasContentCategoryLevel1 = (type === EVENT_NON_SPORTRADAR_TYPE);

        let state = {
            hasSelectedContentCategoryLevel1: false,
            hasSelectedSport: false,
            hasContentCategoryLevel1: hasContentCategoryLevel1,
            hasSport: hasSport,
        };

        if (hasContentCategoryLevel1) {
            this.getContentCategoriesFromGraphQL();
        } else {
            this.props.form.changeValue('contentCategoryLevel1', null);
            this.props.form.changeValue('contentCategoryLevel2', null);
            this.props.form.changeValue('contentCategoryLevel3', null);
            this.props.form.changeValue('venue', null);
        }

        if (hasSport) {
            state.sportsLoading = true;
            this.getSportsFromGraphQL();
        } else {
            this.props.form.changeValue('sport', null);
            this.props.form.changeValue('category', null);
            this.props.form.changeValue('tournament', null);
            this.props.form.changeValue('stadium', null);
        }

        this.setState(() => (state));
    };

    onSportChange = (event, value) => {
        let state = {
            hasSelectedSport: false,
            hasSportsWithTvChannel: false,
            tournamentCategories: [],
            tournamentCategoriesLoading: false,
            tvChannelsLoading: false,
        };

        if (0 < value.length) {
            state = {
                hasSelectedSport: true,
                tournamentCategoriesLoading: true,
            };

            const sportIds = _map(value, (sport) => (
                parseInt(sport, 10)
            ));

            setTvChannelState(this.props.form, value, this.setStateHandler, 'eventsIndex');

            this.getTvChannelsFromGraphQL();
            this.getTournamentCategoriesFromGraphQL(sportIds);
        } else {
            this.props.form.changeValue('category', null);
            this.props.form.changeValue('tvChannels', null);
        }

        this.setState(() => (state));
    };

    setStateHandler = (stateObject) => {
        this.setState(() => (stateObject));
    };

    onTournamentCategoryChange = (event, value) => {
        let state = {
            hasSelectedTournamentCategory: false,
            tournaments: [],
            tournamentsLoading: false,
        };

        if (0 < value.length) {
            state = {
                hasSelectedTournamentCategory: true,
                selectedTournamentCategories: value,
                tournamentsLoading: true,
            };

            let tournamentCategoryIds = _map(value, (tournamentCategory) => (
                parseInt(tournamentCategory, 10)
            ));

            this.getTournamentsFromGraphQL(tournamentCategoryIds);
        } else {
            this.props.form.changeValue('tournament', null);
        }

        this.setState(() => (state));
    };

    onTournamentChange = (event, value) => {
        let state = {
            hasSelectedTournament: false,
            stadiums: [],
            stadiumsLoading: false,
        };

        if (0 < value.length) {
            state = {
                hasSelectedTournament: true,
                selectedTournaments: value,
                stadiumsLoading: true,
            };

            let tournamentIds = value.map(tournament => convertToInt(tournament));

            this.getStadiumsFromGraphQL(tournamentIds);
        } else {
            this.props.form.changeValue('stadium', null);
        }

        this.setState(() => (state));
    };

    onStadiumChange = (event, value) => {
        this.setState(() => ({selectedStadiums: value}));
    };

    onContentCategoryLevel1Change = (event, value) => {
        let state = {
            hasSelectedContentCategoryLevel1: false,
            contentCategoriesLevel2: [],
            contentCategoriesLevel2Loading: false,
        };

        if (0 < value.length) {
            state = {
                hasSelectedContentCategoryLevel1: true,
                contentCategoriesLevel2Loading: true,
            };

            let contentCategoryLevel2Ids = _map(value, (contentCategoryLevel2) => (
                parseInt(contentCategoryLevel2, 10)
            ));

            this.getContentCategoriesLevel2FromGraphQL(contentCategoryLevel2Ids);
        } else {
            this.props.form.changeValue('contentCategoryLevel2', null);
        }

        this.setState(() => (state));
    };

    onContentCategoryLevel2Change = (event, value) => {
        let state = {
            hasSelectedContentCategoryLevel2: false,
            contentCategoriesLevel3: [],
            contentCategoriesLevel3Loading: false,
        };

        if (0 < value.length) {
            state = {
                hasSelectedContentCategoryLevel2: true,
                selectedContentCategoriesLevel2: value,
                contentCategoriesLevel3Loading: true,
            };

            let contentCategoryLevel3Ids = _map(value, (contentCategoryLevel3) => (
                parseInt(contentCategoryLevel3, 10)
            ));

            this.getContentCategoriesLevel3FromGraphQL(contentCategoryLevel3Ids);
        } else {
            this.props.form.changeValue('contentCategoryLevel3', null);
        }

        this.setState(() => (state));
    };

    onContentCategoryLevel3Change = (event, value) => {
        this.setState(() => ({
            selectedContentCategoriesLevel3: value,
        }));
    };

    onChange = (date, name) => {
        let nextState = {};

        defineNextState(nextState, this.state, date, name);

        this.setState(() => (nextState));
    };

    onCoverageChange = (event, value) => {
        if (0 < value.length) {
            this.setState({
                hasSelectedCoverage: true,
                selectedCoverage: value,
            });
        } else {
            this.setState({ hasSelectedCoverage: false });
            this.props.form.changeValue('clipProvider', []);
        }
    };

    render() {
        const {
                clipProviders,
                contributionTypes,
                coverage,
                encodingDatacenters,
                eventContentTypes,
                eventContentVariants,
                eventStatuses,
                eventTypes,
                hasEventReport,
                hasMatchId,
                matchProperties,
                products,
                productStatuses,
                propertyLicences,
                scheduledOrNot,
                venues,
            } = this.props.data,
            loading = this.props.loading;

        return (
            <div className='eventSchedule__filters filter__controls__container'>
                <Field
                    component={Form.ReduxInput}
                    disabled={loading}
                    name='search'
                    placeholder='Search...'
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'search') ?? ''}
                />
                <Field
                    className='--minimalWidth --datetimeWithoutSeconds'
                    component={Form.ReduxInput}
                    configuration={{
                        onChange: this.onChange,
                        calendarClassName: 'filterDatepicker',
                        dateFormat: 'YYYY-MM-DD HH:mm',
                        maxDate: this.state.fromMaxDate || this.state.fromMaxDateDefault,
                        maxTime: this.state.fromMaxTime || moment().hours(23).minutes(59),
                        minTime: moment().hours(0).minutes(0),
                        shouldCloseOnSelect: false,
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                    }}
                    defaultValue={getTodayDate()}
                    disabled={loading}
                    name='from'
                    placeholder='From...'
                    setValue={this.props.restoreFilterDateValues(this.props.filters.EventSchedule, 'from', moment().format('YYYY-MM-DD 00:00'))}
                    type='date'
                />
                <Field
                    className='--minimalWidth --datetimeWithoutSeconds'
                    component={Form.ReduxInput}
                    configuration={{
                        onChange: this.onChange,
                        calendarClassName: 'filterDatepicker',
                        dateFormat: 'YYYY-MM-DD HH:mm',
                        minDate: this.state.toMinDate || this.state.toMinDateDefault,
                        maxTime: moment().hours(23).minutes(59),
                        minTime: this.state.toMinTime || moment().hours(0).minutes(0),
                        shouldCloseOnSelect: false,
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                    }}
                    defaultValue={getTomorrowDate()}
                    disabled={loading}
                    name='to'
                    placeholder='To...'
                    setValue={this.props.restoreFilterDateValues(this.props.filters.EventSchedule, 'to', moment().add(1, 'd').format('YYYY-MM-DD 00:00'))}
                    type='date'
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='propertyLicence'
                    onChangeValue={this.onPropertyLicenceChange}
                    options={propertyLicences}
                    placeholder='Property licence'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'propertyLicence')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.eventPresetsLoading || loading}
                    hidden={!this.state.hasSelectedPropertyLicence}
                    loading={this.state.eventPresetsLoading || loading}
                    multiple
                    name="eventPreset"
                    onChangeValue={this.onEventPresetChange}
                    options={this.state.eventPresets}
                    placeholder="Applied preset"
                    search
                    selection
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'eventPreset')}
                />
                <Field
                    className='--minimalWidth --eventType'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    name='eventType'
                    onChangeValue={this.onEventTypeChange}
                    options={eventTypes}
                    placeholder='Event type'
                    selection={true}
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'eventType')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.sportsLoading || loading}
                    hidden={!this.state.hasSport}
                    loading={this.state.sportsLoading || loading}
                    multiple
                    name='sport'
                    onChangeValue={this.onSportChange}
                    options={this.state.sports}
                    placeholder='Sport'
                    search
                    selection
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'sport')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.tournamentCategoriesLoading}
                    hidden={!this.state.hasSelectedSport}
                    loading={this.state.tournamentCategoriesLoading}
                    multiple
                    name='category'
                    onChangeValue={this.onTournamentCategoryChange}
                    options={this.state.tournamentCategories}
                    placeholder='Category'
                    search
                    selection
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'category')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.tournamentsLoading || this.state.tournamentCategoriesLoading}
                    hidden={!this.state.hasSelectedTournamentCategory}
                    loading={this.state.tournamentsLoading}
                    multiple
                    name='tournament'
                    onChangeValue={this.onTournamentChange}
                    options={this.state.tournaments}
                    placeholder='Tournament'
                    search
                    selection
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'tournament')}
                />
                <Field
                    className='--customWidth --stadium'
                    component={Form.ReduxSelect}
                    disabled={
                        this.state.stadiumsLoading
                        || this.state.tournamentsLoading
                        || this.state.tournamentCategoriesLoading
                    }
                    hidden={!this.state.hasSelectedTournament}
                    loading={this.state.stadiumsLoading}
                    multiple
                    name='stadium'
                    onChangeValue={this.onStadiumChange}
                    options={this.state.stadiums}
                    placeholder='Venue / Court'
                    search
                    selection
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'stadium')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.contentCategoriesLevel1Loading || loading}
                    hidden={!this.state.hasContentCategoryLevel1}
                    loading={this.state.contentCategoriesLevel1Loading || loading}
                    multiple
                    name='contentCategoryLevel1'
                    onChangeValue={this.onContentCategoryLevel1Change}
                    options={this.state.contentCategoriesLevel1}
                    placeholder='1st level category'
                    search
                    selection
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'contentCategoryLevel1')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.contentCategoriesLevel2Loading}
                    hidden={!this.state.hasSelectedContentCategoryLevel1}
                    loading={this.state.contentCategoriesLevel2Loading}
                    multiple
                    name='contentCategoryLevel2'
                    onChangeValue={this.onContentCategoryLevel2Change}
                    options={this.state.contentCategoriesLevel2}
                    placeholder='2nd level category'
                    search
                    selection
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'contentCategoryLevel2')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.contentCategoriesLevel3Loading || this.state.contentCategoriesLevel2Loading}
                    hidden={!this.state.hasSelectedContentCategoryLevel2}
                    loading={this.state.contentCategoriesLevel3Loading}
                    multiple
                    name='contentCategoryLevel3'
                    onChangeValue={this.onContentCategoryLevel3Change}
                    options={this.state.contentCategoriesLevel3}
                    placeholder='3rd level category'
                    search
                    selection
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'contentCategoryLevel3')}
                />
                <Field
                    className='--customWidth --venue'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    hidden={!this.state.hasContentCategoryLevel1}
                    loading={loading}
                    multiple
                    name='venue'
                    options={venues}
                    placeholder='Venue'
                    search
                    selection
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'venue')}
                />
                <Field
                    component={Form.ReduxSelect}
                    name='tvChannels'
                    hidden={!this.state.hasSportsWithTvChannel}
                    options={this.state.tvChannels}
                    disabled={this.state.tvChannelsLoading || loading}
                    placeholder='TV channel'
                    loading={this.state.tvChannelsLoading || loading}
                    multiple
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'tvChannels')}
                    selection
                    search
                />
                <br className='clearfix'/>
                <Field
                    className='--minimalWidth --scheduledOrNot'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    name='scheduled'
                    options={scheduledOrNot}
                    placeholder='Scheduled or not'
                    selection
                    setValue={
                        null !== this.props.restoreFilterValues(this.props.filters.EventSchedule, 'scheduled')
                            ? this.props.restoreFilterValues(this.props.filters.EventSchedule, 'scheduled') ? 1 : 0
                            : null
                    }
                />
                <Field
                    className='--minimalWidth --hasMatchId'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    name='hasMatchId'
                    options={hasMatchId}
                    placeholder='Has match ID?'
                    selection
                    setValue={
                        null !== this.props.restoreFilterValues(this.props.filters.EventSchedule, 'hasMatchId')
                            ? this.props.restoreFilterValues(this.props.filters.EventSchedule, 'hasMatchId') ? 1 : 0
                            : null
                    }
                />
                <Field
                    className='--customWidth --eventStatus'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='eventStatus'
                    options={eventStatuses}
                    placeholder='Event status'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'eventStatus')}
                />
                <Field
                    className='--customWidth --products'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='coverage'
                    options={sortDropdownOptionsAlphanumerically(coverage)}
                    placeholder='Coverage'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'coverage')}
                    onChangeValue={this.onCoverageChange}
                />
                {this.state.hasSelectedCoverage &&
                    (
                        <Field
                            className='--customWidth --clipProvider'
                            component={Form.ReduxSelect}
                            disabled={loading}
                            loading={loading}
                            multiple
                            name='clipProvider'
                            options={sortDropdownOptionsAlphanumerically(clipProviders)}
                            placeholder='Clip provider'
                            search
                            setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'clipProvider')}
                        />
                    )
                }
                <Field
                    className='--customWidth --contentType'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='eventContentTypes'
                    options={eventContentTypes}
                    placeholder='Content type'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'eventContentTypes')}
                />
                <Field
                    className='--customWidth --contentVariant'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='eventContentVariants'
                    options={eventContentVariants}
                    placeholder='Content variant'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'eventContentVariants')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='contributionType'
                    options={contributionTypes}
                    placeholder='Contribution type'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'contributionType')}
                />
                <Field
                    className='--customWidth --encodingDatacenter'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='encodingDatacenter'
                    options={encodingDatacenters}
                    placeholder='Datacenter'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'encodingDatacenter')}
                />
                <Field
                    className='--customWidth --products'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='product'
                    options={products}
                    placeholder='Product'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'product')}
                />
                <Field
                    className='--customWidth --productStatus'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='productStatus'
                    options={productStatuses}
                    placeholder='Product status'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'productStatus')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='matchProperties'
                    options={matchProperties}
                    placeholder='Match properties'
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.EventSchedule, 'matchProperties')}
                />
                <Field
                    className='--minimalWidth --hasEventReport'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    name='hasEventReport'
                    options={hasEventReport}
                    placeholder='Report?'
                    selection
                    setValue={
                        null !== this.props.restoreFilterValues(this.props.filters.EventSchedule, 'hasEventReport')
                            ? this.props.restoreFilterValues(this.props.filters.EventSchedule, 'hasEventReport') ? 1 : 0
                            : null
                    }
                />
            </div>
        );
    }
}

const EventScheduleFilters = FiltersWrapper(withApollo(EventScheduleFiltersComponent), {
    name: 'EventSchedule',
    onSubmit: EventScheduleFiltersComponent.onSubmit,
    initialValues: {
        from: getTodayDate(),
        to: getTomorrowDate(),
    },
});

export default EventScheduleFilters;
