import React from 'react';
import {isEmpty as _isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import {useParams, withRouter} from 'react-router';
import {connect} from 'react-redux';
import {useQuery} from '@apollo/client';

import {showModal} from '@utils/modal';
import {
    renderCountryModalError,
} from '@modules/country/utils/utils';
import Table from '@appComponents/Table';
import {convertToInt} from '@utils/helpers';
import CountryPostalCodesFilters from '@modules/country/components/CountryPostalCodesFilters';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getSearchFilters} from '@utils/filters';
import {exportExcel} from '@modules/country/utils/export/countryPostalCodesExcel';
import {countryPostalCodeColumns} from '@modules/country/components/PostalCodesTable/postalCodesColumns';
import renderCountryPostalCodeTableRow from '@modules/country/components/PostalCodesTable/postalCodesTableRenderer';

import {GetDataForCountryPostalCodesViewQuery} from '../graphql/query.graphql';

const DEFAULT_FILTER_NAME = 'PostalCodesWithBlackoutZones';
export function CountryPostalCodes({filters}) {
    const matchParams = useParams(),
        selectedCountryId = convertToInt(matchParams.id),
        DATA_WARNING_LIMIT = 5000;

    const filtersQuery = {
        country: [selectedCountryId],
        ...(!_isEmpty(filters.country_subdivision)) && { countrySubdivision: [...filters.country_subdivision]},
        ...(filters.search) && {search: filters.search},
    };

    const {
        data: {
            postalCodesWithBlackoutZones = [],
        } = {},
        error: queryFetchingError,
        loading: isQueryLoading = true,
    } = useQuery(
        GetDataForCountryPostalCodesViewQuery,
        {
            variables: {
                filters: filtersQuery,
            },
            refetch: filters.lastClickSubmit,
        },
    );

    if (!_isEmpty(queryFetchingError)) {
        showModal({
            isVisible: true,
            content: renderCountryModalError(queryFetchingError.message),
        });
    }

    return (
        <div>
            <HeaderRenderer
                exportExcelParams={exportExcel(postalCodesWithBlackoutZones, selectedCountryId)}
                messagesBoxNames='Country postal codes'
                filters={CountryPostalCodesFilters}
                filtersProps={{ additionalFilterId: selectedCountryId }}
                filtersButtonName={DEFAULT_FILTER_NAME + selectedCountryId}
                loading={isQueryLoading}
            />
            <div className='table-container'>
                <Table
                    name='Countries'
                    columns={countryPostalCodeColumns}
                    data={postalCodesWithBlackoutZones}
                    loading={isQueryLoading}
                    noDataText='No postal codes found'
                    rowRenderer={renderCountryPostalCodeTableRow}
                    pagination={true}
                    showLimitWarning={true}
                    dataLimitWarning={DATA_WARNING_LIMIT}
                    defaultSort={'postal_code'}
                />
            </div>
        </div>
    );
}

CountryPostalCodes.propTypes = {
    filters: PropTypes.object.isRequired,
};

const CountryPostalCodesFilterWrapped = FilterQueryWrapper(CountryPostalCodes, {
    queryForRefresh: 'GetDataForCountryPostalCodesViewQuery',
    filterUrls: ['countries.postalCodes'],
});

const mapStateToProps = (state, ownProps) => ({
    filters: getSearchFilters(state, DEFAULT_FILTER_NAME + ownProps.match.params.id, {}),
});

export default withRouter(connect(mapStateToProps)(
    FilterUrlParamsWrapper(CountryPostalCodesFilterWrapped, {})
));
