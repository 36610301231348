import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';
import {countryTableColumns} from '@modules/country/components/CountryTable/countryTableColumns';
import countryRowRenderer from '@modules/country/components/CountryTable/countryTableRenderer';

const CountryTable = ({data, loading}) => {
    return (
        <div className='cities__table'>
            <Table
                name='Countries'
                columns={countryTableColumns}
                data={data}
                defaultSort={'name'}
                loading={loading}
                noDataText='No countries found'
                rowRenderer={countryRowRenderer}
                pagination={false}
            />
        </div>
    );
};

CountryTable.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool.isRequired,
};

export default CountryTable;
