import React from 'react';
import PropTypes from 'prop-types';
import {debounce as _debounce, isEmpty as _isEmpty} from 'lodash';
import {Field} from 'redux-form';
import moment from 'moment';

import {withApollo} from 'react-apollo';
import {getTodayDate, getTomorrowDate} from '@utils/date';
import {showConstraintsModal} from '@modules/client/utils/constraintsTables';
import {FiltersWrapper, getSelectedValues} from '@appComponents/HOCFiltersWrapper';
import Form from '@appComponents/ReduxFormControls';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {
    getBookingStatusForOfferings,
    getBookingStatusForOfferingsDefaultValues,
} from '@utils/booking';
import {EVENT_NON_SPORTRADAR_TYPE, EVENT_SPORTRADAR_TYPE} from '@constants/variables';
import {GetSport, GetContentCategoryLevel1} from '@graphql/clientOffering/query';
import {GetContentCategoriesFilteredByLevelAndParents} from '@graphql/contentCategory/query';
import {GetTournamentsFilteredByTournamentCategories} from '@graphql/tournament/query';
import {GetTournamentCategoriesFilteredBySports} from '@graphql/tournamentCategory/query';
import {HAS_HQ} from '@constants/staticDropdowns';
import {showMessageBox} from '@utils/messageBox';

class ClientOfferingsFiltersComponent extends React.Component{
    static propTypes = {
        additionalFilterId: PropTypes.string,
        client: PropTypes.shape({
            query: PropTypes.func.isRequired,
        }),
        data: PropTypes.shape({
            clientPackages: PropTypes.array,
            contentCategories: PropTypes.array,
            contentTiers: PropTypes.array,
            countries: PropTypes.array,
            deviceCategories: PropTypes.array,
            distributionTypes: PropTypes.array,
            eventTypes: PropTypes.array,
            products: PropTypes.array,
            propertyLicences: PropTypes.array,
            tournamentCategories: PropTypes.array,
            tournaments: PropTypes.array,
            eventContentTypes: PropTypes.array,
            eventContentVariants: PropTypes.array,
        }),
        filters: PropTypes.shape({
            ClientOfferings: PropTypes.object,
        }),
        form: PropTypes.shape({
            changeValue: PropTypes.func.isRequired,
        }),
        loading: PropTypes.bool.isRequired,
        MessageBox: PropTypes.shape({
            addMessage: PropTypes.func.isRequired,
        }),
        restoreFilterValues: PropTypes.func.isRequired,
        restoreFilterDateValues: PropTypes.func.isRequired,
    };

    static defaultProps = {
        additionalFilterId: null,
        data: {
            clientPackages: [],
            contentCategories: [],
            contentTiers: [],
            countries: [],
            deviceCategories: [],
            distributionTypes: [],
            eventTypes: [],
            products: [],
            propertyLicences: [],
            tournamentCategories: [],
            tournaments: [],
            eventContentTypes: [],
            eventContentVariants: [],
        },
        filters: {
            ClientOfferings: [],
        },
    };

    state = {
        contentCategoriesLevel1: [],
        contentCategoriesLevel2: [],
        contentCategoriesLevel3: [],
        fromMaxDate: null,
        fromMaxDateDefault: null,
        fromMaxTime: moment().hours(23).minutes(59),
        hasContentCategoryLevel1: false,
        hasContentCategoryLevel2: false,
        hasSport: false,
        hasTournamentCategory: false,
        hasSelectedContentCategoryLevel1: false,
        hasSelectedContentCategoryLevel2: false,
        hasSelectedSport: false,
        hasSelectedTournamentCategory: false,
        selectedContentCategoriesLevel1: [],
        selectedContentCategoriesLevel2: [],
        selectedContentCategoriesLevel3: [],
        selectedSports: [],
        selectedTournamentCategories: [],
        selectedTournaments: [],
        sports: [],
        toMinDate: null,
        toMinDateDefault: null,
        toMinTime: moment().hours(0).minutes(0),
        tournamentCategories: [],
        tournaments: [],
    };

    componentDidMount() {
        let fromMaxDate = this.props.restoreFilterDateValues(
                this.props.filters[`ClientOfferings${this.props.additionalFilterId}`], 'to'
            ),
            toMinDate = this.props.restoreFilterDateValues(
                this.props.filters[`ClientOfferings${this.props.additionalFilterId}`], 'from', moment()
            ),
            nextState = {};

        fromMaxDate = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
        toMinDate = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);

        if (fromMaxDate && !moment(this.state.fromMaxDateDefault).isSame(fromMaxDate)) {
            nextState.fromMaxDateDefault = fromMaxDate;
            nextState.fromMaxDate = fromMaxDate;
        }

        if (toMinDate && !moment(this.state.toMinDateDefault).isSame(toMinDate)) {
            nextState.toMinDateDefault = toMinDate;
            nextState.toMinDate = toMinDate;
        }

        if (
            nextState.fromMaxDateDefault &&
            nextState.toMinDateDefault &&
            nextState.fromMaxDateDefault.format('YYYY-MM-DD') === nextState.toMinDateDefault.format('YYYY-MM-DD')
        ) {
            nextState.fromMaxTime = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
            nextState.toMinTime = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);
        }

        this.setState(() => (nextState));
    }

    static onSubmit = (data) => {
        let from = moment(data.from).format('YYYY-MM-DD HH:mm'),
            to = moment(data.to).format('YYYY-MM-DD HH:mm');

        if (from !== data.from && to !== data.to) {
            data.from = moment().format('YYYY-MM-DD 00:00');
            data.to = null;
        } else if (from !== data.from) {
            data.from = moment(data.to).subtract(1, 'd').format('YYYY-MM-DD HH:mm');
        }  else if (to !== data.to) {
            data.to = null;
        }

        showConstraintsModal(data);

        return data;
    };

    getSportFromGraphQL = _debounce(() => {
        this.props.client.query({
            query: GetSport,
        }).then((result) => {
            this.setState(() => ({
                sports: sortDropdownOptionsAlphanumerically(result.data.sports),
                sportsLoading: false,
            }));
        }).catch((error) => {
            this.setState(() => ({
                sports: [],
                sportsLoading: false,
            }));

            showMessageBox(
                'clientMessage',
                'Failed to fetch sports filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getTournamentCategoriesFromGraphQL = _debounce((sportIds) => {
        this.props.client.query({
            query: GetTournamentCategoriesFilteredBySports,
            variables: {sports: sportIds},
        }).then((result) => {
            this.setState(() => ({
                tournamentCategories: sortDropdownOptionsAlphanumerically(result.data.tournamentCategories),
                tournamentCategoriesLoading: false,
            }));

            this.props.form.changeValue(
                'tournamentCategories',
                getSelectedValues(this.state.selectedTournamentCategories, result.data.tournamentCategories)
            );
        }).catch((error) => {
            this.setState(() => ({
                tournamentCategories: [],
                tournamentCategoriesLoading: false,
            }));

            showMessageBox(
                'clientMessage',
                'Failed to fetch tournament categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getTournamentsFromGraphQL = _debounce((tournamentCategoryIds) => {
        this.props.client.query({
            query: GetTournamentsFilteredByTournamentCategories,
            variables: {tournamentCategories: tournamentCategoryIds},
        }).then((result) => {
            this.setState(() => ({
                tournaments: sortDropdownOptionsAlphanumerically(result.data.tournaments),
                tournamentsLoading: false,
            }));

            this.props.form.changeValue(
                'tournaments',
                getSelectedValues(this.state.selectedTournaments, result.data.tournaments)
            );
        }).catch((error) => {
            this.setState(() => ({
                tournaments: [],
                tournamentsLoading: false,
            }));

            showMessageBox(
                'clientMessage',
                'Failed to fetch tournaments filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getContentCategoryLevel1FromGraphQL = _debounce(() => {
        this.props.client.query({
            query: GetContentCategoryLevel1,
        }).then((result) => {
            this.setState(() => ({
                contentCategoriesLevel1: sortDropdownOptionsAlphanumerically(result.data.contentCategoriesForDropdown),
                contentCategoriesLevel1Loading: false,
            }));
        }).catch((error) => {
            this.setState(() => ({
                contentCategoriesLevel1: [],
                contentCategoriesLevel1Loading: false,
            }));

            showMessageBox(
                'clientMessage',
                'Failed to fetch 1st level content categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getContentCategoriesLevel2FromGraphQL = _debounce((contentCategoryLevel1Ids) => {
        this.props.client.query({
            query: GetContentCategoriesFilteredByLevelAndParents,
            variables: {
                level: 2,
                parents: contentCategoryLevel1Ids,
            },
        }).then((result) => {
            this.setState(() => ({
                contentCategoriesLevel2: sortDropdownOptionsAlphanumerically(result.data.contentCategories),
                contentCategoriesLevel2Loading: false,
            }));

            this.props.form.changeValue(
                'contentCategoryLevel2',
                getSelectedValues(this.state.selectedContentCategoriesLevel2, result.data.contentCategories)
            );
        }).catch((error) => {
            this.setState(() => ({
                contentCategoriesLevel2: [],
                contentCategoriesLevel2Loading: false,
            }));

            showMessageBox(
                'clientMessage',
                'Failed to fetch 2nd level content categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getContentCategoriesLevel3FromGraphQL = _debounce((contentCategoryLevel2Ids) => {
        this.props.client.query({
            query: GetContentCategoriesFilteredByLevelAndParents,
            variables: {
                level: 3,
                parents: contentCategoryLevel2Ids,
            },
        }).then((result) => {
            this.setState(() => ({
                contentCategoriesLevel3: sortDropdownOptionsAlphanumerically(result.data.contentCategories),
                contentCategoriesLevel3Loading: false,
            }));

            this.props.form.changeValue(
                'contentCategoryLevel3',
                getSelectedValues(this.state.selectedContentCategoriesLevel3, result.data.contentCategories)
            );
        }).catch((error) => {
            this.setState(() => ({
                contentCategoriesLevel3: [],
                contentCategoriesLevel3Loading: false,
            }));

            showMessageBox(
                'clientMessage',
                'Failed to fetch 3rd level content categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    onEventTypeChange = (event, value) => {
        const type = convertToInt(value);

        let hasSport = (EVENT_SPORTRADAR_TYPE === type),
            hasContentCategoryLevel1 = (EVENT_NON_SPORTRADAR_TYPE === type);

        let state = {
            contentCategoriesLevel1Loading: true,
            hasContentCategoryLevel1: hasContentCategoryLevel1,
            hasSelectedContentCategoryLevel1: false,
            hasSelectedSport: false,
            hasSport: hasSport,
            sportsLoading: true,
        };

        if (hasSport) {
            this.getSportFromGraphQL();
        }

        if (hasContentCategoryLevel1) {
            this.getContentCategoryLevel1FromGraphQL();
        }

        if (!hasSport) {
            this.props.form.changeValue('sports', null);
            this.props.form.changeValue('tournamentCategories', null);
            this.props.form.changeValue('tournaments', null);
        }

        if (!hasContentCategoryLevel1) {
            this.props.form.changeValue('contentCategoryLevel1', null);
            this.props.form.changeValue('contentCategoryLevel2', null);
            this.props.form.changeValue('contentCategoryLevel3', null);
        }

        if (!value) {
            this.setState(() => ({
                selectedSports: [],
                selectedContentCategoriesLevel1: [],
            }));
        }

        this.setState(() => (state));
    };

    onSportChange = (event, value) => {
        let state = {
            hasSelectedSport: false,
            tournamentCategories: [],
            tournamentCategoriesLoading: false,
        };

        if (0 < value.length) {
            state = {
                selectedSports: value,
                hasSelectedSport: true,
                tournamentCategoriesLoading: true,
            };

            let sportIds = value.map(sport => convertToInt(sport));

            this.getTournamentCategoriesFromGraphQL(sportIds);
        } else {
            this.props.form.changeValue('tournamentCategories', null);
            state = {
                selectedTournamentCategories: [],
            };
        }

        this.setState(() => (state));
    };

    onTournamentCategoryChange = (event, value) => {
        let state = {
            hasSelectedTournamentCategory: false,
            tournaments: [],
            tournamentsLoading: false,
        };

        if (0 < value.length) {
            state = {
                hasSelectedTournamentCategory: true,
                selectedTournamentCategories: value,
                tournamentsLoading: true,
            };

            let tournamentCategoryIds = value.map(tournamentCategory => convertToInt(tournamentCategory));

            this.getTournamentsFromGraphQL(tournamentCategoryIds);
        } else {
            this.props.form.changeValue('tournaments', null);
        }

        this.setState(() => (state));
    };

    onTournamentChange = (event, value) => {
        this.setState(() => ({selectedTournaments: value}));
    };

    onContentCategoryLevel1Change = (event, value) => {
        let state = {
            hasSelectedContentCategoryLevel1: false,
            contentCategoriesLevel2: [],
            contentCategoriesLevel2Loading: false,
        };

        if (0 < value.length) {
            state = {
                hasSelectedContentCategoryLevel1: true,
                contentCategoriesLevel2Loading: true,
                selectedContentCategoriesLevel1: value,
            };

            let contentCategoryLevel2Ids = value.map(contentCategoryLevel2 => convertToInt(contentCategoryLevel2));

            this.getContentCategoriesLevel2FromGraphQL(contentCategoryLevel2Ids);
        } else {
            this.props.form.changeValue('contentCategoryLevel2', null);
        }

        this.setState(() => (state));
    };

    onContentCategoryLevel2Change = (event, value) => {
        let state = {
            contentCategoriesLevel3: [],
            contentCategoriesLevel3Loading: false,
            hasSelectedContentCategoryLevel2: false,
        };

        if (0 < value.length) {
            state = {
                contentCategoriesLevel3Loading: true,
                hasSelectedContentCategoryLevel2: true,
                selectedContentCategoriesLevel2: value,
            };

            let contentCategoryLevel3Ids = value.map(contentCategoryLevel3 => convertToInt(contentCategoryLevel3));

            this.getContentCategoriesLevel3FromGraphQL(contentCategoryLevel3Ids);
        } else {
            this.props.form.changeValue('contentCategoryLevel3', null);
        }

        this.setState(() => (state));
    };

    onContentCategoryLevel3Change = (event, value) => {
        this.setState(() => ({selectedContentCategoriesLevel3: value}));
    };

    onChange = (date, name) => {
        let nextState = {};

        nextState.fromMaxTime = null;
        nextState.toMinTime = null;

        if ('from' === name) {
            nextState.toMinDate = date;

            if (date.format('YYYY-MM-DD') === this.state.fromMaxDate.format('YYYY-MM-DD')) {
                nextState.fromMaxTime = this.state.fromMaxDate;
                nextState.toMinTime = date;
            }
        } else if ('to' === name) {
            nextState.fromMaxDate = date;

            if (date.format('YYYY-MM-DD') === this.state.toMinDate.format('YYYY-MM-DD')) {
                nextState.fromMaxTime = date;
                nextState.toMinTime = this.state.toMinDate;
            }
        }

        this.setState(() => (nextState));
    };

    render() {
        const {
            additionalFilterId,
            data: {
                clientPackages,
                contentTiers,
                countries,
                deviceCategories,
                distributionTypes,
                eventTypes,
                products,
                propertyLicences,
                eventContentTypes,
                eventContentVariants,
            },
            loading,
        } = this.props;

        const clientOfferingsFilters = this.props.filters[`ClientOfferings${additionalFilterId}`];

        return (
            <div className='clientOfferings__filters filter__controls__container'>
                <Form.IdField
                    defaultValue={additionalFilterId}
                    name='client'
                />
                <Field
                    className='--minimalWidth --datetimeWithoutSeconds'
                    component={Form.ReduxInput}
                    configuration={{
                        onChange: this.onChange,
                        calendarClassName: 'filterDatepicker',
                        dateFormat: 'YYYY-MM-DD HH:mm',
                        maxDate: this.state.fromMaxDate || this.state.fromMaxDateDefault,
                        maxTime: this.state.fromMaxTime || moment().hours(23).minutes(59),
                        minTime: moment().hours(0).minutes(0),
                        shouldCloseOnSelect: false,
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                        portalId: 'root',
                    }}
                    defaultValue={getTodayDate()}
                    disabled={loading}
                    name='from'
                    placeholder='From...'
                    setValue={this.props.restoreFilterDateValues(clientOfferingsFilters, 'from', getTodayDate())}
                    type='date'
                />
                <Field
                    className='--minimalWidth --datetimeWithoutSeconds'
                    component={Form.ReduxInput}
                    configuration={{
                        onChange: this.onChange,
                        calendarClassName: 'filterDatepicker',
                        dateFormat: 'YYYY-MM-DD HH:mm',
                        minDate: this.state.toMinDate || this.state.toMinDateDefault,
                        maxTime: moment().hours(23).minutes(59),
                        minTime: this.state.toMinTime || moment().hours(0).minutes(0),
                        shouldCloseOnSelect: false,
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                        portalId: 'root',
                    }}
                    defaultValue={getTomorrowDate()}
                    disabled={loading}
                    name='to'
                    placeholder='To...'
                    setValue={this.props.restoreFilterDateValues(clientOfferingsFilters, 'to', getTomorrowDate())}
                    type='date'
                />
                <Field
                    className='long'
                    component={Form.ReduxSelect}
                    disabled={_isEmpty(propertyLicences)}
                    loading={_isEmpty(propertyLicences)}
                    multiple
                    name='propertyLicences'
                    options={propertyLicences}
                    placeholder='Property licence'
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'propertyLicences')}
                />
                <Field
                    className='--minimalWidth --eventType'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    name='eventTypes'
                    placeholder='Event type'
                    onChangeValue={this.onEventTypeChange}
                    options={eventTypes}
                    selection
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'eventTypes')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.sportsLoading || loading}
                    hidden={!this.state.hasSport}
                    loading={this.state.sportsLoading || loading}
                    multiple
                    name='sports'
                    placeholder='Sport'
                    onChangeValue={this.onSportChange}
                    options={this.state.sports}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'sports')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.tournamentCategoriesLoading || loading}
                    hidden={!this.state.hasSelectedSport}
                    loading={this.state.tournamentCategoriesLoading || loading}
                    multiple
                    name='tournamentCategories'
                    placeholder='Category'
                    onChangeValue={this.onTournamentCategoryChange}
                    options={this.state.tournamentCategories}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'tournamentCategories')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={
                        this.state.tournamentCategoriesLoading ||
                        this.state.tournamentsLoading ||
                        loading
                    }
                    hidden={!this.state.hasSelectedTournamentCategory}
                    loading={
                        this.state.tournamentCategoriesLoading ||
                        this.state.tournamentsLoading ||
                        loading
                    }
                    multiple
                    name='tournaments'
                    placeholder='Tournament'
                    onChangeValue={this.onTournamentChange}
                    options={this.state.tournaments}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'tournaments')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.contentCategoriesLevel1Loading || loading}
                    hidden={!this.state.hasContentCategoryLevel1}
                    loading={this.state.contentCategoriesLevel1Loading || loading}
                    multiple
                    name='contentCategoryLevel1'
                    placeholder='1st level category'
                    onChangeValue={this.onContentCategoryLevel1Change}
                    options={this.state.contentCategoriesLevel1}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'contentCategoryLevel1')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={this.state.contentCategoriesLevel2Loading || loading}
                    hidden={!this.state.hasSelectedContentCategoryLevel1}
                    loading={this.state.contentCategoriesLevel2Loading || loading}
                    multiple
                    name='contentCategoryLevel2'
                    placeholder='2nd level category'
                    onChangeValue={this.onContentCategoryLevel2Change}
                    options={this.state.contentCategoriesLevel2}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'contentCategoryLevel2')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={
                        this.state.contentCategoriesLevel2Loading ||
                        this.state.contentCategoriesLevel3Loading ||
                        loading
                    }
                    hidden={!this.state.hasSelectedContentCategoryLevel2}
                    loading={
                        this.state.contentCategoriesLevel2Loading ||
                        this.state.contentCategoriesLevel3Loading ||
                        loading
                    }
                    multiple
                    name='contentCategoryLevel3'
                    placeholder='3rd level category'
                    onChangeValue={this.onContentCategoryLevel3Change}
                    options={this.state.contentCategoriesLevel3}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'contentCategoryLevel3')}
                />
                <Field
                    className='--customWidth --contentTiers'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='contentTiers'
                    placeholder='Tier'
                    options={contentTiers}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'contentTiers')}
                />
                <Field
                    className='--customWidth --contentType'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='eventContentTypes'
                    placeholder='Content type'
                    options={eventContentTypes}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'eventContentTypes')}
                />
                <Field
                    className='--customWidth --contentVariant'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='eventContentVariants'
                    placeholder='Content variant'
                    options={eventContentVariants}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'eventContentVariants')}
                />
                <Field
                    className='--customWidth --products'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='products'
                    placeholder='Product'
                    options={products}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'products')}
                />
                <Field
                    className='--customWidth --distributionTypes'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='distributionTypes'
                    placeholder='Distribution type'
                    options={distributionTypes}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'distributionTypes')}
                />
                <Field
                    className='--customWidth --deviceCategories'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='deviceCategories'
                    placeholder='Device category'
                    options={deviceCategories}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'deviceCategories')}
                />
                <Field
                    component={Form.ReduxSelect}
                    disabled={_isEmpty(countries)}
                    loading={_isEmpty(countries)}
                    multiple
                    name='countries'
                    placeholder='Country'
                    options={countries}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'countries')}
                />
                <Field
                    className='medium'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    multiple
                    name='clientPackages'
                    placeholder='Package'
                    options={clientPackages}
                    search
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'clientPackages')}
                />
                <Field
                    className='--customWidth --isHq'
                    component={Form.ReduxSelect}
                    disabled={loading}
                    loading={loading}
                    selection
                    name='isHq'
                    placeholder='HQ?'
                    options={HAS_HQ}
                    setValue={this.props.restoreFilterValues(clientOfferingsFilters, 'isHq')}
                />
                <Field
                    component={Form.ReduxSelect}
                    name='bookingStatus'
                    disabled={loading}
                    loading={loading}
                    options={getBookingStatusForOfferings()}
                    multiple
                    placeholder='Booking status'
                    setValue={this.props.restoreFilterValues(
                        clientOfferingsFilters,
                        'bookingStatus',
                        getBookingStatusForOfferingsDefaultValues()
                    )}
                    selection
                />
            </div>
        );
    }
}

const ClientOfferingsFilters = FiltersWrapper(withApollo(ClientOfferingsFiltersComponent), {
    name: 'ClientOfferings',
    onSubmit: ClientOfferingsFiltersComponent.onSubmit,
    initialValues: {
        bookingStatus: getBookingStatusForOfferingsDefaultValues(),
        from: getTodayDate(),
        to: getTomorrowDate(),
    },
});

export default ClientOfferingsFilters;
