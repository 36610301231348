import {toUpper} from 'lodash';
import {has} from 'lodash/fp';

import {columnObjectAlignCenter, hasClients, namesOfClients} from '@utils/tableHelpers';

export const Columns =  {
    id: {
        label: 'ID',
        content: { align: 'center' },
        sorting: row => parseInt(row.id, 10),
        header: { align: 'center', isCollapsed: true },
    },

    name: {
        label: 'Name',
        sorting: row => toUpper(row.name) || '',
    },

    product: {
        label: 'Product',
        sorting: row => (! has('product.short_name', row)) ? '' : toUpper(row.product.short_name),
    },

    distribution_type: {
        label: 'Distribution type',
        sorting: row => (! row.name) ? '' : row.distribution_type.name.toUpperCase(),
    },

    has_drm_support: {
        label: 'DRM?',
        ...columnObjectAlignCenter,
        sorting: row => JSON.stringify(!! row.has_drm_support),
    },

    has_multi_audio_support: {
        label: 'Multi audio?',
        ...columnObjectAlignCenter,
        sorting: row => JSON.stringify(!! row.has_multi_audio_support),
    },

    is_default: {
        label: 'Default?',
        ...columnObjectAlignCenter,
        sorting: row => JSON.stringify(!! row.is_default),
    },

    clients: {
        label: 'Clients',
        sorting: row => false === hasClients(row) ? 'All' : namesOfClients(row.clients),
    },

    actions: {
        label: 'Actions',
        ...columnObjectAlignCenter,
    },
};

export default Columns;
