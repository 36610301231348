import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import {useQuery} from '@apollo/client';
import moment from 'moment';

import Form from '@appComponents/ReduxFormControls';
import {AllClientPackagesDropdownData} from '@graphql/clientPackages/query';
import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import {STATUS, GEO_RESTRICTIONS, TEST, HAS_HQ} from '@constants/staticDropdowns';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';

const ClientPackagesFilters = ({loading : loadingFilters, restoreFilterValues, restoreFilterDateValues, filters}) => {
    const [dates, setDates] = useState({
        fromMaxDate: null,
        fromMaxDateDefault: null,
        fromMaxTime: null,
        toMinDate: null,
        toMinDateDefault: null,
        toMinTime: null,
    });

    useEffect(() => {
        let fromMaxDate = restoreFilterDateValues(filters.ClientPackages, 'to', null),
            toMinDate = restoreFilterDateValues(filters.ClientPackages, 'from', null),
            nextState = {...dates};

        fromMaxDate = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
        toMinDate = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);

        if (fromMaxDate && !moment(dates.fromMaxDateDefault).isSame(fromMaxDate)) {
            nextState.fromMaxDateDefault = fromMaxDate;
            nextState.fromMaxDate = fromMaxDate;
        }

        if (toMinDate && !moment(dates.toMinDateDefault).isSame(toMinDate)) {
            nextState.toMinDateDefault = toMinDate;
            nextState.toMinDate = toMinDate;
        }

        if (
            nextState.fromMaxDateDefault &&
            nextState.toMinDateDefault &&
            nextState.fromMaxDateDefault.format('YYYY-MM-DD') === nextState.toMinDateDefault.format('YYYY-MM-DD')
        ) {
            nextState.fromMaxTime = moment.isMoment(fromMaxDate) ? fromMaxDate : moment(fromMaxDate);
            nextState.toMinTime = moment.isMoment(toMinDate) ? toMinDate : moment(toMinDate);
        }

        setDates(nextState);
    }, []);

    const {
        data: {
            clients,
            products,
            clientPackageTypes,
            bookingTypes,
            contentPackageSelectionTypes,
            eventContentVariants,
        } = {},
        loading : areDropdownFiltersLoading = true,
    } = useQuery(AllClientPackagesDropdownData);

    const loading = (areDropdownFiltersLoading || loadingFilters);

    const onChange = (date, name) => {
        let nextState = {...dates};

        nextState.fromMaxTime = null;
        nextState.toMinTime = null;

        if ('from' === name) {
            nextState.toMinDate = date;

            if (date.format('YYYY-MM-DD') === dates.fromMaxDate.format('YYYY-MM-DD')) {
                nextState.fromMaxTime = dates.fromMaxDate;
                nextState.toMinTime = date;
            }
        } else if ('to' === name) {
            nextState.fromMaxDate = date;

            if (date.format('YYYY-MM-DD') === dates.toMinDate.format('YYYY-MM-DD')) {
                nextState.fromMaxTime = date;
                nextState.toMinTime = dates.toMinDate;
            }
        }

        setDates(nextState);
    };

    return (
        <div className='content__filters'>
            <Field
                component={Form.ReduxInput}
                name='search'
                placeholder='Search...'
                disabled={loading}
                loading={loading}
                setValue={restoreFilterValues(filters.ClientPackages, 'search') || ''}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                multiple
                name='client'
                options={clients}
                placeholder='Client'
                search
                setValue={restoreFilterValues(filters.ClientPackages, 'client')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='clientIsDisabled'
                options={STATUS}
                placeholder='Client status'
                setValue={restoreFilterValues(filters.ClientPackages, 'clientIsDisabled')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='product'
                multiple
                options={products}
                placeholder='Product'
                setValue={restoreFilterValues(filters.ClientPackages, 'product')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='productIsDisabled'
                options={STATUS}
                placeholder='Product status'
                setValue={restoreFilterValues(filters.ClientPackages, 'productIsDisabled')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='clientPackageType'
                multiple
                options={sortDropdownOptionsAlphanumerically(clientPackageTypes)}
                placeholder='Package type'
                setValue={restoreFilterValues(filters.ClientPackages, 'clientPackageType')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='bookingTypes'
                multiple
                options={bookingTypes}
                placeholder='Booking type'
                setValue={restoreFilterValues(filters.ClientPackages,'bookingTypes')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='contentPackageSelectionType'
                multiple
                options={sortDropdownOptionsAlphanumerically(contentPackageSelectionTypes)}
                placeholder='Content selection'
                setValue={restoreFilterValues(filters.ClientPackages, 'contentPackageSelectionType')}
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='eventContentVariants'
                multiple
                options={eventContentVariants}
                placeholder='Content variant'
                setValue={restoreFilterValues(filters.ClientPackages, 'eventContentVariants')}
            />
            <Field
                component={Form.ReduxInput}
                disabled={loading}
                loading={loading}
                name='from'
                configuration={{
                    dateFormat: 'YYYY-MM-DD',
                    shouldCloseOnSelect: true,
                    onChange: onChange,
                    maxDate: dates.fromMaxDate || dates.fromMaxDateDefault,
                }}
                placeholder='From...'
                setValue={restoreFilterDateValues(filters.ClientPackages, 'from')}
                type='date'
            />
            <Field
                component={Form.ReduxInput}
                disabled={loading}
                loading={loading}
                name='to'
                configuration={{
                    dateFormat: 'YYYY-MM-DD',
                    shouldCloseOnSelect: true,
                    onChange: onChange,
                    minDate: dates.toMinDate || dates.toMinDateDefault,
                }}
                placeholder='To...'
                setValue={restoreFilterDateValues(filters.ClientPackages, 'to')}
                type='date'
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='geoRestrictions'
                placeholder='Geo restrictions?'
                options={GEO_RESTRICTIONS}
                setValue={restoreFilterValues(filters.ClientPackages, 'geoRestrictions')}
                selection
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='isHq'
                placeholder='HQ?'
                options={HAS_HQ}
                setValue={restoreFilterValues(filters.ClientPackages, 'isHq')}
                selection
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='isTest'
                placeholder='Test?'
                options={TEST}
                setValue={restoreFilterValues(filters.ClientPackages, 'isTest')}
                selection
            />
            <Field
                component={Form.ReduxSelect}
                disabled={loading}
                loading={loading}
                name='isDisabled'
                placeholder='Package status'
                options={STATUS}
                setValue={restoreFilterValues(filters.ClientPackages, 'isDisabled')}
                selection
            />
        </div>
    );
};

ClientPackagesFilters.propTypes = {
    filters: PropTypes.shape({
        ClientPackages: PropTypes.object.isRequired,
    }),
    loading: PropTypes.bool,
    restoreFilterValues: PropTypes.func.isRequired,
    restoreFilterDateValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(ClientPackagesFilters, {name: 'ClientPackages'});
