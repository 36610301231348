import {isEmpty as _isEmpty} from 'lodash';
import React from 'react';

import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';
import * as RESOURCES from '@constants/resources';
import * as CONST from '@constants/variables';

export const authorizationObject = {
    [RESOURCES.MEDIA_CONNECT_ORIGIN_ENTRY_POINT]: CONST.SECURITY_PRIVILEGES_READ,
};

export const renderMediaConnectFeedsColumn = (row) => {
    if (_isEmpty(row.mediaconnect_origin_feeds)) {
        return null;
    }

    const rows = row.mediaconnect_origin_feeds.map(feed => (
        <div key={feed.feed_number}>
            {feed.url}
        </div>
    ));

    return <div>{rows}</div>;
};

export const renderMediaConnectNameColumn = (column, row) => {
    return (
        <div>
            <div>
                {(_isEmpty(row[column]))
                    ? ''
                    :
                    <LinkWithAuthorization
                        authorization={authorizationObject}
                        link={{
                            name: 'mediaConnect.edit',
                            params: {
                                id: row.id,
                            },
                        }}
                    >
                        {row[column]}
                    </LinkWithAuthorization>
                }
            </div>
            <div>
                {(!_isEmpty(row.description)) && <p className='muted'>{row.description}</p>}
            </div>
        </div>
    );
};

export const renderMediaConnectIdColumn = (column, row) => {
    return (
        <LinkWithAuthorization
            authorization={authorizationObject}
            link={{
                name: 'mediaConnect.edit',
                params: {
                    id: row.id,
                },
            }}
        >
            {row[column]}
        </LinkWithAuthorization>
    );
};
