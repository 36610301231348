import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import {debounce as _debounce} from 'lodash';

import {gql, withApollo} from 'react-apollo';
import Form from '@appComponents/ReduxFormControls';
import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import mapModulesToProps from '@utils/mapModulesToProps';
import {
    PROPERTY_LICENCE_TYPE_SPORTRADAR, PROPERTY_LICENCE_TYPE_RACE, PROPERTY_LICENCE_TYPE_OTHER,
} from '@constants/variables';
import {contentCategoriesFilteredByLevelAndParents} from '@graphql/content';
import {GetTournamentsFilteredByTournamentCategories} from '@graphql/tournament/query';
import {GetSport, GetContentCategoryLevel1} from '@graphql/propertyLicence/query';
import {tournamentCategoriesFilteredBySports} from '@graphql/tournamentCategories';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {showMessageBox} from '@utils/messageBox';

class PropertyLicenceFilters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasSport: false,
            hasCategory: false,
            hasSelectedSport: false,
            hasSelectedCategory: false,
            hasSelectedTournamentCategory: false,
            hasSelectedSecondCategory: false,
            selectedTournamentCategories: [],
            selectedTournaments: [],
            tournamentCategories: [],
            contentCategoriesSecondLevel: [],
            contentCategoriesThirdLevel: [],
            sportsLoading: true,
            contentCategoriesLoading: true,
        };

        this.onTypeChange = this.onTypeChange.bind(this);
        this.getTournamentCategoriesFromGraphQL = this.getTournamentCategoriesFromGraphQL.bind(this);
    }

    getSelectedValues(selectedValues, values) {
        const newSelectedTournamentCategories = [];

        for (let selectedCategoryIndex = 0;
            selectedCategoryIndex < selectedValues.length;
            selectedCategoryIndex++) {
            for (let categoryIndex = 0; categoryIndex < values.length; categoryIndex++) {
                if (selectedValues[selectedCategoryIndex]
                    === values[categoryIndex].value) {
                    newSelectedTournamentCategories.push(values[categoryIndex].value);
                }
            }
        }

        return newSelectedTournamentCategories;
    }

    getTournamentCategoriesFromGraphQL = _debounce((sportIds) => {
        this.props.client.query({
            query: gql(tournamentCategoriesFilteredBySports),
            variables: {
                sports: sportIds,
            },
        }).then((result) => {
            const tournamentCategories = sortDropdownOptionsAlphanumerically(
                    result.data.tournamentCategories.mapDataForDropdownWithIntVal()
                ),
                selectedTournamentCategories = this.state.selectedTournamentCategories;

            this.setState(() => {
                return {
                    tournamentCategoriesLoading: false,
                    tournamentCategories: tournamentCategories,
                };});

            this.props.form.changeValue('tournamentCategories',
                this.getSelectedValues(selectedTournamentCategories, tournamentCategories)
            );
        }).catch((error) => {
            this.setState(() => {
                return {
                    tournamentCategoriesLoading: false,
                    tournamentCategories: [],
                };
            });

            showMessageBox(
                'propertyLicenceIndexMessage',
                'Failed to fetch tournament categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getSecondLevelCategoriesFromGraphQL = _debounce((firstLevelIds) => {
        this.props.client.query({
            query: gql(contentCategoriesFilteredByLevelAndParents),
            variables: {
                level: 2,
                parents: firstLevelIds,
            },
        }).then((result) => {
            const secondLevelCategories = sortDropdownOptionsAlphanumerically(
                    result.data.contentCategories.mapDataForDropdownWithIntVal()
                ),
                selectedSecondLevelCategories = this.state.selectedSecondLevelCategories;

            this.setState(() => ({
                contentCategoriesSecondLevelLoading: false,
                contentCategoriesSecondLevel: secondLevelCategories,
            }));

            this.props.form.changeValue('contentCategoriesLvl2',
                this.getSelectedValues(selectedSecondLevelCategories, secondLevelCategories)
            );

        }).catch((error) => {
            this.setState(() => {
                return {
                    contentCategoriesSecondLevelLoading: false,
                    contentCategoriesSecondLevel: [],
                };
            });

            showMessageBox(
                'propertyLicenceIndexMessage',
                'Failed to fetch 2nd level content categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getThirdLevelCategoriesFromGraphQL = _debounce((secondLevelIds) => {
        this.props.client.query({
            query: gql(contentCategoriesFilteredByLevelAndParents),
            variables: {
                level: 3,
                parents: secondLevelIds,
            },
        }).then((result) => {
            const thirdLevelCategories = sortDropdownOptionsAlphanumerically(
                    result.data.contentCategories.mapDataForDropdownWithIntVal()
                ),
                selectedThirdLevelCategories = this.state.selectedThirdLevelCategories;

            this.setState(() => ({
                contentCategoriesThirdLevelLoading: false,
                contentCategoriesThirdLevel: thirdLevelCategories,
            }));

            this.props.form.changeValue('contentCategoriesLvl3',
                this.getSelectedValues(selectedThirdLevelCategories, thirdLevelCategories)
            );

        }).catch((error) => {
            this.setState(() => {
                return {
                    contentCategoriesThirdLevelLoading: false,
                    contentCategoriesThirdLevel: [],
                };
            });

            showMessageBox(
                'propertyLicenceIndexMessage',
                'Failed to fetch 3rd level content categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getSportFromGraphQL = _debounce(() => {
        this.props.client.query({
            query: GetSport,
        }).then((result) => {
            this.setState(() => ({
                sports: sortDropdownOptionsAlphanumerically(result.data.sports),
                sportsLoading: false,
            }));
        }).catch((error) => {
            this.setState(() => ({
                sports: [],
                sportsLoading: false,
            }));

            showMessageBox(
                'clientMessage',
                'Failed to fetch sports filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    getContentCategoryFromGraphQL = _debounce(() => {
        this.props.client.query({
            query: GetContentCategoryLevel1,
        }).then((result) => {
            this.setState(() => ({
                contentCategories: sortDropdownOptionsAlphanumerically(result.data.contentCategories),
                contentCategoriesLoading: false,
            }));
        }).catch((error) => {
            this.setState(() => ({
                contentCategories: [],
                contentCategoriesLoading: false,
            }));

            showMessageBox(
                'clientMessage',
                'Failed to fetch 1st level content categories filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    onTypeChange = (event, value) => {
        const type = parseInt(value),
            hasSport = type === PROPERTY_LICENCE_TYPE_SPORTRADAR || type === PROPERTY_LICENCE_TYPE_RACE,
            hasCategory = type === PROPERTY_LICENCE_TYPE_OTHER,
            state = {
                hasSelectedSport: false,
                hasSelectedCategory: false,
                hasSelectedSecondCategory: false,
                hasSelectedTournamentCategory: false,
                hasSport: hasSport,
                hasCategory: hasCategory,
            };

        if (!hasSport || !hasCategory) {
            this.resetTypeRelatedDropdownsFields();
        }

        if (hasSport) {
            this.getSportFromGraphQL();
        }

        if (hasCategory) {
            this.getContentCategoryFromGraphQL();
        }

        if (!value) {
            this.setState(() => ({
                selectedSports: [],
                selectedContentCategories: [],
            }));
        }

        this.setState(() => (state));
    };

    resetTypeRelatedDropdownsFields = () => {
        this.props.form.changeValue('sport', null);
        this.props.form.changeValue('tournamentCategories', null);
        this.props.form.changeValue('tournaments', null);
        this.props.form.changeValue('contentCategory', null);
        this.props.form.changeValue('contentCategoriesLvl2', null);
        this.props.form.changeValue('contentCategoriesLvl3', null);
    };

    getTournamentsFromGraphQL = _debounce((tournamentCategoryIds) => {
        this.setState(() => ({
            tournamentsLoading: true,
        }));

        this.props.client.query({
            query: GetTournamentsFilteredByTournamentCategories,
            variables: {
                tournamentCategories: tournamentCategoryIds,
            },
        }).then((result) => {
            const tournaments = sortDropdownOptionsAlphanumerically(
                    result.data.tournaments.mapDataForDropdownWithIntVal()
                ),
                selectedTournaments = this.state.selectedTournaments;

            this.setState(() => {
                return {
                    tournamentsLoading: false,
                    tournaments: tournaments,
                };});

            this.props.form.changeValue('tournaments',
                this.getSelectedValues(selectedTournaments, tournaments)
            );
        }).catch((error) => {
            this.setState(() => {
                return {
                    tournamentsLoading: false,
                    tournaments: [],
                };
            });

            showMessageBox(
                'propertyLicenceIndexMessage',
                'Failed to fetch tournament filter data',
                `${error}`,
                'error'
            );
        });
    }, 1000);

    onTournamentCategoryChange = (event, value) => {
        this.setState(() => ({
            selectedTournamentCategories: value,
            hasSelectedTournamentCategory: !!value.length,
        }));

        if (value.length) {
            this.props.form.changeValue('tournaments', null);
            this.getTournamentsFromGraphQL(value);
        }
    };

    onTournamentChange = (event, value) => {
        this.setState(() => ({
            selectedTournaments: value,
        }));
    };

    onSportChange = (event, value) => {
        let state = {
            hasSelectedSport: false,
            tournamentCategories: [],
            tournamentCategoriesLoading: false,
        };

        if (0 < value.length) {
            state = {
                selectedSports: value.map(Number),
                hasSelectedSport: true,
                tournamentCategoriesLoading: true,
            };

            this.getTournamentCategoriesFromGraphQL(value.map(Number));
        } else {
            this.props.form.changeValue('tournamentCategories', null);
        }

        this.setState(() => (state));
    };

    onCategoryChange = (event, value) => {
        let state = {
            hasSelectedCategory: false,
            contentCategoriesSecondLevel: [],
        };

        if (0 < value.length) {
            state = {
                hasSelectedCategory: true,
                contentCategoriesSecondLevelLoading: true,
                selectedContentCategories: value.map(Number),
            };

            this.getSecondLevelCategoriesFromGraphQL(value.map(Number));
        } else {
            this.props.form.changeValue('contentCategoriesLvl2', null);
        }

        this.setState(() => (state));
    };

    onContentCategorySecondLevelChange = (event, value) => {
        this.setState(() => ({
            selectedSecondLevelCategories: value,
        }));

        let state = {
            hasSelectedSecondCategory: false,
            contentCategoriesThirdLevel: [],
        };

        if (0 < value.length) {
            state = {
                hasSelectedSecondCategory: true,
                contentCategoriesThirdLevelLoading: true,
            };

            this.getThirdLevelCategoriesFromGraphQL(value);
        } else {
            this.props.form.changeValue('contentCategoriesLvl3', null);
        }

        this.setState(() => (state));
    };

    onContentCategoryThirdLevelChange = (event, value) => {
        this.setState(() => ({
            selectedThirdLevelCategories: value,
        }));
    };

    render() {
        const {
                licensors,
                licensees,
                propertyLicenceTypes,
                contentTiers,
                contentPackageTypes,
                contentManagers,
                accreditation,
                tvStreaming,
                archives,
                propertyLicenceStatuses,
            } = this.props.data,
            loading = this.props.loading;

        return (
            <div className='propertyLicence__filters filter__controls__container'>
                <Field
                    component={Form.ReduxInput}
                    name='search'
                    placeholder='Search...'
                    disabled={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'search')}
                />
                <Field
                    component={Form.ReduxSelect}
                    name='licensors'
                    options={licensors}
                    disabled={loading}
                    placeholder='Licensor'
                    loading={loading}
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'licensors')}
                    multiple
                    selection
                />
                <Field
                    component={Form.ReduxSelect}
                    name='licensees'
                    options={licensees}
                    disabled={loading}
                    placeholder='Licensee'
                    loading={loading}
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'licensees')}
                    multiple
                    selection
                />
                <Field
                    component={Form.ReduxSelect}
                    name='propertyLicenceType'
                    className={'--customWidth --propertyLicenceType'}
                    options={propertyLicenceTypes}
                    disabled={loading}
                    placeholder='Type'
                    loading={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'propertyLicenceType')}
                    selection
                    onChangeValue={this.onTypeChange}
                />
                <Field
                    component={Form.ReduxSelect}
                    name='sport'
                    hidden={!this.state.hasSport}
                    options={this.state.sports}
                    disabled={this.state.sportsLoading || loading}
                    placeholder='Sport'
                    loading={this.state.sportsLoading || loading}
                    multiple
                    selection
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'sport')}
                    onChangeValue={this.onSportChange}
                />
                <Field
                    component={Form.ReduxSelect}
                    name='tournamentCategories'
                    hidden={!this.state.hasSelectedSport}
                    options={this.state.tournamentCategories}
                    disabled={this.state.tournamentCategoriesLoading}
                    placeholder='Category'
                    loading={this.state.tournamentCategoriesLoading}
                    multiple
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'tournamentCategories')}
                    selection
                    onChangeValue={this.onTournamentCategoryChange}
                    search
                />
                <Field
                    component={Form.ReduxSelect}
                    name='tournaments'
                    hidden={!this.state.hasSelectedTournamentCategory}
                    options={this.state.tournaments}
                    disabled={this.state.tournamentsLoading}
                    placeholder='Tournament'
                    loading={this.state.tournamentsLoading}
                    multiple
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'tournaments')}
                    selection
                    onChangeValue={this.onTournamentChange}
                    search
                />
                <Field
                    component={Form.ReduxSelect}
                    name='contentCategory'
                    hidden={!this.state.hasCategory}
                    options={this.state.contentCategories}
                    disabled={this.state.contentCategoriesLoading || loading}
                    placeholder='1st level category'
                    loading={this.state.contentCategoriesLoading || loading}
                    multiple
                    selection
                    search
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'contentCategory')}
                    onChangeValue={this.onCategoryChange}
                />
                <Field
                    component={Form.ReduxSelect}
                    name='contentCategoriesLvl2'
                    hidden={!this.state.hasSelectedCategory}
                    options={this.state.contentCategoriesSecondLevel}
                    disabled={this.state.contentCategoriesSecondLevelLoading}
                    placeholder='2nd level category'
                    loading={this.state.contentCategoriesSecondLevelLoading}
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'contentCategoriesLvl2')}
                    multiple
                    selection
                    onChangeValue={this.onContentCategorySecondLevelChange}
                    search
                />
                <Field
                    component={Form.ReduxSelect}
                    name='contentCategoriesLvl3'
                    hidden={!this.state.hasSelectedSecondCategory}
                    options={this.state.contentCategoriesThirdLevel}
                    disabled={this.state.contentCategoriesThirdLevelLoading}
                    placeholder='3rd level category'
                    loading={this.state.contentCategoriesThirdLevelLoading}
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'contentCategoriesLvl3')}
                    multiple
                    selection
                    onChangeValue={this.onContentCategoryThirdLevelChange}
                    search
                />
                <Field
                    component={Form.ReduxSelect}
                    name='contentTier'
                    className={'--customWidth --propertyLicenceTier'}
                    options={contentTiers}
                    disabled={loading}
                    placeholder='Tier'
                    loading={loading}
                    multiple
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'contentTier')}
                    selection
                    search
                />
                <Field
                    component={Form.ReduxSelect}
                    name='contentPackageType'
                    options={contentPackageTypes}
                    disabled={loading}
                    placeholder='Content package type'
                    loading={loading}
                    multiple
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'contentPackageType')}
                    selection
                    search
                />
                <Field
                    component={Form.ReduxSelect}
                    name='contentManagerUser'
                    options={contentManagers}
                    disabled={loading}
                    placeholder='Content manager'
                    loading={loading}
                    multiple
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'contentManagerUser')}
                    selection
                    search
                />
                <Field
                    component={Form.ReduxSelect}
                    name='propertyLicenceStatus'
                    className={'--customWidth --propertyLicenceStatus'}
                    options={propertyLicenceStatuses}
                    disabled={loading}
                    placeholder='Status'
                    loading={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'propertyLicenceStatus')}
                    selection
                />
                <Field
                    component={Form.ReduxSelect}
                    name='hasAccreditation'
                    className={'--customWidth --propertyLicenceHasAccreditation'}
                    options={accreditation}
                    disabled={loading}
                    placeholder='Accreditation?'
                    loading={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'hasAccreditation')}
                    selection
                />
                <Field
                    component={Form.ReduxSelect}
                    name='isTvStreaming'
                    className={'--customWidth --propertyLicenceTvStreaming'}
                    options={tvStreaming}
                    disabled={loading}
                    placeholder='TV Streaming?'
                    loading={loading}
                    setValue={this.props.restoreFilterValues(this.props.filters.PropertyLicence, 'isTvStreaming')}
                    selection
                />
                <Field
                    component={Form.ReduxSelect}
                    name='isArchived'
                    className={'--customWidth --propertyLicenceArchived'}
                    options={archives}
                    disabled={loading}
                    placeholder='Archive status'
                    loading={loading}
                    setValue={this.props.restoreFilterValues(
                        this.props.filters.PropertyLicence,
                        'isArchived',
                        0
                    )}
                    selection
                />
            </div>
        );
    }
}

PropertyLicenceFilters.defaultProps = {
    licensors: [],
    licensees: [],
    propertyLicenceTypes: [],
    contentTiers: [],
    contentPackageTypes: [],
    contentManagers: [],
    propertyLicenceStatuses: [],
    accreditation: [],
    isTvStreaming: [],
    archives: [],
    sports: [],
    contentCategories: [],
    tournamentCategories: [],
    contentCategoriesSecondLevel: [],
};

PropertyLicenceFilters.propTypes = {
    licensors: PropTypes.array.isRequired,
    licensees: PropTypes.array.isRequired,
    propertyLicenceTypes: PropTypes.array.isRequired,
    contentTiers: PropTypes.array.isRequired,
    contentPackageTypes: PropTypes.array.isRequired,
    contentManagers: PropTypes.array.isRequired,
    propertyLicenceStatuses: PropTypes.array.isRequired,
    accreditation: PropTypes.array.isRequired,
    isTvStreaming: PropTypes.array.isRequired,
    archives: PropTypes.array.isRequired,
    sports: PropTypes.array.isRequired,
    contentCategories: PropTypes.array.isRequired,
    tournamentCategories: PropTypes.array.isRequired,
    contentCategoriesSecondLevel: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    client: PropTypes.object,
    form: PropTypes.object,
    MessageBox: PropTypes.object,
    data: PropTypes.object,
    filters: PropTypes.object,
    restoreFilterValues: PropTypes.func.isRequired,
};

export default FiltersWrapper(withApollo(connect(null, mapModulesToProps(['MessageBox']))(PropertyLicenceFilters)), {
    name: 'PropertyLicence',
    initialValues: {
        isArchived: 0,
    },
});
